/* eslint-disable valid-jsdoc */
import {Capacitor} from '@capacitor/core';
import {Geolocation, PositionOptions, Position} from '@capacitor/geolocation';
import {Ref, ref} from 'vue';

export interface GeoLocationInfo {
    position: Ref<Position | undefined>
    error: Ref<Error | undefined>
    isPermissionGranted: Ref<boolean>
    isRequestingLocation: Ref<boolean>
}

const DEFAULT_GEOLOCATION_OPTIONS: PositionOptions = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 60000,
};

let isFunctionActive = false;
let locationRequest: Promise<Position | undefined>;

const error = ref<Error | undefined>(undefined);
const position = ref<Position | undefined>(undefined);
const isPermissionGranted = ref(true);
const isRequestingLocation = ref(false);

/** requests location and returns vue variables */
export function geoLocation(options = DEFAULT_GEOLOCATION_OPTIONS) {
    getGeoLocation(options, false);
    return geoLocationStatus();
};

/** returns vue variables without requesting location update */
export function geoLocationStatus(): GeoLocationInfo {
    return {
        position,
        error,
        isPermissionGranted,
        isRequestingLocation,
    };
}

export function getGeoLocation(
    options: PositionOptions = DEFAULT_GEOLOCATION_OPTIONS,
    isThrowError = true,
) {
    if (isFunctionActive) {
        return locationRequest;
    }
    isFunctionActive = true;
    locationRequest = new Promise(async (resolve, reject) => {
        const position = await handleLocation(options);
        if (isThrowError) {
            if (isPermissionGranted.value === false) {
                reject(new Error(`permission to access location of device not granted`));
                return;
            } else if (error.value) {
                reject(error.value);
                return;
            }
        }
        resolve(position);
    });
    isFunctionActive = false;
    return locationRequest;
}

async function handleLocation(options: PositionOptions) {
    error.value = undefined;
    try {
        const permission = await getPermission();
        if (permission !== 'granted' && permission !== 'prompt') {
            isPermissionGranted.value = false;
            return position.value;
        }
        isPermissionGranted.value = true;
        isRequestingLocation.value = true;
        const coordinates = await getCurrentPosition(options);
        position.value = coordinates;
    } catch (e: any) {
        error.value = e;
    }
    isRequestingLocation.value = false;
    return position.value;
}

async function getCurrentPosition(options: PositionOptions): Promise<Position> {
    if (Capacitor.getPlatform() === 'web') {
        return await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => resolve(position as Position),
                (error) => reject(error),
                options,
            );
        });
    } else {
        const coordinates = await Geolocation.getCurrentPosition(options);
        return coordinates;
    }
}

async function getPermission(): Promise<String> {
    if (Capacitor.getPlatform() === 'web') {
        return await new Promise(async (resolve) => {
            const result = await navigator.permissions.query(
                {name: 'geolocation'},
            );
            resolve(result.state);
        });
    } else {
        const permissions = await Geolocation.requestPermissions();
        return permissions.location;
    }
}
