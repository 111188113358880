export {Chart} from 'chart.js';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    // LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    // Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    // LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    // Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
);
Chart.register(zoomPlugin);

export const generateColors = (colorPrimary:string, numOfColors:number = 18):string[] => {
    const generatedColors: string[] = [];
    for (let i = 0; i < numOfColors; i++) {
        const changedHueColr = changeHue(colorPrimary, (i+1)*20);
        generatedColors.push(changedHueColr);
    }
    return generatedColors;
};

export const generateRandomColor = ():string => {
    return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
};

const changeHue = (hex:string, degree:number) => {
    const hsl = hexToHSL(hex);
    hsl.h += degree;
    if (hsl.h > 360) {
        hsl.h -= 360;
    } else if (hsl.h < 0) {
        hsl.h += 360;
    }
    return hslToHEX(hsl);
};


const hexToHSL = (hex:string) => {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length == 3) {
        hex = hex.replace(/(.)/g, '$1$1');
    }

    const r = parseInt(hex.substr(0, 2), 16) / 255;
    const g = parseInt(hex.substr(2, 2), 16) / 255;
    const b = parseInt(hex.substr(4, 2), 16) / 255;

    const cMax = Math.max(r, g, b);
    const cMin = Math.min(r, g, b);
    const delta = cMax - cMin;
    const l = (cMax + cMin) / 2;
    let h = 0;
    let s = 0;

    if (delta == 0) {
        h = 0;
    } else if (cMax == r) {
        h = 60 * (((g - b) / delta) % 6);
    } else if (cMax == g) {
        h = 60 * (((b - r) / delta) + 2);
    } else {
        h = 60 * (((r - g) / delta) + 4);
    }

    if (delta == 0) {
        s = 0;
    } else {
        s = (delta/(1-Math.abs(2*l - 1)));
    }

    return {
        h: h,
        s: s,
        l: l,
    };
};

const hslToHEX = (hsl:any):string => {
    const h = hsl.h;
    const s = hsl.s;
    const l = hsl.l;
    const c = (1 - Math.abs(2*l - 1)) * s;
    const x = c * ( 1 - Math.abs((h / 60 ) % 2 - 1 ));
    const m = l - c/ 2;
    let r; let g; let b;

    if (h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h < 300) {
        r = x;
        g = 0;
        b = c;
    } else {
        r = c;
        g = 0;
        b = x;
    }

    r = normalizeRgbValue(r, m);
    g = normalizeRgbValue(g, m);
    b = normalizeRgbValue(b, m);

    return rgbToHex(r, g, b);
};

const normalizeRgbValue = (color:number, m:number):number => {
    color = Math.floor((color + m) * 255);
    if (color < 0) {
        color = 0;
    }
    return color;
};

const rgbToHex = (r:number, g:number, b:number):string => {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};
