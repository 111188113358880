<template>
    <ion-app>
        <div v-if="platform === 'web'">
            <jeep-sqlite />
        </div>
        <ion-loading :is-open="isLoading" />
        <ion-split-pane
            when="(min-width: 3850px)"
            content-id="main-content"
        >
            <ion-menu
                v-show="route.name !== 'setup'"
                side="end"
                content-id="main-content"
                type="overlay"
            >
                <ion-content
                    v-if="store.state.app.isSetupDone"
                    class="ion-padding-horizontal"
                >
                    <ion-header>
                        <ion-toolbar>
                            <ion-row>
                                <ion-col>
                                    <ion-select
                                        :value="store.state.user.locale"
                                        ok-text="Okay"
                                        :cancel-text="$t('action.cancel')"
                                        class="ion-float-left"
                                        @ion-change="setLanguage"
                                    >
                                        <ion-select-option
                                            v-for="locale in store.state.user?.application?.locales || DEFAULT_LOCALES"
                                            :key="locale.language_code"
                                            :value="locale.language_code"
                                        >
                                            {{ locale.name }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-col>
                                <ion-col style="flex-grow: 0;">
                                    <ion-button @click="store.commit('user/isDarkMode', !store.state.user?.isDarkMode)">
                                        <ion-icon :icon="store.state.user?.isDarkMode ? sunnyOutline : moonOutline" />
                                    </ion-button>
                                </ion-col>
                                <ion-col class="ion-text-right">
                                    <ion-button
                                        v-if="store.state.user.isLoggedIn"
                                        @click="signOut"
                                    >
                                        {{ t('signOut') }}&nbsp;
                                        <ion-icon :icon="logOutOutline" />
                                    </ion-button>
                                </ion-col>
                            </ion-row>
                            <ion-row class="ion-margin-bottom ion-nowrap">
                                <template v-if="store.state.user.isLoggedIn">
                                    <ion-col class="ion-align-self-center ion-text-right ion-padding-end">
                                        <ion-menu-toggle :auto-hide="false">
                                            <ion-button
                                                router-direction="root"
                                                router-link="/profile"
                                            >
                                                <ion-title class="ion-no-padding">
                                                    {{ store.state.user.credentials.username }}
                                                </ion-title>
                                            </ion-button>
                                        </ion-menu-toggle><br>
                                        <ion-button @click="changeApplication">
                                            {{ store.state.user.application.name }}&nbsp;
                                            <ion-icon :icon="chevronForward" />
                                        </ion-button>
                                    </ion-col>
                                    <ion-col style="flex-grow: 0;">
                                        <ion-menu-toggle :auto-hide="false">
                                            <ion-avatar
                                                router-direction="root"
                                                router-link="/profile"
                                            >
                                                <img :src="store.getters['user/gravatar'](64)">
                                            </ion-avatar>
                                        </ion-menu-toggle>
                                    </ion-col>
                                </template>
                                <ion-col v-else>
                                    <ion-title
                                        size="large"
                                        class="ion-text-right ion-padding-bottom"
                                    >
                                        F2
                                    </ion-title>
                                </ion-col>
                            </ion-row>
                        </ion-toolbar>
                    </ion-header>
                    <template v-if="store.state.user.isLoggedIn">
                        <ion-row class="ion-margin-top">
                            <ion-col style="flex-grow: 0;">
                                <ion-menu-toggle :auto-hide="false">
                                    <ion-button router-link="/notifications">
                                        <ion-icon :icon="notificationsOutline" />
                                        <ion-badge
                                            v-if="store.state.user?.unReadNotificationsCount"
                                            color="danger"
                                        >
                                            {{ store.state.user?.unReadNotificationsCount }}
                                        </ion-badge>
                                    </ion-button>
                                </ion-menu-toggle>
                            </ion-col>
                            <ion-col>
                                <ion-select
                                    :value="store.state.user.menu?.id"
                                    ok-text="Okay"
                                    :cancel-text="$t('action.cancel')"
                                    justify="end"
                                    @ion-change="setMenu"
                                >
                                    <ion-select-option
                                        v-for="menu in store.state.user.menus"
                                        :key="menu.id"
                                        :value="menu.id"
                                    >
                                        {{ menu.title_text_overwrite || menu.title_text }}
                                    </ion-select-option>
                                </ion-select>
                            </ion-col>
                        </ion-row>
                        <ion-list
                            id="inbox-list"
                            style="clear: both"
                        >
                            <ion-progress-bar
                                v-if="store.state.user.isMenuItemsLoading"
                                type="indeterminate"
                            />
                            <ion-menu-toggle
                                v-for="menuItem in store.state.user.menuItems"
                                v-else
                                :key="menuItem.id"
                                :auto-hide="false"
                            >
                                <ion-item
                                    router-direction="root"
                                    :router-link="'/pages/' + menuItem.page_uid"
                                    lines="none"
                                    :detail="false"
                                    class="hydrated"
                                    :class="{ selected: route.params.id === menuItem.page_uid }"
                                >
                                    <ion-label>{{ menuItem.title_text_overwrite || menuItem.title_text }}</ion-label>
                                    <f2-icon
                                        slot="end"
                                        :definition="menuItem"
                                    />
                                </ion-item>
                            </ion-menu-toggle>
                        </ion-list>
                    </template>
                </ion-content>
                <div
                    v-if="appVersion"
                    class="ion-text-center"
                >
                    <small>{{ appVersion }}</small>
                </div>
            </ion-menu>

            <ion-router-outlet
                id="main-content"
                ref="presentingElement"
            />
        </ion-split-pane>
        <ion-modal
            ref="applicationsModal"
            :can-dismiss="isApplicationsModalDismissable"
            :is-open="isApplicationsModalOpen"
            :presenting-element="presentingElement"
            :enter-animation="enterModalAnimation"
            :leave-animation="leaveModalAnimation"
            @didDismiss="isApplicationsModalOpen = false"
        >
            <ion-header>
                <ion-toolbar>
                    <ion-title class="ion-text-center">
                        {{ t('applications') }}
                    </ion-title>
                </ion-toolbar>
            </ion-header>
            <div>
                <ion-list class="ion-padding-horizontal">
                    <ion-item
                        v-for="application in userApplications"
                        :key="application.key"
                        :color="store.state.user.isLoggedIn && store.state.user.application.key === application.key ? 'primary' : undefined"
                        href="javascript:void(0)"
                        @click="selectApplication(application)"
                    >
                        <ion-thumbnail slot="start">
                            <ion-img
                                v-if="application.logo_uid"
                                :src="`${baseURL}/api/files/${application.logo_uid}/sm`"
                            />
                        </ion-thumbnail>
                        <ion-label>{{ application.name }}</ion-label>
                    </ion-item>
                </ion-list>
            </div>
        </ion-modal>
    </ion-app>
</template>

<script lang="ts">
import {
    IonApp,
    IonContent,
    IonCol,
    IonRow,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonSelect,
    IonSelectOption,
    IonProgressBar,
    IonAvatar,
    IonLoading,
    IonIcon,
    IonButton,
    IonThumbnail,
    IonImg,
    IonModal,
    IonBadge,
    menuController,
} from '@ionic/vue';
import {
    logOutOutline,
    chevronForward,
    sunnyOutline,
    moonOutline,
    notificationsOutline,
} from 'ionicons/icons';
import {defineComponent, ref, Ref, nextTick, watch} from 'vue';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
import {key} from './store';
import {useI18n} from 'vue-i18n';
import {useRouter} from 'vue-router';
import {setLocale, DEFAULT_LOCALES} from './localization';
import F2Icon from './components/F2Icon.vue';
import {Capacitor} from '@capacitor/core';
import {App} from '@capacitor/app';
import * as api from './api';
import F2Api, {UserApplication} from './framework2-sdk/f2Api';
import {enterModalAnimation, leaveModalAnimation} from './helpers';
import CacheHandler from './cacheHandler';

export default defineComponent({
    name: 'App',
    components: {
        IonProgressBar,
        IonApp,
        IonContent,
        IonCol,
        IonRow,
        IonTitle,
        IonToolbar,
        IonHeader,
        IonItem,
        IonLabel,
        IonList,
        IonMenu,
        IonMenuToggle,
        IonRouterOutlet,
        IonSplitPane,
        IonSelect,
        IonSelectOption,
        IonAvatar,
        IonLoading,
        IonIcon,
        IonButton,
        IonThumbnail,
        IonImg,
        IonModal,
        IonBadge,
        F2Icon,
    },
    setup() {
        const selectedIndex = ref(0);
        const isLoading = ref(false);
        const isApplicationsModalOpen = ref(false);
        const isApplicationsModalDismissable = ref(false);
        const applicationsModal = ref();
        const presentingElement = ref();
        const userApplications: Ref<Array<UserApplication>> = ref([]);
        const selectApplication = ref((app: UserApplication) => {});
        const appVersion = ref('');
        const baseURL = ref('');

        const platform = Capacitor.getPlatform();
        const route = useRoute();
        const store = useStore(key);
        const {t} = useI18n();
        const router = useRouter();

        if (platform !== 'web') {
            App.getInfo().then(({name, version, build}) => appVersion.value = `${name} - v${version} (${build})`);
        }

        const setLanguage = (event: any) => {
            setLocale(event.detail.value);
        };

        const setMenu = (event: any) => {
            const menu = store.state.user.menus.find((m) => m.id === event.detail.value);
            store.dispatch('user/changeMenu', {menu});
        };

        const changeApplication = async () => {
            const {key} = await openApplicationsModal(store.state.user.applications);
            if (key !== store.state.user.application.key) {
                isLoading.value = true;
                const apiInstance = api.get() as F2Api;
                let loginInfo;
                if (
                    !store.state.app.networtkStatus?.connected &&
                    store.state.user?.userId &&
                    store.state.user?.email &&
                    store.state.user?.userId &&
                    store.state.user?.applications
                ) {
                    loginInfo = {
                        userId: store.state.user.userId,
                        userEmail: store.state.user.email,
                        applicationKey: key,
                        userApplications: store.state.user.applications,
                    };
                    apiInstance.calcelToken();
                } else {
                    loginInfo = await apiInstance.setApplication(key, store.state.user.credentials.setCookie);
                }
                const cacheHandler = new CacheHandler(store.state.user?.credentials?.serverKey as string, key, loginInfo.userId);
                await cacheHandler.init();
                apiInstance.cacheHandler = cacheHandler;
                store.commit('app/setCacheActivationStatus', cacheHandler.isActive);
                await api.initUser(
                    Object.assign({}, store.state.user.credentials, {applicationKey: key}),
                    loginInfo,
                    store.state.user.locale,
                );
                apiInstance.cacheHandler?.syncRequests();
                isLoading.value = false;
                router.replace({path: `/pages/${store.state.user.lastPageUID}`});
                menuController.close();
            }
        };

        const signOut = () => {
            api.logout('');
            menuController.close();
        };

        const openApplicationsModal = async (
            applications: Array<UserApplication>,
            dismissable: boolean = true,
            serverBaseURL: string | undefined = store.state.user.credentials?.baseURL,
        ): Promise<UserApplication> => {
            baseURL.value = <string>serverBaseURL;
            userApplications.value = applications;
            isApplicationsModalOpen.value = true;
            isApplicationsModalDismissable.value = dismissable;
            return await new Promise((resolve) => {
                selectApplication.value = async (app: UserApplication) => {
                    resolve(app);
                    isApplicationsModalDismissable.value = true;
                    await nextTick();
                    isApplicationsModalOpen.value = false;
                };
            });
        };

        watch(() => store.state.user.isDarkMode, (isDarkMode) => {
            document.body.parentElement?.classList.toggle('ion-palette-dark', isDarkMode);
        }, {
            immediate: true,
        });

        return {
            isLoading,
            platform,
            setLanguage,
            setMenu,
            changeApplication,
            signOut,
            selectedIndex,
            store,
            isSelected: (url: string) => (url === route.path ? 'selected' : ''),
            route,
            t,
            DEFAULT_LOCALES,
            logOutOutline,
            chevronForward,
            sunnyOutline,
            moonOutline,
            notificationsOutline,
            applicationsModal,
            presentingElement,
            openApplicationsModal,
            isApplicationsModalOpen,
            isApplicationsModalDismissable,
            userApplications,
            selectApplication,
            enterModalAnimation,
            leaveModalAnimation,
            appVersion,
            baseURL,
        };
    },
});
</script>

<style scoped lang="scss">

ion-menu {
  --side-max-width: 20%;

  ion-header {
    box-shadow: none;
    border-bottom: 1px solid var(--ion-color-secondary);

    ion-toolbar {
        --ion-toolbar-background: var(--ion-toolbar-background);
    }

    ion-title {
      --color: var(--ion-color-primary);
    }
  }

  ion-button {
      --background: none;
      --padding-start: .5em;
      --padding-end: .5em;
      --color: var(--ion-toolbar-color, var(--ion-text-color, #424242));
      --box-shadow: none;
      --border-radius: 0;
      margin: 0;
      text-transform: none;
      letter-spacing: normal;
      margin-left: calc(var(--padding-start) * -1);
      margin-right: calc(var(--padding-end) * -1);
  }

  ion-list {
    clear: both;

    ion-item {
      --color: var(--ion-background-color-step-750, #717171);
      align-items: right;

      &:hover {
        --color: var(--ion-color-secondary);

        svg {
          color: var(--ion-color-secondary);
        }
      }

      ion-label {
        text-align: right;
        font-size: 1.2em !important;
      }

      svg {
        width: 2em;
        height: 2em;
        color: var(--ion-color-primary);
      }

      &.selected svg {
        color: var(--ion-color-secondary);
      }
    }
  }
}

ion-modal {
  --max-width: 500px;
  --max-height: 90vh;
  --height: auto;

  &::part(content) {
    display: flex;
    flex-direction: column;
  }

  > div {
    overflow-y: auto;
  }

  ion-thumbnail {
    ion-img {
      display: flex;
      align-items: center;
      justify-content: center;

      &::part(image) {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
}
</style>
