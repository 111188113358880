import {DirectiveBinding} from 'vue';
import config from '../config';
import {validator} from '../framework2-sdk/tools/validatorWrapper';
import {store} from '../store';
import * as api from '../api';
import CacheHandler from '../cacheHandler';

export default async function(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.oldValue === binding.value) {
        return;
    }
    const sizeModifier = config.imageSizes.find((imagSize) => <string>imagSize.key in binding.modifiers)?.key;
    switch (el.tagName) {
    case 'ION-IMG':
    case 'IMG':
        el.setAttribute('src', await getSrc(binding.value, el, sizeModifier));
        break;
    case 'VIDEO':
        if (validator.isUid(binding.value)) {
            el.setAttribute('poster', await getSrc(binding.value, el, sizeModifier));
        }
    case 'AUDIO':
        el.innerHTML = '';
        const src = await getSrc(binding.value);
        if (src) {
            const source = document.createElement('source');
            source.setAttribute('src', src);
            el.appendChild(source);
        }
    }
};

async function getSrc(value: any, el?: HTMLElement, sizeModifier?: string): Promise<string> {
    if (validator.isUid(value)) {
        sizeModifier = sizeModifier || el && await new Promise((resolve) => {
            const id = setInterval(() => {
                if (el.clientWidth) {
                    let sizeModifier: string = '';
                    for (const {key, width} of config.imageSizes) {
                        sizeModifier = key as string;
                        if (width >= el.clientWidth) {
                            break;
                        }
                    }
                    clearInterval(id);
                    resolve(sizeModifier);
                }
            }, 10);
        }) || '';
        const cacheHandler = api.get()?.cacheHandler as CacheHandler;
        return (await cacheHandler.getFile(value, 'base64', sizeModifier)) || `${store.state.user.credentials.baseURL}/app-key/${store.state.user.application.key}/api/files/${value}/${sizeModifier}`;
    } else {
        return value + '';
    }
}
