import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"width":"100%","padding-top":"20px"},
  class: "ion-align-self-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_f2_form_input = _resolveComponent("f2-form-input")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, {
    class: "filter-row",
    style: {"flex-grow":"1"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            size: "12",
            "size-lg": "6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.filter.field,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.field) = $event)),
                    label: _ctx.$t('label.field'),
                    "label-placement": "floating",
                    style: {"max-width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: field.key,
                          value: field.key
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(field.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.dsField.type !== 'boolean')
            ? _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                key: 0,
                size: "12",
                "size-lg": "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        modelValue: _ctx.filter.operator,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.operator) = $event)),
                        label: _ctx.$t('label.operator'),
                        "label-placement": "floating",
                        style: {"max-width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operators, (operator) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: operator.key,
                              value: operator.key
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(operator.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512)), [
                [_vShow, _ctx.filter.field]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, {
            size: "12",
            "size-lg": "6"
          }, {
            default: _withCtx(() => [
              (_ctx.hasFilterValue)
                ? (_openBlock(), _createBlock(_component_f2_form_input, {
                    key: _ctx.filter.field,
                    definition: {
                        field: 'value',
                        definition: {
                            formID: 'filterModel',
                            field: 'value',
                            ..._ctx.dsField,
                            label: {
                                text: _ctx.fields.find(({key}) => key === _ctx.filter.field)?.label
                            },
                        },
                        type: _ctx.valueFieldType,
                    },
                    "data-source": _ctx.dataSource,
                    model: {
                        filterModel: {
                            value: _ctx.filter
                        }
                    },
                    "ds-field-name": _ctx.filter.field,
                    placeholder: _ctx.$t('label.value')
                  }, null, 8, ["definition", "data-source", "model", "ds-field-name", "placeholder"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, {
            size: "12",
            "size-lg": "6",
            class: "ion-padding-end ion-padding-start",
            style: {"display":"flex"}
          }, {
            default: _withCtx(() => [
              (_ctx.showLogic)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_ion_segment, {
                      value: _ctx.filter.logic,
                      color: "primary",
                      mode: "ios",
                      onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter.direction = $event.detail.value))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_segment_button, { value: "and" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('label.and')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_segment_button, { value: "or" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('label.or')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}