import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_source_status = _resolveComponent("data-source-status")!
  const _component_f2_datetime = _resolveComponent("f2-datetime")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_f2_upload = _resolveComponent("f2-upload")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_data_source_status, { "data-source-info": _ctx.dataSourceInfo }, null, 8, ["data-source-info"]),
    (_ctx.isDataReady && _ctx.formModel)
      ? _withDirectives((_openBlock(), _createBlock(_component_ion_item, {
          key: 0,
          class: _normalizeClass(_ctx.fieldClass)
        }, {
          default: _withCtx(() => [
            (
                _ctx.definition.type === 'date' ||
                    _ctx.definition.type === 'time' ||
                    _ctx.definition.type === 'datetime'
            )
              ? (_openBlock(), _createBlock(_component_f2_datetime, {
                  key: 0,
                  definition: _ctx.definition.definition,
                  model: _ctx.props.model,
                  disabled: !_ctx.modifyable,
                  "is-required": _ctx.isRequired,
                  "additional-input-attributes": _ctx.additionalInputAttributes,
                  label: _ctx.labelText
                }, null, 8, ["definition", "model", "disabled", "is-required", "additional-input-attributes", "label"]))
              : (
                _ctx.definition.type === 'text-input'
                    || _ctx.definition.type === 'number'
                    || _ctx.definition.type === 'integer'
                    || _ctx.definition.type === 'float'
                    || _ctx.definition.type === 'barcode'
            )
                ? (_openBlock(), _createBlock(_component_ion_input, _mergeProps({
                    key: 1,
                    modelValue: _ctx.formModel[_ctx.fieldName],
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel[_ctx.fieldName]) = $event)),
                    type: _ctx.definition.type === 'text-input' || _ctx.definition.type === 'barcode' ? 'text' : 'number',
                    disabled: !_ctx.modifyable,
                    placeholder: _ctx.placeholder,
                    step: _ctx.definition.type === 'integer' ? '1' : undefined,
                    required: _ctx.isRequired,
                    label: _ctx.labelText,
                    "label-placement": "stacked"
                  }, _ctx.additionalInputAttributes, { onIonFocus: _ctx.onFocus }), null, 16, ["modelValue", "type", "disabled", "placeholder", "step", "required", "label", "onIonFocus"]))
                : (_ctx.definition.type === 'textarea')
                  ? (_openBlock(), _createBlock(_component_ion_textarea, _mergeProps({
                      key: 2,
                      modelValue: _ctx.formModel[_ctx.fieldName],
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel[_ctx.fieldName]) = $event)),
                      rows: 5,
                      disabled: !_ctx.modifyable,
                      required: _ctx.isRequired,
                      label: _ctx.labelText,
                      "label-placement": "stacked"
                    }, _ctx.additionalInputAttributes), null, 16, ["modelValue", "disabled", "required", "label"]))
                  : (_ctx.definition.type === 'dropdown')
                    ? (_openBlock(), _createBlock(_component_ion_select, _mergeProps({
                        key: 3,
                        modelValue: _ctx.formModel[_ctx.fieldName],
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel[_ctx.fieldName]) = $event)),
                        disabled: !_ctx.modifyable,
                        placeholder: _ctx.placeholder,
                        multiple: _ctx.isMultiSelect,
                        label: _ctx.labelText,
                        "label-placement": "stacked"
                      }, _ctx.additionalInputAttributes), {
                        default: _withCtx(() => [
                          (!_ctx.isRequired)
                            ? (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: 0,
                                value: null
                              }))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: item.value,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.text), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 16, ["modelValue", "disabled", "placeholder", "multiple", "label"]))
                    : (_ctx.definition.type === 'file' || _ctx.definition.type === 'signature')
                      ? (_openBlock(), _createBlock(_component_f2_upload, {
                          key: 4,
                          definition: _ctx.definition,
                          model: _ctx.props.model,
                          disabled: !_ctx.modifyable,
                          "is-required": _ctx.isRequired,
                          "validation-rules": _ctx.validationRules,
                          label: _ctx.labelText,
                          "additional-input-attributes": _ctx.additionalInputAttributes
                        }, null, 8, ["definition", "model", "disabled", "is-required", "validation-rules", "label", "additional-input-attributes"]))
                      : (_ctx.definition.type === 'checkbox')
                        ? (_openBlock(), _createBlock(_component_ion_toggle, _mergeProps({
                            key: 5,
                            modelValue: _ctx.formModel[_ctx.fieldName],
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel[_ctx.fieldName]) = $event)),
                            disabled: !_ctx.modifyable,
                            "is-required": _ctx.isRequired,
                            "label-placement": "end",
                            justify: "start"
                          }, _ctx.additionalInputAttributes), {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.labelText), 1)
                            ]),
                            _: 1
                          }, 16, ["modelValue", "disabled", "is-required"]))
                        : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class"])), [
          [_vShow, !_ctx.hidden]
        ])
      : _createCommentVNode("", true)
  ], 64))
}