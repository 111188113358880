import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _directive_f2Src = _resolveDirective("f2Src")!

  return (_ctx.isSrc && !_ctx.src)
    ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
        key: 0,
        type: "indeterminate"
      }))
    : (_ctx.isSrc)
      ? _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.type === 'image' ? 'ion-img' : 'video'), {
          key: 1,
          "router-link": _ctx.type === 'image' && _ctx.isUid && `/files/${_ctx.src}`,
          style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
          class: _normalizeClass(_ctx.props.definition.resolvedClasses),
          controls: ""
        }, null, 8, ["router-link", "style", "class"])), [
          [_directive_f2Src, _ctx.src]
        ])
      : _createCommentVNode("", true)
}