<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="end">
                <ion-button
                    :strong="true"
                    @click="submit"
                >
                    {{ $t('action.apply') }}
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <ion-list class="ion-padding-end ion-padding-start">
            <ion-radio-group
                :value="userSort[0].column"
                allow-empty-selection="true"
                @ionChange="userSort[0].column = $event.detail.value"
            >
                <ion-item
                    v-for="field of fields"
                    :key="field.key"
                >
                    <ion-grid>
                        <ion-row>
                            <ion-col
                                class="ion-padding-end"
                            >
                                <ion-radio
                                    :value="field.key"
                                    label-placement="end"
                                    justify="start"
                                >
                                    {{ field.label }}
                                </ion-radio>
                            </ion-col>
                            <ion-col>
                                <ion-segment
                                    v-if="userSort[0].column === field.key"
                                    color="primary"
                                    mode="ios"
                                    :value="userSort[0].direction"
                                    @ionChange.stop="userSort[0].direction = $event.detail.value"
                                >
                                    <ion-segment-button value="asc">
                                        <ion-icon :icon="caretUpOutline" />
                                    </ion-segment-button>
                                    <ion-segment-button value="desc">
                                        <ion-icon :icon="caretDownOutline" />
                                    </ion-segment-button>
                                </ion-segment>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-item>
            </ion-radio-group>
        </ion-list>
    </ion-content>
</template>
<script lang="ts">
import {defineComponent, ref} from 'vue';
import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonRadio,
    IonRadioGroup,
    IonList,
} from '@ionic/vue';
import {
    caretUpOutline,
    caretDownOutline,
} from 'ionicons/icons';

export default defineComponent({
    components: {
        IonContent,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonIcon,
        IonItem,
        IonGrid,
        IonRow,
        IonCol,
        IonSegment,
        IonSegmentButton,
        IonRadio,
        IonRadioGroup,
        IonList,
    },
    props: {
        fields: {
            required: true,
            type: Array as () => any[],
        },
        sort: {
            required: true,
            type: Array as () => any[],
        },
    },
    emits: ['submit', 'dismiss'],
    setup(props, {emit}) {
        const userSort = ref<any[]>(props.sort.length ? props.sort : [{
            column: null,
            direction: 'asc',
        }]);
        function submit() {
            emit('submit', userSort.value.filter((sort) => sort.column));
        }

        function dismiss() {
            emit('dismiss');
        }

        return {
            userSort,
            submit,
            dismiss,
            caretUpOutline,
            caretDownOutline,
        };
    },
});
</script>
