const AGGREGATION_FUNCTIONS = {
    'count': true,
};

module.exports = {
    buildAggregation,
};

function buildAggregation (query, aggregation) {
    for (const a of aggregation) {
        if (a.function in AGGREGATION_FUNCTIONS) {
            query[a.function]({ [a.alias]: a.column });
        }
    }
    return query;
}
