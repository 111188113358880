<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button
                    @click="dismiss()"
                >
                    {{ $t('action.abort') }}
                </ion-button>
            </ion-buttons>
            <ion-buttons slot="end">
                <ion-button
                    :strong="true"
                    @click="submit"
                >
                    {{ $t('action.apply') }}
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <template v-if="userFilters.length">
            <ion-card
                v-for="(item, i) of userFilters"
                :key="i"
                :value="item"
            >
                <ion-card-content>
                    <f2-filter-row
                        ref="filterRows"
                        :fields="fields"
                        :ds-fields="dsFields"
                        :show-logic="userFilters.length > 1 && i < userFilters.length - 1"
                        :filter="item"
                        :data-source="dataSource"
                    />
                    <ion-icon
                        :icon="close"
                        class="ion-margin-start"
                        @click="userFilters.splice(i, 1)"
                    />
                </ion-card-content>
            </ion-card>
        </template>
        <ion-fab
            slot="fixed"
            vertical="bottom"
            horizontal="end"
        >
            <ion-fab-button
                color="primary"
                @click="userFilters.push({
                    field: null,
                    operator: '=',
                    value: null,
                    logic: 'and',
                })"
            >
                <ion-icon :icon="add" />
            </ion-fab-button>
        </ion-fab>
    </ion-content>
</template>
<script lang="ts">
import {defineComponent, ref} from 'vue';
import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonCard,
    IonCardContent,
} from '@ionic/vue';
import {
    add,
    close,
} from 'ionicons/icons';
import {DataSourceFields} from '@/framework2-sdk/dataSource';
import F2DataSource from '@/framework2-sdk/f2DataSource';
import F2FilterRow from './F2FilterRow.vue';

export default defineComponent({
    components: {
        IonContent,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonIcon,
        IonCard,
        IonCardContent,
        F2FilterRow,
    },
    props: {
        fields: {
            required: true,
            type: Array as () => any[],
        },
        dsFields: {
            required: true,
            type: Object as () => DataSourceFields,
        },
        filters: {
            required: true,
            type: Array as () => any[],
        },
        dataSource: {
            required: true,
            type: Object as () => F2DataSource,
        },
    },
    emits: ['submit', 'dismiss'],
    setup(props, {emit}) {
        const filterRows = ref();
        const userFilters = ref<any[]>(props.filters.length ? props.filters : [{
            field: null,
            operator: '=',
            value: null,
            logic: 'and',
        }]);

        function submit() {
            emit(
                'submit',
                userFilters.value.filter(
                    (filter: any, i) => filter.field &&
                        (!filterRows.value[i]?.hasFilterValue || filter.value !== null),
                ),
            );
        }

        function dismiss() {
            emit('dismiss');
        }

        return {
            userFilters,
            submit,
            dismiss,
            add,
            close,
            filterRows,
        };
    },
});
</script>

<style scoped>
    ion-card-content {
        display: flex;
    }
    ion-button,
    ion-reorder {
        align-self: center;
    }
</style>
