import DS from './dataSource';
import api from './f2ApiSingleton';
import { clone } from './tools/object';
import { merge } from 'lodash';
import { ApiError } from './f2Api';

export default class F2DataSource extends DS {

    constructor (definitionOrUID, dataSourceRegistry) {
        super(undefined, dataSourceRegistry);
        this.isDefinitionReady = true;
        this.definitionPromise = this.setDefinition(definitionOrUID);
    }

    async setDefinition (definitionOrUID) {
        if (typeof definitionOrUID === 'string') {
            this._config = {
                uid: definitionOrUID,
            };
        }
        else {
            this._config = definitionOrUID;
        }

        let config = this._config = Object.assign(
            {
                debug: false,
                getFilesByName: (fileName) => {
                    return api.getFiles({
                        where: {
                            field: 'f.name',
                            value: fileName,
                            operator: 'contains'
                        }
                    });
                },
                // way to intercept config before loading data
                // onDataDefinitionFetched: async (fetchedDtaDefinition) => {}
            },
            this._config,
        );

        if (this._config.uid) {
            this.isDefinitionReady = false;
            if (!('id' in this._config)) {
                this._config.id = this._config.uid;
            }
            let def = await api.dataDefinition(this._config.uid);
            if (this._config.onDataDefinitionFetched) {
                def = await this._config.onDataDefinitionFetched(def);
            }

            this.isDefinitionReady = true;

            const tagsToResolve = this._config.tagsToResolve;
            this._config = merge(def, this._config);

            if (tagsToResolve) {
                for (const [key, value] of Object.entries(tagsToResolve)) {
                    this._config.tagsToResolve[key] = value;
                }
            }

            this._config.source = async (dbInstructionsOrData, action, items) => {
                let result;
                try {
                    if (action === 'read') {
                        const data = await api.readData(this._config.uid, dbInstructionsOrData, this._config.needsGeoLocation);
                        // handling of stored proc results
                        if (data.length && Array.isArray(data[0])) {
                            return data[0];
                        }
                        return data;
                    }
                    else if (action === 'create') {
                        result = await api.createData(this._config.uid, dbInstructionsOrData);
                    }
                    else if (action === 'update') {
                        result = await api.updateData(this._config.uid, dbInstructionsOrData);
                    }
                    else { // delete
                        result = await api.deleteData(this._config.uid, dbInstructionsOrData);
                    }
                }
                catch (error) {
                    if (error instanceof ApiError) {
                        if (error.errorSubject) {
                            let dataItem = this.findItem(error.errorSubject, items);
                            if (dataItem) {
                                dataItem.__emit('error', error);
                            }
                        }
                        else if (items && items.length === 1) {
                            items[0].__emit('error', error);
                        }
                    }
                    throw {
                        action,
                        error,
                    };
                }
                return {
                    action,
                    result,
                };
            };
        }

        if (this._config.fields) {
            const dataSourceRegistry = this._dataSourceRegistry;
            for (let fieldName in this._config.fields) {
                Object.defineProperty(this._config.fields[fieldName], 'dataSource', {
                    get: function () {
                        if (this._dataSource)
                            return this._dataSource;
                        else if (this.data && this.data.source) {
                            return this._dataSource = new F2DataSource(
                                Object.assign(this.data.source, {
                                    debug: config.debug,
                                    autoFetch: false
                                })
                                , dataSourceRegistry
                            );
                        }
                        return undefined;
                    }
                });
            }
        }

        // resolve placeholders in data if data is an object
        if (this._config.data) {
            for (const data of this._config.data) {
                for (let [key, value] of Object.entries(data)) {
                    if (typeof value === 'string') {
                        if (value.includes('{__currentYear}')) {
                            data[key] = value.replace('{__currentYear}', new Date().getFullYear());
                        }
                    }
                }
            }
        }

        super.setDefinition(this._config);
        return this._config;
    }

    getDefinition () {
        return this.definitionPromise;
    }

    getDefinitionSync () {
        if (this._config.debug && !this.isDefinitionReady)
            console.warn('getDefinitionSync: definition not ready yet');
        return this._config;
    }

    async userCan (...rights) {
        const userCan = [];
        const config = await this.getDefinition();
        for (const right of rights) {
            let can = false;
            if (rights in config && right in config.rights) {
                can = config.rights[right];
            }
            userCan.push(can);
        }
        return userCan;
    }

    async clone (config = {}) {
        let definition = clone(await this.getDefinition());
        delete definition.uid;
        return new F2DataSource(Object.assign(definition, config), this._dataSourceRegistry);
    }
}