import {Barcode} from '@capacitor-mlkit/barcode-scanning';
import router from '@/router';

export let resolve: (a: Barcode) => void | undefined;
export let reject: Function | undefined;

export function read(): Promise<Barcode> {
    return new Promise((res, rej) => {
        resolve = res;
        reject = rej;
        router.push('/barcode/read');
    });
}

export function show(barcodeType: string, value: string): Promise<void> {
    return router.push({
        name: 'showBarcode',
        params: {
            barcodeType,
            value,
        },
    })
        .then(() => {});
}
