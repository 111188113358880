const recursion = require('./recursion');
const string = require('./string');

module.exports = {
    replaceAll__StringTagsInObject,
    replaceAllStringTagsInObject,
    accessUsingDotNotation,
    accessUsingDotNotationRemoveZeroIndexesOnFail,
    clone,
    cloneAsync,
    isEmpty,
    mapOwn,
    diff,
    deletedDiff,
    detailedDiff,
    traverseObjectValues: recursion.traverseObjectValues,
    areDifferent,
    areSame,
    getOverwrite,
    overwrite,
};

async function replaceAllStringTagsInObject (object, tagSolver, tagStart = '{', tagEnd = '}') {
    await Promise.all(
        recursion.traverseObjectValues(
            object
            , function (value, key, tree) {
                this.push(
                    string
                        .replaceTagsAsync(value, async tag => {
                            return await tagSolver(tag);
                        }, tagStart, tagEnd)
                        .then(replacedString => {
                            tree.parent[key] = replacedString;
                        })
                );
            }
            , []
        )
    );
    return object;
}

function replaceAll__StringTagsInObject (object, asyncTagSolver) {
    return replaceAllStringTagsInObject(object, asyncTagSolver, '__', '\\b');
}

/**
 * pass an object and dot separeted selector string (hi.array.5.man)
 * @param {*} object
 * @param {*} selector
 * @returns {any} or undefined if value is not found
 */
function accessUsingDotNotation (object, selector) {
    for (let fragment of Array.isArray(selector) ? selector : selector.split('.')) {
        if (Array.isArray(object) || object && fragment in object) {
            object = object[fragment];
        }
        else {
            return undefined;
        }
    }
    return object;
}

function accessUsingDotNotationRemoveZeroIndexesOnFail (object, selector) {
    let result = accessUsingDotNotation(object, selector);
    if (result) {
        return {data: result, isRemovedZeroIndex: false};
    }
    if (Array.isArray(selector)) {
        result = accessUsingDotNotation(object, selector.filter(e => e != '0'));
        return {data: result, isRemovedZeroIndex: true };
    }
    result = accessUsingDotNotation(object, selector.replace('.0.', '.'));
    return {data: result, isRemovedZeroIndex: true };
}

/**
 * used to clone an object, with possiblity to control changes in the clone with 2nd param function
 * @param {object} object you want to clone
 * @param {function} forEveryObjectItem that gets called for every value function(key, value, parentObject, pathAsArray, parentClone) if you return undefined the property
 * is omitted in the clone the pathAsArray property is created for an object like { 'first': [ { 'third': '' } ] } => [ 'first', 0, 'third' ]
 * @returns {object} deep clone of object handed in
 */
function clone (object, forEveryObjectItem = null) {
    return _clone(object, null, null, forEveryObjectItem);
}

//IMPORTANT NOTE: if you modify this function also modify _cloneAsync
function _clone (object, key, parentObject, forEveryObjectItem = null, path = [], parentClone = null) {
    let theClone;
    if (Array.isArray(object)) {
        theClone = [];
        let length = object.length;
        for (let i = 0; i < length; i++) {
            let val = _clone(object[i], i, object, forEveryObjectItem, [...path, i], theClone);
            if (val === undefined) {
                continue;
            }
            theClone.push(val);
        }
    }
    else if (object && typeof object === 'object') {
        theClone = {};
        for (let [key, value] of Object.entries(object)) {
            let val = _clone(value, key, object, forEveryObjectItem, [...path, key], theClone);
            if (val === undefined) {
                continue;
            }
            theClone[key] = val;
        }
    }
    else {
        if (forEveryObjectItem) {
            return forEveryObjectItem(key, object, parentObject, path, parentClone);
        }
        return object;
    }
    return theClone;
}

/**
 * used to clone an object, for every property "forEveryObjectItem" parameter function is called with possibility to return a promise
 * which will be awaited before continue with execution
 * @param {object} object you want to clone
 * @param {function} forEveryObjectItem that gets called for every value function(key, value, parentObject, pathAsArray, parentClone) if you return undefined the property
 * is omitted in the clone the pathAsArray property is created for an object like { 'first': [ { 'third': '' } ] } => [ 'first', 0, 'third' ]
 * @returns {object} deep clone of object handed in
 */
function cloneAsync (object, forEveryObjectItem) {
    return _cloneAsync(object, null, null, forEveryObjectItem);
}

//IMPORTANT NOTE: if you modify this function also modify _clone
async function _cloneAsync (object, key, parentObject, forEveryObjectItem, path = [], parentClone = null) {
    let theClone = await forEveryObjectItem(key, object, parentObject, path, parentClone);
    if (theClone === undefined) {
        return;
    }
    if (Array.isArray(object)) {
        theClone = [];
        let length = object.length;
        for (let i = 0; i < length; i++) {
            let val = await _cloneAsync(object[i], i, object, forEveryObjectItem, [...path, i], theClone);
            if (val === undefined) {
                continue;
            }
            theClone.push(val);
        }
    }
    else if (object && typeof object === 'object') {
        theClone = {};
        for (let [key, value] of Object.entries(object)) {
            let val = await _cloneAsync(value, key, object, forEveryObjectItem, [...path, key], theClone);
            if (val === undefined) {
                continue;
            }
            theClone[key] = val;
        }
    }
    return theClone;
}

function isEmpty (obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

function mapOwn (obj, fn) {
    const array = [];
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const res = fn(key, obj[key], obj);
            if (res != undefined) {
                array.push(res);
            }
        }
    }
    return array;
}

/**
 * returns an object that can be used to update "from" in order to become "to", this can be achieved by using the function "overwrite"
 * @param {Object} from
 * @param {Object} to
 */
function getOverwrite (from, to) {
    if (Array.isArray(from) && Array.isArray(to)) {
        let i = 0;
        let overwrite = [];
        for (const v of to) {
            if (from.length >= i) {
                overwrite.push(getOverwrite(from[i], v) || '==equal==');
            }
            else {
                overwrite.push(v);
            }
            i++;
        }
        if (from.length >= i) {
            for (i; i < from.length; i++) {
                overwrite.push('==delete==');
            }
        }
        return overwrite;
    }
    else if (from && to && typeof from === 'object' && typeof to === 'object') {
        const overwrite = {};
        for (const [k, v] of Object.entries(to)) {
            if (k in from) {
                const o = getOverwrite(from[k], v);
                if (o) {
                    overwrite[k] = o;
                }
                else {
                    overwrite[k] = '==equal==';
                }
            }
            else {
                overwrite[k] = v;
            }
        }
        for (const k of Object.keys(from)) {
            if (!(k in to)) {
                overwrite[k] = '==delete==';
            }
        }
        return overwrite;
    }
    else if (from === to) {
        return;
    }
    return to;
}

function overwrite (object, overwriteObject) {
    if (Array.isArray(overwriteObject)) {
        if (!Array.isArray(object)) {
            return overwriteObject;
        }

        let iTarget = 0;
        for (const o of overwriteObject) {
            if (o === '==equal==') {
                iTarget++;
                continue;
            }
            if (o === '==delete==' && iTarget < object.length) {
                object.splice(iTarget, 1);
                continue;
            }
            if (iTarget >= object.length) {
                object.push(o);
            }
            else {
                object[iTarget] = overwrite(object[iTarget], o);
            }
            iTarget++;
        }
    }
    else if (overwriteObject && typeof overwriteObject === 'object') {
        if (!object || typeof object !== 'object') {
            return overwriteObject;
        }

        for (const [k, v] of Object.entries(overwriteObject)) {
            if (v === '==equal==') {
                continue;
            }
            if (v === '==delete==') {
                delete object[k];
                continue;
            }
            if (k in object) {
                object[k] = overwrite(object[k], v);
            }
            else {
                object[k] = v;
            }
        }
    }
    else {
        return overwriteObject;
    }

    return object;
}

/**
 * used to get the difference from 2 values
 * @param {any} object base object
 * @param {any} value to get the difference from
 */
function diff (baseValue, value) {
    let diffs;
    // diff arrays
    if (
        Array.isArray(baseValue)
        && Array.isArray(value)
    ) {
        diffs = value
            .map((v, i) => diff(baseValue[i], v));

        for (let i = diffs.length - 1; i >= 0; i--) {
            if (diffs[i] !== undefined) {
                break;
            }
            diffs.pop();
        }

        if (!diffs.length) {
            diffs = undefined;
        }
    }
    // diff objects
    else if (
        value
        && baseValue
        && typeof value === 'object'
        && typeof baseValue === 'object'
        && !Array.isArray(value)
        && !Array.isArray(baseValue)
    ) {
        for (let key of Object.keys(value)) {
            let _diff = diff(baseValue[key], value[key]);
            if (_diff === undefined) {
                continue;
            }
            if (!diffs) {
                diffs = {};
            }
            diffs[key] = _diff;
        }
    }
    // diff single value
    else if (value !== baseValue) {
        diffs = value;
    }

    return diffs;
}

function deletedDiff (baseValue, value, path = '', deleted = {}) {
    // arrays
    if (
        Array.isArray(baseValue)
        && Array.isArray(value)
    ) {
        baseValue.map((v, i) => deletedDiff(v, value[i], `${path}.${i}`, deleted));
    }
    // objects
    else if (
        value
        && baseValue
        && typeof value === 'object'
        && typeof baseValue === 'object'
        && !Array.isArray(value)
        && !Array.isArray(baseValue)
    ) {
        for (let key of Object.keys(baseValue)) {
            deletedDiff(baseValue[key], value[key], `${path}.${key}`, deleted);
        }
    }
    else if (value === undefined) {
        deleted[path.substring(1)] = baseValue;
    }

    return deleted;
}

function detailedDiff (baseValue, value) {
    return {
        changed: diff(baseValue, value),
        deleted: deletedDiff(baseValue, value)
    };
}

function areDifferent (object1, object2) {
    if (object1 === object2) {
        return false;
    }
    if (!object1 || !object2) {
        return true;
    }
    if (Array.isArray(object1) && Array.isArray(object2)) {
        if (object1.length !== object2.length) {
            return true;
        }
        for (let i = 0; i < object1.length; i++) {
            if (areDifferent(object1[i], object2[i])) {
                return true;
            }
        }
        return false;
    }
    else if (object1 && typeof object1 === 'object' && object2 && typeof object2 === 'object') {
        const object1Keys = Object.keys(object1);
        const object2Keys = Object.keys(object2);
        if (object1Keys.length !== object2Keys.length) {
            return true;
        }
        for (const key1 of object1Keys) {
            if (!(key1 in object2)) {
                return true;
            }
            if (areDifferent(object1[key1], object2[key1])) {
                return true;
            }
        }
        return false;
    }
    return true;
}

function areSame (object1, object2) {
    return !areDifferent(object1, object2);
}