<template>
    <ion-progress-bar
        v-if="!template && isTemplateDefined"
        type="indeterminate"
    />
    <f2-download
        v-if="actionType === 'file'"
        :url="resultData"
    >
        <ion-button
            ref="button"
            expand="block"
            :style="props.definition.resolvedStyles"
            :class="props.definition.resolvedClasses"
        >
            <f2-icon
                :slot="slot"
                :definition="props.definition"
                :data="data"
            />
            <span v-if="template">{{ template }}</span>
        </ion-button>
    </f2-download>
    <ion-button
        v-else
        ref="button"
        expand="block"
        :style="props.definition.resolvedStyles"
        :class="props.definition.resolvedClasses"
        @click="executeAction(); $event.stopPropagation();"
    >
        <f2-icon
            :slot="slot"
            :definition="props.definition"
            :data="data"
        />
        <span v-if="template">{{ template }}</span>
    </ion-button>
</template>
<script lang="ts">
import {defineComponent, watch, ref, Ref} from 'vue';
import {
    IonProgressBar,
    IonButton,
} from '@ionic/vue';
import {resolveTemplate} from '../functionality/template';
import {action, ActionData} from '@/functionality/actions';
import F2Icon from '../components/F2Icon.vue';
import F2Download from '../components/F2Download.vue';

export default defineComponent({
    components: {
        IonProgressBar,
        IonButton,
        F2Icon,
        F2Download,
    },
    props: {
        definition: {
            required: true,
            type: Object as () => ActionData,
        },
        data: {
            required: false,
            type: Object,
        },
    },
    setup(props, context) {
        const button: Ref = ref(null);
        const {template, isTemplateDefined} = resolveTemplate(props);
        const {
            actionType,
            executeAction,
            isExecuting,
            error,
            resultData,
        } = action(props);
        const slot = isTemplateDefined ? 'start' : 'icon-only';

        watch(error, (error) => {
            if (!error) {
                return;
            }
            console.error(error);
        });

        // generate url of file to download immediately
        if (
            actionType === 'file' &&
            props.definition.definition.type === 'download'
        ) {
            executeAction();
        }

        return {
            slot,
            props,
            isTemplateDefined,
            template,
            executeAction,
            actionType,
            isExecuting,
            error,
            resultData,
            button,
        };
    },
});


</script>
<style scoped  lang="scss">
ion-button {
	text-transform: inherit;

	&.f2-circle {
		--border-radius: 40px;
		white-space: normal;
		height: 80px;
		width: 80px;
		font-size: .52em;

		svg {
			font-size: 2em;
			width: 2em;
			height: 2em;
			border-width: 2px;
			border-style: solid;
			border-radius: 1em;
			padding: 0.1em;

			+ span {
				margin-top: 5px;
				padding-bottom: 12px;
			}
		}

		&::part(button-inner) {
			flex-flow: column;
			box-sizing: border-box;
		}
	}
}
/* .button {
	--padding-start: 0;
	--padding-end: 0;
} */
</style>
