import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f2_icon = _resolveComponent("f2-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
    class: _normalizeClass(_ctx.props.definition.resolvedClasses)
  }, [
    _createVNode(_component_f2_icon, {
      definition: _ctx.props.definition,
      data: _ctx.data
    }, null, 8, ["definition", "data"]),
    (_ctx.template)
      ? (_openBlock(), _createBlock(_component_ion_label, {
          key: 0,
          style: _normalizeStyle({
                '-webkit-line-clamp': _ctx.props.definition.maxLines,
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                'white-space': 'pre-line'
            })
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.template), 1)
          ]),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true)
  ], 6))
}