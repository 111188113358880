<template>
    <data-source-status :data-source-info="dataSourceInfo" />
    <ion-item
        v-if="isDataReady && formModel"
        v-show="!hidden"
        :class="fieldClass"
    >
        <f2-datetime
            v-if="
                definition.type === 'date' ||
                    definition.type === 'time' ||
                    definition.type === 'datetime'
            "
            :definition="definition.definition"
            :model="props.model"
            :disabled="!modifyable"
            :is-required="isRequired"
            :additional-input-attributes="additionalInputAttributes"
            :label="labelText"
        />
        <ion-input
            v-else-if="
                definition.type === 'text-input'
                    || definition.type === 'number'
                    || definition.type === 'integer'
                    || definition.type === 'float'
                    || definition.type === 'barcode'
            "
            v-model="formModel[fieldName]"
            :type="definition.type === 'text-input' || definition.type === 'barcode' ? 'text' : 'number'"
            :disabled="!modifyable"
            :placeholder="placeholder"
            :step="definition.type === 'integer' ? '1' : undefined"
            :required="isRequired"
            :label="labelText"
            label-placement="stacked"
            v-bind="additionalInputAttributes"
            @ionFocus="onFocus"
        />
        <ion-textarea
            v-else-if="definition.type === 'textarea'"
            v-model="formModel[fieldName]"
            :rows="5"
            :disabled="!modifyable"
            :required="isRequired"
            :label="labelText"
            label-placement="stacked"
            v-bind="additionalInputAttributes"
        />
        <ion-select
            v-else-if="definition.type === 'dropdown'"
            v-model="formModel[fieldName]"
            :disabled="!modifyable"
            :placeholder="placeholder"
            :multiple="isMultiSelect"
            :label="labelText"
            label-placement="stacked"
            v-bind="additionalInputAttributes"
        >
            <ion-select-option
                v-if="!isRequired"
                :value="null"
            />
            <ion-select-option
                v-for="item of items"
                :key="item.value"
                :value="item.value"
            >
                {{ item.text }}
            </ion-select-option>
        </ion-select>
        <f2-upload
            v-else-if="definition.type === 'file' || definition.type === 'signature'"
            :definition="definition"
            :model="props.model"
            :disabled="!modifyable"
            :is-required="isRequired"
            :validation-rules="validationRules"
            :label="labelText"
            :additional-input-attributes="additionalInputAttributes"
        />
        <ion-toggle
            v-else-if="definition.type === 'checkbox'"
            v-model="formModel[fieldName]"
            :disabled="!modifyable"
            :is-required="isRequired"
            label-placement="end"
            justify="start"
            v-bind="additionalInputAttributes"
        >
            {{ labelText }}
        </ion-toggle>
    </ion-item>
</template>
<script lang="ts">
import {Capacitor} from '@capacitor/core';
import {Component} from '@/framework2-sdk/f2Api';
import {defineComponent, ref, computed, watch} from 'vue';
import {
    IonItem,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonToggle,
} from '@ionic/vue';
import {useStore} from 'vuex';
import {key} from '../store';
import F2Datetime from './F2Datetime.vue';
import DataSourceStatus from './DataSourceStatus.vue';
import F2Upload from './F2Upload.vue';
import {dataSource} from '@/functionality/dataSource';
import {formField} from '@/functionality/form';
import * as barcode from '@/functionality/barcode';
import F2DataSource from '@/framework2-sdk/f2DataSource';
import resolve from '@/framework2-sdk/dependencyResolver';
import {TagsToResolve, isConditionsMet, Condition} from '../helpers';
import dataSourceRegistry from '@/dataSourceRegistry';

export default defineComponent({
    components: {
        DataSourceStatus,
        F2Datetime,
        IonInput,
        IonItem,
        IonTextarea,
        IonSelect,
        IonSelectOption,
        F2Upload,
        IonToggle,
    },
    props: {
        definition: {
            required: true,
            type: Object as () => Component,
        },
        data: {
            required: false,
            type: Object,
        },
        model: {
            required: true,
            type: Object,
        },
        dataSource: {
            required: false,
            type: Object as () => F2DataSource,
        },
        dsFieldName: {
            required: false,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
        },
        dataDefinition: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        const store = useStore(key);
        const dataSourceInfo = dataSource(props.definition.definition);
        let dynamicValues: any = {};
        const modifyable = ref(true);
        const hidden = ref(false);
        const labelClass = ref('');
        const fieldClass = ref('');
        const additionalInputAttributes = ref({});
        const dataDefinition = props.dataSource?.getDefinitionSync() || props.dataDefinition;
        const {formModel, fieldName} = formField({
            definition: props.definition.definition,
            model: props.model,
            dataSource: props.dataSource,
        });
        const validationRules = fieldName && (dataDefinition?.validation || {})[fieldName as string];
        const isMultiSelect = !!validationRules && 'isArray' in validationRules;
        const isRequired = !!validationRules && ('notNull' in validationRules || 'notEmpty' in validationRules);
        const items = computed(() => {
            const field = dataDefinition?.fields?.[props.dsFieldName || fieldName];
            const valueKey = field?.valueColumn || 'id';
            const textKey = field?.textColumn || 'description';
            return dataSourceInfo.data.value?.map((item) => ({
                value: item[valueKey],
                text: item[textKey],
            })) || [];
        });
        watch(items, (items) => {
            if (formModel[fieldName] && !items.find((item) => item.value === formModel[fieldName])) {
                formModel[fieldName] = isRequired && items.length ? items[0].value : undefined;
            }
        });
        const valuesToResolve = {
            modifyable: props.definition.definition.modifyable,
            hidden: props.definition.definition.hidden,
            defaultValue: props.definition.definition.defaultValue,
            labelClass: props.definition.definition.labelClass,
            class: props.definition.definition.class,
            additionalInputAttributes: props.definition.definition.additionalInputAttributes,
        };
        const tags = new TagsToResolve({
            self: formModel,
            storage: store.state.user.storage,
        });
        dynamicValues = resolve(valuesToResolve, tags, (isResolved) => {
            if (isResolved) {
                dynamicValues = resolve(valuesToResolve, tags, undefined, undefined, dataSourceRegistry);
                updateDynamicValues();
            }
        }, undefined, dataSourceRegistry, store.state.user.locale, false);
        if (!(fieldName in formModel) || formModel[fieldName] === null && 'defaultValue' in dynamicValues) {
            formModel[fieldName] = dynamicValues.defaultValue;
        }
        function updateDynamicValues() {
            if ('modifyable' in dynamicValues) {
                modifyable.value = typeof dynamicValues.modifyable === 'boolean' ? dynamicValues.modifyable : isConditionsMet(dynamicValues.modifyable as Condition);
            }
            if ('hidden' in dynamicValues) {
                hidden.value = typeof dynamicValues.hidden === 'boolean' ? dynamicValues.hidden : isConditionsMet(dynamicValues.hidden as Condition);
            }
            if ('labelClass' in dynamicValues && dynamicValues.labelClass) {
                labelClass.value = dynamicValues.labelClass + '';
            }
            if ('class' in dynamicValues && dynamicValues.class) {
                fieldClass.value = dynamicValues.class + '';
            }
            if ('additionalInputAttributes' in dynamicValues && dynamicValues.additionalInputAttributes) {
                additionalInputAttributes.value = dynamicValues.additionalInputAttributes;
            }
        }
        updateDynamicValues();

        const {
            isDataReady,
        } = dataSourceInfo;

        async function onFocus() {
            if (
                Capacitor.getPlatform() !== 'web' &&
				props.definition.type === 'barcode'
            ) {
                try {
                    const result = await barcode.read();
                    formModel[fieldName] = result.rawValue;
                } catch (e: any) {}
            }
        }

        const labelText = (props.definition?.definition?.label?.text_overwrite || props.definition?.definition?.label?.text);
        return {
            onFocus,
            formModel,
            fieldName,
            isDataReady,
            dataSourceInfo,
            items,
            props,
            modifyable,
            hidden,
            labelClass,
            fieldClass,
            isMultiSelect,
            isRequired,
            validationRules,
            additionalInputAttributes,
            labelText: labelText ? labelText + (isRequired ? ' *' : '') : labelText,
        };
    },
});
</script>
<style scoped>
ion-item {
	width: 100%;
}
ion-label {
    white-space: inherit !important;
}
ion-select::part(text) {
    white-space: inherit;
}
</style>
