import { replaceTagsFn } from './tools/string';
import { traverseObjectValues } from './tools/recursion';
import object from './tools/object';
import DataSourceRegistry from './dataSourceRegistry';
import f2Api from './f2ApiSingleton';
import moment from 'moment';

const EVENT_REMOVERS_PROPERTY = '__eventRemovers';

// mark tag as required by prefixing it with "!"?

/**
 * use this function to parse templates and objects containing placeholders for strings in the form of "{__idTagName.subObjectPropertyName} some other string content"
 * for objects in the form { __idTagName.subObjectPropertyName: '__idTagName.subObjectPropertyName' }
 * @param {string|object} stringOrObject required - where tags will be replaced
 * @param {object of objects} tagsToReplace required - will be used to lookup non special tags, if 1st level property (=id)
 * in this object is set it means the tags in this subobject are required
 * @param {function} onChange optional - gets called if one of the tagsToReplaces subobjects changes, requirement is that the subobject is a DataItem or some other EventEmitter
 * having a method named "__on" or "on"
 * first param is a boolean which states if all dependencies are met
 * second the containedChanges, an array containing the changed dependencies
 * third is the id of the dependency
 * fourth the dependencies object
 * @param {function} onEventsAttached this gets called once all events are attached, passes a function as argument that allows
 * to remove all events that got attached by calling "resolve", if you dont hand in onChange, you dont need to pass in this argument since
 * there will be no events to remove
 */
export default function resolve (stringOrObject, tagsToReplace, onChange, onEventsAttached = null, dataSourceRegistry, locale, forceRequiredTo) {
    if (typeof stringOrObject === 'string') {
        return resolveStringTemplate(stringOrObject, tagsToReplace, onChange, undefined, undefined, onEventsAttached, dataSourceRegistry, locale, forceRequiredTo);
    }
    else {
        return resolveObjectDependencies(stringOrObject, tagsToReplace, onChange, onEventsAttached, dataSourceRegistry, locale, forceRequiredTo);
    }
    // __source dataItem
    // __foreign.dsid.val dataSource
    // __ds.dsid.val dataSource
    /* '{__property.subProperty|date:nnn}
    {__storage.storagekey.storageprop}
    {__label.labelKey}
    {__ds.dsID.dataKey}
    {__foreign.dsID.resultprop(selfprop, foreignIDColumn:default='id')}
    {__self.propName}
    {__self.propName|foreign}'
    */
}

/**
 * returns the resolved object or string, warning: this can probably make u wait forever (when the dependency never resolves)
 * @param {*} stringOrObject
 * @param {Object} tagsToReplace
 * @returns {Promise<String|Object>}
 */
export function resolveAsync (stringOrObject, tagsToReplace, dataSourceRegistry, locale) {
    return new Promise(resolvePromise => {
        let removeFn = null;
        let string = resolve(stringOrObject, tagsToReplace, isResolved => {
            if (isResolved) {
                if (removeFn) {
                    removeFn();
                }
                resolvePromise(resolve(stringOrObject, tagsToReplace, undefined, undefined, dataSourceRegistry, locale));
            }
        }, (r) => {
            removeFn = r;
        }, dataSourceRegistry, locale, true);
        if (string !== undefined) {
            if (removeFn) {
                removeFn();
            }
            resolvePromise(string);
        }
    });
}

const requiredCheck = /^(\?|!)/;
function handleTag (tag, dependencies, tagsToReplace, onChange = null, occurenceInfo = {}, dataSourceRegistry = new DataSourceRegistry(), locale, forceRequiredTo = null) {
    if (!(EVENT_REMOVERS_PROPERTY in dependencies)) {
        dependencies.__eventRemovers = [];
    }

    let [ propsSegment, filterSegment ] = tag.split('|');
    if (!forceRequiredTo && requiredCheck.test(propsSegment)) {
        forceRequiredTo = requiredCheck[0] === '!';
        propsSegment = propsSegment.substring(1);
    }
    const fragments = propsSegment.split('.');
    const tagPrefix = '';

    let id = tagPrefix + fragments[0];
    let name = fragments[1];
    let value = undefined;
    switch (id) {
        case 'label':
            value = f2Api.getLabelTextFromCache(name);
            if (!value) {
                f2Api.getLabelText(name)
                    .then(
                        label => {
                            eventHandler(dependencies, id, filterSegment, onChange, locale)({ data: label, property: name, targetOfType: 'dataItem' });
                        },
                        () => {},
                    );
            }
            break;
        case 'foreign': {
            id = name;
            if (!(`${tagPrefix}self` in tagsToReplace)) {
                dependencies[id] = {
                    error: 'no self provided for foreign tag'
                };
                break;
            }
            const [ selfProperty, foreignIDColumn ] = fragments[2]
                .replace(/(^[^(]+\(|\)$)/g, '')
                .split(',')
                .map(v => v.trim());
            const foreignProperty = fragments[2].replace(/\(.*/, '');
            name = foreignProperty;
            // get value from tagsToReplace
            if (id in tagsToReplace) {
                const ds = tagsToReplace[id];
                const data = ds.data.find(d => d[foreignIDColumn || 'id'] === tagsToReplace[`${tagPrefix}self`][selfProperty]);
                if (data) {
                    value = data[foreignProperty];
                }
            }
            break;
        }
        case 'ds': {
            id = name;
            name = fragments[2];
            if (fragments.length > 3) {
                name = fragments
                    .slice(2)
                    .join('.');
            }
            if (!(id in dependencies) && onChange !== null) {
                const handler = eventHandler(dependencies, id, filterSegment, onChange, locale);
                const eventName = 'changed';
                dataSourceRegistry.on(eventName, id, handler);
                dependencies.__eventRemovers.push(() => {
                    dataSourceRegistry.off(eventName, id, handler);
                });
            }

            let data = dataSourceRegistry.getData(id);
            if (!data) {
                break;
            }

            value = dataSourceDataObject(data, fragments.slice(2));

            break;
        }
        default:
            id = tagPrefix + fragments.slice(0, fragments.length-1).join('.');
            name = fragments[fragments.length-1];
            if (filterSegment) {
                name += '|' + filterSegment;
            }
            value = object.accessUsingDotNotationRemoveZeroIndexesOnFail(tagsToReplace, propsSegment).data;
    }

    if (!(id in dependencies)) {
        dependencies[id] = {
        };

        if (id in tagsToReplace && onChange !== null) {
            const eventTarget = tagsToReplace[id];
            const eventProps = ['on', '__on'];
            const eventProp = eventProps.find(p => p in eventTarget);
            if (eventProp && typeof eventTarget[eventProp] === 'function') {
                const handler = eventHandler(dependencies, id, filterSegment, onChange, locale);
                const eventName = 'changed';
                eventTarget[eventProp](eventName, handler);
                dependencies.__eventRemovers.push(() => {
                    const offProp = eventProp.replace('on', 'off');
                    if (offProp in eventTarget) {
                        eventTarget[offProp](eventName, handler);
                    }
                });
            }
        }
    }

    if (!(name in dependencies[id])) {
        const dependency = {
            required: forceRequiredTo !== null ? forceRequiredTo : id in tagsToReplace,
            name,
            value,
            filteredValue: filter(value, filterSegment, locale),
            occurence: [],
            filterSegment,
        };
        dependency.resolved = dependency.required && value !== undefined || !dependency.required;
        dependencies[id][name] = dependency;
    }

    dependencies[id][name].occurence.push(occurenceInfo);

    return dependencies[id][name];
}

function dataSourceDataObject (data, selector = null) {
    if (Array.isArray(data)) {
        if (selector && selector.length) {
            if (Array.isArray(selector)) {
                if (!selector[0].match(/^\d/)) {
                    selector.unshift('0');
                }
            }
            else if (!selector.match(/^\d/)) {
                selector = `0.${selector}`;
            }
        }
        else if (!selector) {
            return data;
        }
    }
    return object.accessUsingDotNotation(data, selector);
}

function getPropertyRegex (property) {
    return new RegExp(`^${property.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(|.+)?`);
}

function eventHandler (dependencies, id, filterSegment, onChange, locale) {
    return (event) => {
        let dep = dependencies[id];
        let containedChanges = [];
        if (
            event.targetOfType === 'dataItem'
            || event.targetOfType === 'location'
        ) {
            let data = event.data;
            const regex = getPropertyRegex(event.property);
            for (let property of Object.keys(dep)) {
                if (property.match(regex) && dep[property].value !== data) {
                    dep[property].value = data;
                    dep[property].filteredValue = filter(data, filterSegment, locale);
                    dep[property].resolved = dep[property].required && data !== undefined || !dep[property].required;
                    containedChanges.push(dep[property]);
                }
            }
        }
        else {
            for (let { name, value } of Object.values(dep)) {
                let data = dataSourceDataObject(event.data, name);
                if (data !== value) {
                    dep[name].value = data;
                    dep[name].filteredValue = filter(data, filterSegment, locale);
                    dep[name].resolved = dep[name].required && data !== undefined || !dep[name].required;
                    containedChanges.push(dep[name]);
                }
            }
        }
        if (containedChanges.length && onChange) {
            onChange(isAllDependenciesResolved(dependencies), id, containedChanges, dependencies);
        }
    };
}

function isAllDependenciesResolved (dependencies) {
    let isResolved = true;
    traverseObjectValues(dependencies, (value, key, _, depth) => {
        if (depth === 2 && key === 'resolved' && value === false) {
            isResolved = false;
            return false;
        }
    });
    return isResolved;
}

function resolveStringTemplate (string, tagsToReplace = {}, onChange = null, additionalInfo = {}, dependencies = {}, onEventsAttached = null, dataSourceRegistry, locale, forceRequiredTo) {
    let isReady = true;

    let result = replaceTagsFn(string, (value, offset) => {
        let { resolved, filteredValue } = handleTag(value, dependencies, tagsToReplace, onChange, Object.assign({ offset }, additionalInfo), dataSourceRegistry, locale, forceRequiredTo);
        callOnEventsAttached(onEventsAttached, dependencies);
        if (!resolved) {
            isReady = false;
        }
        return resolved ? filteredValue + '' : '';
    }, '{__');

    if (isReady) {
        return result;
    }
}

function resolveObjectDependencies (objectToResolve, tagsToReplace, onChange, onEventsAttached = null, dataSourceRegistry, locale, forceRequiredTo) {
    let isReady = true;
    let dependencies = {};

    let result = object.clone(objectToResolve, (_, value, __, path) => {
        if (typeof value === 'string') {
            let partialResult = {};
            if (value.startsWith('__')) {
                partialResult = handleTag(value.substring(2), dependencies, tagsToReplace, onChange, { path }, dataSourceRegistry, forceRequiredTo);
                if (!partialResult.filterSegment) {
                    partialResult.filteredValue = partialResult.value;
                }
            }
            else {
                const template = resolveStringTemplate(value, tagsToReplace, onChange, { path }, dependencies, null, dataSourceRegistry, locale, forceRequiredTo);
                partialResult.resolved = template !== undefined;
                partialResult.filteredValue = template;
            }
            if (!partialResult.resolved) {
                isReady = false;
            }
            return partialResult.filteredValue;
        }
        return value;
    });

    callOnEventsAttached(onEventsAttached, dependencies);

    if (isReady) {
        return result;
    }
}

function callOnEventsAttached (onEventsAttached, dependencies) {
    if (onEventsAttached) {
        onEventsAttached(() => {
            if (EVENT_REMOVERS_PROPERTY in dependencies) {
                dependencies[EVENT_REMOVERS_PROPERTY].forEach(r => r());
            }
        }, dependencies);
    }
}

//TODO: add function filter
export const filterRegex = /^([\w]+)(\(([\w]+\s*,?\s*)*\))?$/;
function filter (value, filterString, locale = 'en') {
    if (value === undefined) {
        return '';
    }
    if (!filterString) {
        return value;
    }

    let match = filterRegex.exec(filterString);
    if (!match) {
        return value;
    }
    let [ , fnName, , params ] = match;
    if (params) {
        params = params.split(',').map(p => p.trim());
    }
    switch (fnName) {
        case 'date':
            return moment(value)
                .format(...(params || ['L LT']));
        case 'price':
            return new global.Intl.NumberFormat(locale, {
                style: 'currency',
                currency: params ? params[0] : 'EUR'
            })
                .format(parseFloat(value));
        case 'percent':
            return new global.Intl.NumberFormat(locale, {
                style: 'percent'
            })
                .format(parseFloat(value));
        case 'decimal':
            return new global.Intl.NumberFormat(locale, {
                style: 'decimal'
            })
                .format(parseFloat(value));
        case 'urlEncode':
            return encodeURIComponent(value);
        case 'jsonStringify':
            return JSON.stringify(value);
    }
    return value;
}