import dataSourceRegistry from '@/dataSourceRegistry';
import resolve from '@/framework2-sdk/dependencyResolver';
import {Component} from '@/framework2-sdk/f2Api';
import {ref, watch, Ref} from 'vue';
import {showErrorToast} from './logging';
import {TagsToResolve, isConditionsMet, Condition} from '../helpers';
import * as api from '../api';
import {useI18n} from 'vue-i18n';
import {merge} from 'lodash';
import {useStore} from 'vuex';
import {key} from '../store';

const STYLE_PROPS_TO_TRANSFORM = ['font-size', 'height', 'width'];

export function loadDefinitions(components: Component[], data?: Ref<any>) {
    const {t} = useI18n();
    const isLoadingDefinitions = ref(true);
    const resolvedComponents = ref<Array<Component>>([]);
    let errorsLoadingDefinition = false;
    (async function() {
        for (const componentDefinition of components) {
            let finalDefinition = componentDefinition;
            if (componentDefinition.uid && !componentDefinition.isComplete) {
                try {
                    const completeDefinition = await api
                        .get()
                        ?.component(componentDefinition.uid);
                    finalDefinition = merge(finalDefinition, completeDefinition as Component, finalDefinition);
                    finalDefinition.definition.uid = componentDefinition.uid;
                    finalDefinition.isComplete = true;
                } catch (e) {
                    errorsLoadingDefinition = true;
                    console.error(
                        `error loading definition of component with uid ${componentDefinition.uid}`,
                        e,
                    );
                }
            }
            if (errorsLoadingDefinition) {
                showErrorToast(t('error.loadingComponents'));
            }
            resolveStyles(finalDefinition, data?.value);
            resolveClasses(finalDefinition, data?.value);
            if (data) {
                watch(data, () => {
                    resolveStyles(finalDefinition, data.value);
                    resolveClasses(finalDefinition, data.value);
                });
            }
            resolvedComponents.value.push(finalDefinition);
        }
        isLoadingDefinitions.value = false;
    })();
    return {
        isLoadingDefinitions,
        resolvedComponents,
    };
}

function resolveStyles(component: Component, self: any) {
    if (component.definition.styles) {
        _resolve(component.definition.styles, self, (styles: any) => {
            component.definition.resolvedStyles = convertStyles(styles);
        });
    } else if (component.definition.style) {
        _resolve(component.definition.style, self, (style: any) => {
            component.definition.resolvedStyles = convertStyles(style);
        });
    }
}

function resolveClasses(component: Component, self: any) {
    if (component.definition.classes) {
        _resolve(component.definition.classes, self, (classes: any) => {
            component.definition.resolvedClasses = convertClasses(classes);
        });
    }
}

function _resolve(target: string | object, self: any, callback: Function, isFirstCall = true) {
    const store = useStore(key);
    const tags = new TagsToResolve({
        storage: store?.state.user.storage,
    });
    if (self) {
        tags.self = self;
    } else {
        delete tags.self;
    }
    callback(
        resolve(
            target,
            tags,
            isFirstCall ? (isResolved) => {
                if (isResolved) {
                    _resolve(target, self, callback, false);
                }
            } : undefined,
            undefined,
            dataSourceRegistry,
        ),
    );
}

function convertStyles(styles: any) {
    const styleObject: {[key: string]: boolean} = {};
    if (styles && typeof styles === 'object') {
        for (const [k, v] of Object.entries(styles)) {
            const match = (v as any).find((style: { value: string; condition: Condition }) => style.value && (!style.condition || isConditionsMet(style.condition)));
            if (match) {
                if (
                    STYLE_PROPS_TO_TRANSFORM.includes(k) &&
					!match.value.match(/[^\d]+/)
                ) {
                    match.value += 'px';
                    console.warn(`added "px" to style property ${k}`);
                }
                styleObject[k] = match.value;
            }
        }
    }
    return styleObject || '';
}

function convertClasses(classes: any) {
    if (Array.isArray(classes)) {
        const classObject: {[key: string]: boolean} = {};
        classes
            .forEach((cssClass: { name: string; condition: Condition }) => {
                if (cssClass.name) {
                    classObject[cssClass.name] = !cssClass.condition || isConditionsMet(cssClass.condition);
                }
            });
        return classObject;
    }
    return classes || '';
}
