import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  strong: true,
                  onClick: _ctx.submit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('action.apply')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, { class: "ion-padding-end ion-padding-start" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_radio_group, {
              value: _ctx.userSort[0].column,
              "allow-empty-selection": "true",
              onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userSort[0].column = $event.detail.value))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: field.key
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_grid, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, { class: "ion-padding-end" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_radio, {
                                    value: field.key,
                                    "label-placement": "end",
                                    justify: "start"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(field.label), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  (_ctx.userSort[0].column === field.key)
                                    ? (_openBlock(), _createBlock(_component_ion_segment, {
                                        key: 0,
                                        color: "primary",
                                        mode: "ios",
                                        value: _ctx.userSort[0].direction,
                                        onIonChange: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.userSort[0].direction = $event.detail.value), ["stop"]))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_segment_button, { value: "asc" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, { icon: _ctx.caretUpOutline }, null, 8, ["icon"])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_segment_button, { value: "desc" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["value"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}