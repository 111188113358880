import {Capacitor} from '@capacitor/core';
import {Knex} from 'knex';
import {store, PushNotificationToken} from './store';
import knex from './knex';
import {UserApplication} from './framework2-sdk/f2Api';

const DB_NAME = 'f2v3';
const SETTINGS_TABLE_NAME = 'settings';
const CURRENT_USER_STATE_SETTING_KEY = 'current_user_state';

export let db: Knex;

export interface PersistedState {
	user: {
		locale: string,
		isDarkMode: boolean,
		pushNotificationToken?: PushNotificationToken,
        email?: string,
        userId?: number,
        applications?: UserApplication[],
		credentials?: {
			username?: string,
			password?: string,
            applicationKey?: string,
			serverKey?: string,
            encryptionToken?: {
                public: string,
                private: string
            },
		},
	}
}

export async function dbSetup() {
    db = knex(DB_NAME);
    await createTables();
}

export async function getSetting(key: string) {
    const result = await db(SETTINGS_TABLE_NAME)
        .select('value')
        .where('key', key)
        .first();
    if (result) {
        return result.value;
    }
    return null;
}

export function setSetting(key: string, value: any) {
    return db(SETTINGS_TABLE_NAME)
        .insert({
            key,
            value,
        })
        .onConflict('key')
        .merge({value});
}

export function deleteSetting(key: string) {
    return db(SETTINGS_TABLE_NAME)
        .delete()
        .where('key', key);
}

export async function persistState(): Promise<PersistedState> {
    const platform = Capacitor.getPlatform();
    const isWeb = platform === 'web';
    const persistedState: PersistedState = {
        user: {
            locale: store.state.user.locale,
            isDarkMode: store.state.user.isDarkMode,
            pushNotificationToken: store.state.user.pushNotificationToken,
            email: !isWeb ? store.state.user.email : undefined,
            userId: !isWeb ? store.state.user.userId : undefined,
            applications: !isWeb ? store.state.user.applications : undefined,
        },
    };
    if (store.state.user.credentials) {
        persistedState.user.credentials = {
            username: !isWeb ? store.state.user.credentials.username : undefined,
            password: !isWeb ? store.state.user.credentials.password : undefined,
            encryptionToken: !isWeb ? store.state.user.credentials.encryptionToken : undefined,
            applicationKey: store.state.user.credentials.applicationKey,
            serverKey: store.state.user.credentials.serverKey,
        };
    }
    await setSetting(CURRENT_USER_STATE_SETTING_KEY, JSON.stringify(persistedState));
    return persistedState;
}

export async function deleteStoredState(): Promise<void> {
    await deleteSetting(CURRENT_USER_STATE_SETTING_KEY);
}

export function getStoredState(): Promise<PersistedState | null> {
    return getSetting(CURRENT_USER_STATE_SETTING_KEY);
}

export async function createTables() {
    await db.schema
        .createTableIfNotExists(SETTINGS_TABLE_NAME, (table) => {
            table
                .string('key', 255)
                .primary()
                .notNullable();
            table.text('value');
            table.timestamps(false, true);
        });
}
