import {InjectionKey} from 'vue';
import {ActionTree, createStore, MutationTree, Store, GetterTree} from 'vuex';
import {Md5} from 'ts-md5';
import {Network, ConnectionStatus} from '@capacitor/network';
import {Credentials} from './api';
import * as api from './api';
import localization from './localization';
import {Menu, MenuItem, UserApplication, TokenInfo} from './framework2-sdk/f2Api';
import {deleteStoredState, persistState, PersistedState} from './db';
import config from './config';

let lastMenuItemsCall: Promise<Array<MenuItem>> | undefined = undefined;
let lastShortcutMenuItemsCall: Promise<Array<MenuItem>> | undefined = undefined;
const prefersDark: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
prefersDark?.addEventListener('change', (e) => store.commit('user/isDarkMode', e.matches));
Network.addListener('networkStatusChange', (status) => store.commit('app/setNetworkStatus', status));
Network.getStatus().then((status) => store.commit('app/setNetworkStatus', status));

export interface State {
	app: App,
    user: User,
};

export interface PushNotificationToken {
	token: string,
	id: number,
}

export interface User {
	isLoggedIn: boolean,
    email: string,
    userId: number,
	locale: string,
	credentials: Credentials,
	lastPageUID: string,
	lastMenuID: number,
	mapKey?: string,
	logoutReason: string,
	application: UserApplication,
	applications: Array<UserApplication>,
	menus: Array<Menu>,
	menu: Menu,
	menuItems: Array<MenuItem>,
	shortcutMenu: Menu,
	shortcutMenuItems: Array<MenuItem>,
	isMenuItemsLoading: boolean,
	pushNotificationToken?: PushNotificationToken,
	storage: { [key: string]: object },
	definitions: { [key: string]: object },
	isDarkMode: boolean,
	unReadNotificationsCount: number,
}

export interface App {
	hasStaticServerKey: boolean,
	isSetupDone: boolean,
    networtkStatus: ConnectionStatus,
    isCacheActive: boolean,
    isRegistrationOpen: boolean,
    isOfflineForced: boolean,
}

export const key: InjectionKey<Store<State>> = Symbol();

const mutations: MutationTree<User> = {
    setServerKey(state: User, serverKey) {
        if (!state.credentials) {
            state.credentials = {};
        }
        state.credentials.serverKey = serverKey;
    },
    setTokenInfo(state: User, tokenInfo: TokenInfo) {
        if (!state.credentials) {
            state.credentials = {};
        }
        state.credentials.tokenInfo = tokenInfo;
    },
    authData(state: User, {
        credentials,
        mapKey,
        isLoggedIn,
        logoutReason,
        application,
        applications,
        menus,
        shortcutMenu,
        email,
        userId,
    }) {
        state.isLoggedIn = isLoggedIn;
        state.credentials = credentials;
        state.mapKey = mapKey;
        state.logoutReason = logoutReason || '';
        state.application = application;
        state.applications = applications;
        state.menus = menus;
        state.shortcutMenu = shortcutMenu;
        state.email = email;
        state.userId = userId;
    },
    email(state: User, email: string) {
        state.email = email;
    },
    restoreState(state: User, previousState: PersistedState) {
        if (previousState?.user?.credentials) {
            state.credentials = Object.assign(state.credentials || {}, previousState.user.credentials as Credentials);
        } else if (config.serverKey) {
            state.credentials = Object.assign(state.credentials || {}, {
                serverKey: config.serverKey,
            });
        }
        if (previousState?.user?.locale) {
            state.locale = previousState.user.locale;
        }
        if (previousState?.user?.locale) {
            state.isDarkMode = previousState.user.isDarkMode;
        }
        if (previousState?.user?.userId) {
            state.userId = previousState.user.userId;
        }
        if (previousState?.user?.email) {
            state.email = previousState.user.email;
        }
        if (previousState?.user?.applications) {
            state.applications = previousState.user.applications;
        }
    },
    page(state: User, pageUID: string) {
        state.lastPageUID = pageUID;
    },
    menu(state: User, {menu}) {
        state.menu = menu;
        state.isMenuItemsLoading = true;
    },
    menuItems(state: User, {menuItems}) {
        state.menuItems = menuItems;
        state.isMenuItemsLoading = false;
    },
    shortcutMenu(state: User, {shortcutMenu}) {
        state.shortcutMenuItems = shortcutMenu;
    },
    shortcutMenuItems(state: User, {shortcutMenuItems}) {
        state.shortcutMenuItems = shortcutMenuItems;
    },
    locale(state: User, locale: string) {
        state.locale = locale;
        persist();
    },
    storage(state: User, {key, value}) {
        state.storage[key] = value;
    },
    definition(state: User, {key, value}) {
        state.definitions[key] = value;
    },
    pushNotificationToken(state: User, token: PushNotificationToken) {
        state.pushNotificationToken = token;
        persist();
    },
    isDarkMode(state: User, isDarkMode: boolean) {
        state.isDarkMode = isDarkMode;
        persist();
    },
    unReadNotificationsCount(state: User, unReadNotificationsCount: number) {
        state.unReadNotificationsCount = unReadNotificationsCount;
    },
    notificationRead(state: User) {
        state.unReadNotificationsCount--;
    },
};

const actions: ActionTree<User, State> = {
    async login(context, {
        credentials,
        mapKey,
        pageUID,
        application,
        applications,
        menu,
        menus,
        shortcutMenu,
        email,
        userId,
        unReadNotificationsCount,
    }) {
        context.commit('authData', {
            credentials,
            mapKey,
            isLoggedIn: true,
            application,
            applications,
            menus,
            email,
            userId,
        });
        context.commit('page', pageUID);
        context.dispatch('changeMenu', {menu});
        context.dispatch('changeShortcutMenu', {shortcutMenu});
        context.commit('unReadNotificationsCount', unReadNotificationsCount);
        await persist();
    },
    async logout(context, {logoutReason}) {
        context.commit('authData', {
            credentials: {
                encryptionToken: context.state.credentials?.encryptionToken,
                serverKey: context.state.credentials?.serverKey,
                baseURL: context.state.credentials?.baseURL,
            },
            mapKey: null,
            isLoggedIn: false,
            logoutReason,
            application: null,
            applications: [],
            menus: [],
        });
        try {
            await deleteStoredState();
        } catch (e) {
            console.error('error on deleting persisted state', e);
        }
    },
    async changeMenu(context, {menu}) {
        context.commit('menu', {menu});
        if (menu) {
            const menuItemsCall = api.get()
                ?.menuItems(menu.id);
            if (!menuItemsCall) {
                return;
            }
            lastMenuItemsCall = menuItemsCall;
            let menuItems: Array<MenuItem> = [];
            try {
                menuItems = await menuItemsCall;
            } catch (e: any) {
                console.error(e, 'caught', 'store changeMenu');
                menuItems = [];
            }
            if (lastMenuItemsCall === menuItemsCall) {
                context.commit('menuItems', {menuItems});
            }
        } else {
            context.commit('menuItems', {menuItems: []});
        }
    },
    async changeShortcutMenu(context, {shortcutMenu}) {
        context.commit('shortcutMenu', {shortcutMenu});
        if (shortcutMenu) {
            const shortcutMenuItemsCall = api.get()
                ?.menuItems(shortcutMenu.id);
            if (!shortcutMenuItemsCall) {
                return;
            }
            lastShortcutMenuItemsCall = shortcutMenuItemsCall;
            let shortcutMenuItems: Array<MenuItem> = [];
            try {
                shortcutMenuItems = await shortcutMenuItemsCall;
            } catch (e: any) {
                console.error(e, 'caught', 'store changeShortcutMenu');
                shortcutMenuItems = [];
            }
            if (lastShortcutMenuItemsCall === shortcutMenuItemsCall) {
                context.commit('shortcutMenuItems', {shortcutMenuItems});
            }
        } else {
            context.commit('shortcutMenuItems', {shortcutMenuItems: []});
        }
    },
};

const serverKeyInits: {[serverKey: string]: Promise<any>} = {};
const getters: GetterTree<User, State> = {
    gravatar: (user: User) => {
        const gravatar = `https://www.gravatar.com/avatar/${Md5.hashStr(user.email || '')}?d=mm&s=`;
        return (size: number) => {
            return gravatar + size;
        };
    },
    serverKeyInit: (user: User) => {
        if (user.credentials?.serverKey) {
            const serverKey = user.credentials.serverKey;
            if (!serverKeyInits[serverKey]) {
                serverKeyInits[serverKey] = api.initServerConfig(serverKey)
                    .catch((e) => {
                        delete serverKeyInits[serverKey];
                        throw e;
                    });
            }
            return serverKeyInits[serverKey]
                .then((res) => {
                    document.getElementById('app')!.className = <string>user.credentials.serverKey;
                    store.commit('app/setRegistrationStatus', !!res.applications?.find((application: any) => application.public_registration_open && !application.registration_uri));
                    return res;
                });
        }
        return null;
    },
};

const appMutations: MutationTree<App> = {
    setup(appState: App) {
        appState.isSetupDone = true;
    },
    setNetworkStatus(appState: App, networkStatus: ConnectionStatus) {
        appState.networtkStatus = networkStatus;
    },
    setCacheActivationStatus(appState: App, isCacheActive: boolean) {
        appState.isCacheActive = isCacheActive;
    },
    setRegistrationStatus(appState: App, isRegistrationOpen: boolean) {
        appState.isRegistrationOpen = isRegistrationOpen;
    },
    setForceOffline(state: App, isOfflineForced: boolean) {
        state.isOfflineForced = isOfflineForced;
    },
};

export const store = createStore<State>({
    modules: {
        app: {
            namespaced: true,
            state: {
                hasStaticServerKey: !!config.serverKey,
                isSetupDone: false,
                networkStatus: undefined,
                isCacheActive: false,
                isRegistrationOpen: false,
                isOfflineForced: false,
            },
            mutations: appMutations,
            getters: {
                isOffline: (app: App) => {
                    return !app.networtkStatus?.connected || app.isOfflineForced;
                },
            },
        },
        user: {
            namespaced: true,
            state: {
                isLoggedIn: false,
                locale: localization.global.locale.value,
                credentials: null,
                lastPageUID: '',
                mapKey: null,
                application: null,
                applications: [],
                menu: null,
                menus: [],
                menuItems: [],
                shortcutMenu: null,
                shortcutMenuItems: [],
                storage: {},
                definitions: {},
                pushNotificationToken: null,
                isDarkMode: !!prefersDark && prefersDark.matches,
                unReadNotificationsCount: 0,
            },
            mutations,
            actions,
            getters,
        },
    },
});

async function persist() {
    try {
        await persistState();
    } catch (e) {
        console.error('error on persisting login state', e);
    }
}
