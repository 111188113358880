<template>
    <ion-progress-bar
        v-if="isSrc && !src"
        type="indeterminate"
    />
    <component
        :is="type === 'image' ? 'ion-img' : 'video'"
        v-else-if="isSrc"
        v-f2Src="src"
        :router-link="type === 'image' && isUid && `/files/${src}`"
        :style="props.definition.resolvedStyles"
        :class="props.definition.resolvedClasses"
        controls
    />
</template>
<script lang="ts">
import {defineComponent, ref} from 'vue';
import {
    IonProgressBar,
    IonImg,
} from '@ionic/vue';
import {resolveTemplate} from '@/functionality/template';
import {validator} from '../framework2-sdk/tools/validatorWrapper';

export default defineComponent({
    components: {
        IonProgressBar,
        IonImg,
    },
    props: {
        type: {
            type: String,
            default: 'image',
            validator: (elementName: string) => ['image', 'video'].includes(elementName),
        },
        definition: {
            required: true,
            type: Object,
        },
        data: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        const src = ref<string | null>(null);
        const isSrc = ref(true);

        if (props?.definition?.src?.startsWith('http')) {
            src.value = props.definition.src;
        } else {
            if (props?.definition?.src?.startsWith('{')) {
                const {template} = resolveTemplate({definition: {template: props.definition.src}, data: props.data});
                if (template.value === '') {
                    isSrc.value = false;
                }
                src.value = template.value;
            } else if (props?.definition?.src) {
                src.value = props?.definition?.src;
            } else {
                isSrc.value = false;
            }
        }

        return {
            isSrc,
            src,
            props,
            isUid: validator.isUid(src.value),
        };
    },
});
</script>

<style scoped>
video {
    width: 100%;
}
ion-img {
    cursor: pointer;
}
</style>
