<template>
    <fieldset>
        <legend v-if="component?.definition?.label?.text_overwrite || component?.definition?.label?.text">
            {{ component?.definition?.label?.text_overwrite || component?.definition?.label?.text }}
        </legend>
        <f2-component
            :definition="component.definition.subcomponents"
            :data="data"
            :model="model"
            :data-source="dataSource"
            :data-definition="dataDefinition"
        />
    </fieldset>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {Component} from '@/framework2-sdk/f2Api';
import F2DataSource from '@/framework2-sdk/f2DataSource';

export default defineComponent({
    props: {
        component: {
            required: true,
            type: Object as () => Component,
        },
        data: {
            required: false,
            type: Object,
        },
        model: {
            required: true,
            type: Object,
        },
        dataSource: {
            required: false,
            type: Object as () => F2DataSource,
        },
        dataDefinition: {
            required: false,
            type: Object,
        },
    },

});
</script>
