<template>
    <div>
        <f2-toolbar
            ref="toolbar"
            :definition="props.definition"
            :data-source-info="dataSourceInfo"
            :selected-items="selectedItems"
            @setpage="pagination ? pagination.changePage($event) : dataSourceInfo.dataSource?.offset(0)"
        />
        <data-source-status :data-source-info="dataSourceInfo" />
        <div style="position: relative;">
            <ion-refresher
                slot="fixed"
                @ionRefresh="doRefresh"
            >
                <ion-refresher-content />
            </ion-refresher>
            <template v-if="isDataReady && data?.length">
                <ion-list
                    :style="props.definition.resolvedStyles"
                    :class="props.definition.resolvedClasses"
                >
                    <ion-item
                        v-for="item of data"
                        :key="item.__id + '-' + item.__changed_at"
                        href="javascript:void(0)"
                        :color="selectedItems.find(_item => _item.__id === item.__id) ? 'medium' : undefined"
                        @click="toggleSelectItem(item)"
                    >
                        <f2-component
                            v-if="props.definition.look.preview.subcomponents"
                            :definition="clone(
                                props.definition.look.preview?.subcomponents,
                            )"
                            :data="item"
                        />
                        <div v-else>
                            form not implemented
                        </div>
                    </ion-item>
                </ion-list>
                <ion-infinite-scroll
                    v-if="props.definition.isInfiniteScroll && isInfiniteScrollActive"
                    @ionInfinite="ionInfinite"
                >
                    <ion-infinite-scroll-content />
                </ion-infinite-scroll>
            </template>
            <ion-label
                v-else-if="isDataReady"
                class="ion-margin"
                style="display: flex; justify-content: center"
            >
                {{ t("error.noRecordsFound") }}
            </ion-label>
        </div>
        <f2-pagination
            v-if="!props.definition.isInfiniteScroll"
            ref="pagination"
            :data-source-info="dataSourceInfo"
        />
    </div>
</template>
<script lang="ts">
import {defineComponent, ref, watch, computed} from 'vue';
import {
    IonList,
    IonItem,
    IonLabel,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    InfiniteScrollCustomEvent,
} from '@ionic/vue';
import {dataSource} from '@/functionality/dataSource';
import {useI18n} from 'vue-i18n';
import {clone} from '@/framework2-sdk/tools/object';
import DataSourceStatus from '@/components/DataSourceStatus.vue';
import F2Pagination from '@/components/F2Pagination.vue';
import F2Toolbar from '@/components/F2Toolbar.vue';

export default defineComponent({
    components: {
        F2Toolbar,
        DataSourceStatus,
        IonList,
        IonItem,
        IonLabel,
        F2Pagination,
        IonRefresher,
        IonRefresherContent,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
    },
    props: {
        definition: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        const {t} = useI18n();
        const toolbar = ref();
        const pagination = ref();

        const dataSourceInfo = dataSource(
            props.definition,
            async (fetchedDataDefinition) => {
                if (!fetchedDataDefinition.limit) {
                    fetchedDataDefinition.limit = 20;
                }
                return fetchedDataDefinition;
            },
            true,
        );

        const {
            data,
            isLoading,
            error,
            isDependenciesMet,
            isGeoLocationNeeded,
            geoLocation,
            isDataReady,
            count,
            selectedItems,
            toggleSelectItem,
        } = dataSourceInfo;

        async function doRefresh(e: any) {
            if (props.definition.isInfiniteScroll) {
                dataSourceInfo.dataSource?.offset(0);
            } else {
                await dataSourceInfo.dataSource?.fetch();
            }
            e.target.complete();
        }

        const isInfiniteScrollActive = computed(() => {
            return (dataSourceInfo.dataSource?.count || 0) > (data.value?.length || 0);
        });


        return {
            t,
            toggleSelectItem,
            clone,
            isDependenciesMet,
            props,
            isLoading,
            error,
            data,
            isGeoLocationNeeded,
            geoLocation,
            dataSourceInfo,
            isDataReady,
            count,
            selectedItems,
            doRefresh,
            toolbar,
            pagination,
            isInfiniteScrollActive,
            ionInfinite: (e: InfiniteScrollCustomEvent) => {
                if (data.value) {
                    dataSourceInfo.dataSource?.offset(data.value.length);
                    const unwatch = watch(data, () => {
                        unwatch();
                        e.target.complete();
                    });
                }
            },
        };
    },
});
</script>
<style scoped lang="scss">
	ion-item {
		--border-color: var(--ion-color-secondary);

		> ion-grid {
			padding: 8px;
		}
	}

	ion-buttons {
		margin: 0;

		ion-button {
			margin-left: 7px;
		}
	}
</style>
