import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_f2_tabstrip_content = _resolveComponent("f2-tabstrip-content")!

  return (_ctx.isLoadingDefinitions)
    ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
        key: 0,
        type: "indeterminate"
      }))
    : (_ctx.resolvedComponents?.length)
      ? (_openBlock(), _createBlock(_component_f2_tabstrip_content, {
          key: 1,
          data: _ctx.props.data,
          model: _ctx.props.model,
          "data-source": _ctx.props.dataSource,
          definition: _ctx.resolvedComponents
        }, null, 8, ["data", "model", "data-source", "definition"]))
      : _createCommentVNode("", true)
}