//DEPRECATED: requiring this file is deprecated - require object instead

module.exports = {
    traverseObjectValues
};

/**
 * recurse an object and call a function (action) for each value of all properties
 * @param {object} object to recurse
 * @param {function} action to be called for each value
 * - "this" corresponds to the handed in result (next param)
 * - first param is the value,
 * - second the key, the third is an object containing the parent object within property "parent"
 * - third param is an object containing properties "parent" parent object, "parentTree" the whole tree up to the root, "key" and "value" which are already provided as params,
 * - and "result" already provided as "this" of the action function
 * - the fourth parameter is the depth starting at 0
 * - if the action returns false the function aborts
 * @param {any} result is always available action params this and will be returned from as final result
 * @returns returns param "result" in the way its modified by action
 */
function traverseObjectValues (object, action, result = null) {
    return _traverseObjectValues(object, action, result);
}

function _traverseObjectValues (object, action, result = null, depth = 0, key = null) {
    if (depth == 0)
        result = { result, value: object, depth };
    else {
        result = { parent: result.value, value: object, result: result.result, parentTree: result, key };
    }
    if (Array.isArray(object) || object && typeof object === 'object') {
        for (const [key, value] of Object.entries(object)) {
            if (_traverseObjectValues(value, action, result, depth + 1, key) === false && depth > 0) {
                return false;
            }
        }
    }
    else if (action.call(result.result, object, key, result, depth - 1) === false && depth > 0) {
        return false;
    }
    return result.result;
}
