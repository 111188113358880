<template>
    <data-source-status :data-source-info="dataSourceInfo" />
    <f2-component
        v-if="isDataReady && definitions.length"
        :definition="definitions"
        :data="formModel"
        :model="masterModel"
        :data-source="ds"
        :data-definition="dataDefinition"
        :style="component.definition.resolvedStyles"
        :class="component.definition.resolvedClasses"
    />
    <ion-button
        v-if="isDataReady && props.isShowSubmit && isModifyable"
        :disabled="isSubmitting"
        @click="submit"
    >
        <f2-icon
            v-if="isSubmitting"
            class="spin"
            definition="mdi:loading"
            style="margin-right: 0.5em"
        />
        {{ t("action.save") }}
    </ion-button>
    <ion-text
        v-if="submitError"
        color="danger"
    >
        {{ submitError }}
    </ion-text>
    <ion-text
        v-if="isSubmitSuccess"
        color="success"
    >
        {{ t("success.recordSaved") }}
    </ion-text>
</template>
<script lang="ts">
import {defineComponent, isRef, ref, watch, watchEffect} from 'vue';
import {dataSource} from '@/functionality/dataSource';
import {useI18n} from 'vue-i18n';
import {
    IonButton,
    IonText,
} from '@ionic/vue';
import DataSourceStatus from './DataSourceStatus.vue';
import DataItem from '@/framework2-sdk/dataItem';
import {EventData} from '@/framework2-sdk/eventEmitter';
import F2Icon from './F2Icon.vue';
import DataSource, {DataSourceConfig, Label} from '@/framework2-sdk/dataSource';
import {clone} from '@/framework2-sdk/tools/object';
import dataSourceRegistry from '@/dataSourceRegistry';
import {Component} from '@/framework2-sdk/f2Api';

export default defineComponent({
    components: {
        DataSourceStatus,
        IonButton,
        IonText,
        F2Icon,
    },
    props: {
        component: {
            required: true,
            type: Object as () => Component,
        },
        data: {
            required: false,
            type: Object,
        },
        model: {
            required: false,
            type: Object,
        },
        isShowSubmit: Boolean,
        shouldCloneData: {
            required: true,
            type: Boolean,
        },
        dataDefinition: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        const {t} = useI18n();
        const dataSourceInfo = dataSource(props.component.definition);
        const isSubmitting = ref(false);
        const submitError = ref('');
        const isSubmitSuccess = ref(false);
        const isModifyable = ref(false);
        const {
            data: dsData,
            isLoading,
            isDependenciesMet,
            dataSource: ds,
            isDataReady,
        } = dataSourceInfo;
        const dataDefinition = props.component?.definition?.data ? dataSourceInfo.dataDefinition : ref(props.dataDefinition);
        const formID = props.component?.definition?.data?.source?.uid || props.component.definition?.data?.source?.id || 'master';

        // model handling
        const isMasterModel = !props.model;
        const masterModel = props.model || {};
        const formModel: any = props.data ? (!isRef(props.data) ? ref(props.data) : props.data) : ref({});
        watch(dsData, (data) => {
            if (!data?.length) {
                return;
            }
            const dsModelData = Array.isArray(data) ? data[0] as {[key: string]: any} : undefined;
            formModel.value = dsModelData?.__getValues();
        });
        masterModel[formID] = formModel;

        // handle global form dataSource in order to handle dependencies among form fields
        let dataItem: DataItem;
        if (isMasterModel) {
            const modelDataSource = new DataSource({
                id: 'form',
                data: clone(formModel.value),
            }, dataSourceRegistry);
            dataItem = modelDataSource.data as DataItem;
        } else {
            const masterDataSource = dataSourceRegistry.get('form');
            dataItem = masterDataSource?.data as DataItem;
            addAllPropertiesToDataItem(formModel.value, dataItem);
        }

        watch(formModel, (formModel) => {
            if (!formModel) {
                return;
            }
            addAllPropertiesToDataItem(formModel, dataItem);
        }, {deep: true});

        // component definition handling
        const definitions = ref<any[]>([]);
        watchEffect(() => {
            if (ds && dataDefinition.value === undefined) {
                return;
            }
            definitions.value = props.component.definition.subcomponents?.length &&
                props.component.definition.subcomponents ||
				subcomponentsFromLook(props.component, dataDefinition.value);
            definitions.value = addFormIDRecursive(definitions.value, formID);

            // check if at least one field is modifyable: if not, don't show submit
            definitions.value.forEach((form:any) => {
                if (form?.definition?.modifyable !== false) {
                    isModifyable.value = true;
                }
            });
        });

        dataItem?.__on('error', (event: EventData) => {
            if (event.data.validationErrors?.length) {
                const errors: any = {};
                event.data.validationErrors
                    .forEach((error: any) => {
                        if (!(error.key in errors)) {
                            errors[error.key] = [];
                        }
                        errors[error.key].push(error);
                    });
                dataSourceInfo.validationResult.value = {
                    hasErrors: true,
                    items: [{
                        dataItem,
                        errors,
                        hasErrors: true,
                    }],
                };
            }
        });

        async function submit() {
            submitError.value = '';
            isSubmitting.value = true;
            isSubmitSuccess.value = false;
            if (props.shouldCloneData) {
                if (props.data) {
                    dataItem.__action = 'update';
                } else {
                    dataItem.__action = 'create';
                }
            }
            try {
                const res = await ds?.sync([dataItem]);
                if (res?.some((r) => r.isError)) {
                    const message = res
                        .find((r) => r.result?.error?.validationErrors)
                        ?.result.error.message;
                    submitError.value = message || t(`error.${res.some((r) => !r.result) ? 'validationError' : 'sendingData'}`);
                    console.error('error saving form', res);
                } else {
                    isSubmitSuccess.value = true;
                    // TODO: handle offline insert here - search for this string, just do it
                    await ds?.fetch();
                }
            } catch (e) {
                submitError.value = t('error.sendingData');
                console.error(`error submitting form with id ${formID}`, e);
            }
            isSubmitting.value = false;
        }

        return {
            isSubmitSuccess,
            t,
            dataDefinition, // eslint-disable-line vue/no-dupe-keys
            isLoading,
            isDataReady,
            dataSourceInfo,
            isDependenciesMet,
            dsData,
            props,
            definitions,
            masterModel,
            formModel,
            dataItem,
            ds,
            submit,
            isSubmitting,
            submitError,
            isModifyable,
        };
    },
});

function subcomponentsFromLook(component: Component, dataDefinition?: DataSourceConfig) {
    const look = component.definition.look;
    if (look.detail.subcomponents?.length) {
        return look.detail.subcomponents;
    }
    const subcomponents: Array<any> = [];
    loopGrid(component, dataDefinition, subcomponents);
    return subcomponents;
}

function loopGrid(
    component: Component,
    dataDefinition: DataSourceConfig | undefined,
    subcomponents: Array<any>,
    look = component.definition.look,
    grid = look.detail.grid,
) {
    for (const lookComponent of grid) {
        let type = look.detail.fields[lookComponent.field]?.type;
        let def: any;
        // field grid
        if (!type) {
            const _subcomponents: Array<any> = [];
            loopGrid(component, dataDefinition, _subcomponents, look, lookComponent.grid);
            def = {
                ...lookComponent,
                definition: {
                    ...lookComponent,
                    subcomponents: _subcomponents,
                },
                type: 'input-group',
            };
            if (lookComponent.label && component.definition.labels) {
                def.definition.label = component.definition.labels.find((l: any) => l.uid === lookComponent.label);
            }
            // single field
        } else {
            if (type === 'text') {
                type = 'text-input';
            }
            def = {
                ...lookComponent,
                definition: {
                    ...look.detail.fields[lookComponent.field],
                    ...lookComponent,
                },
                type,
            };
        }
        // adding config from dataDefinition endpoint and label
        if (dataDefinition?.fields && lookComponent.field in dataDefinition.fields) {
            def.definition = {...def.definition, ...dataDefinition.fields[lookComponent.field]};
            let label: Label | undefined;
            if (def.definition.label) {
                if (component.definition.labels) {
                    label = component.definition.labels.find((l: any) => l.uid === def.definition.label);
                }
                if (!label && def.definition.label && dataDefinition.labels) {
                    label = dataDefinition.labels.find((l) => l.uid === def.definition.label);
                }
                if (label) {
                    def.definition.label = label;
                }
            }
        }
        subcomponents.push(def);
    }
}

function addFormIDRecursive(definitions: Array<any>, formID: string) {
    return definitions.map((d: any) => {
        if (!d.definition) {
            d.definition = {};
        }
        if (d.definition.subcomponents) {
            d.definition.subcomponents = addFormIDRecursive(d.definition.subcomponents, formID);
        }
        d.definition.formID = formID;
        return d;
    });
}

function addAllPropertiesToDataItem(object: any, dataItem: DataItem) {
    for (const [key, value] of Object.entries(object)) {
        dataItem[key] = value;
    }
}

</script>
