
import {Capacitor} from '@capacitor/core';
import {CapacitorSQLite, SQLiteConnection, SQLiteDBConnection} from '@capacitor-community/sqlite';
import config from './config';
import {stringify} from './framework2-sdk/tools/json';
import {knex, Knex} from 'knex';
export {Knex} from 'knex';

const Dialect = require('knex/lib/dialects/sqlite3/index.js');

async function connectToDatabase(dbName: string): Promise<{db: SQLiteDBConnection, sqlite: SQLiteConnection}> {
    const platform = Capacitor.getPlatform();
    const sqlite = new SQLiteConnection(CapacitorSQLite);
    if (platform === 'web') {
        await customElements.whenDefined('jeep-sqlite');
        const jeepSqliteEl = document.querySelector('jeep-sqlite');
        if (jeepSqliteEl != null) {
            await CapacitorSQLite.initWebStore();
        } else {
            console.error('jeepSqliteEl is null');
        }
    } else {
        try {
            // Check if isEncryption in the capacitor.config.ts
            const isEncryptInConfig = (await sqlite.isInConfigEncryption()).result;
            if (isEncryptInConfig) {
                // check if a passphrase has been stored
                const isSetPassphrase = (await sqlite.isSecretStored()).result;
                if (!isSetPassphrase) {
                    // Set a Passphrase
                    sqlite.setEncryptionSecret(config.SQLiteSecret);
                }
            }
        } catch (e) {
            console.warn('error setting sql encryption secret', e);
        }
    }

    const ret = await sqlite.checkConnectionsConsistency();
    const isConn = (await sqlite.isConnection(dbName, false)).result;
    let db;
    if (ret.result && isConn) {
        db = await sqlite.retrieveConnection(dbName, false);
    } else if (platform === 'web') {
        db = await sqlite.createConnection(dbName, false, 'no-encryption', 1, false);
    } else {
        db = await sqlite.createConnection(dbName, true, 'secret', 1, false);
    }
    await db.open();
    return {
        db,
        sqlite,
    };
}

class Database {
    dbName: string;
    db: SQLiteDBConnection | null = null;
    sqlite: SQLiteConnection | null = null;

    constructor(dbName: string, flags: number, callback: Function) {
        this.dbName = dbName;
        connectToDatabase(dbName)
            .then((connection) => {
                this.db = connection.db;
                this.sqlite = connection.sqlite;
                callback && callback();
            }, (error) => callback && callback(error));
    }

    async close(callback: Function) {
        await this.db?.close();
        this.db = null;
        this.sqlite = null;
        callback();
    }

    all(sql: string, params: any[] = [], callback: Function) {
        this.db?.query(sql, params)
            .then(
                (result) => callback(null, JSON.parse(stringify(result.values))),
                (error) => callback(error),
            );
    }

    run(sql: string, params: any[] = [], callback: Function) {
        this.db?.run(sql, params)
            .then(
                async (result) => {
                    if (Capacitor.getPlatform() === 'web') {
                        await this.sqlite?.saveToStore(this.dbName);
                    }
                    callback.call({
                        lastID: result.changes?.lastId,
                        changes: result.changes?.changes,
                    }, null, result.changes);
                },
                (error) => callback(error),
            );
    }
}

Dialect.prototype._driver = () => ({
    Database,
});

export default (dbName: string): Knex => {
    return knex({
        client: Dialect,
        useNullAsDefault: true,
        connection: {
            filename: dbName,
        },
    });
};
