import EventEmitter from './eventEmitter';
import object from './tools/object';
export default class DataItem extends EventEmitter {
    constructor (data, config) {
        super();

        this._config = Object.assign({
            primaryKey: ['id'],
        }, config);

        this.debug = this._config.debug;

        // adding __id property
        this.getId(data);

        for (const methodName of this._eventEmitterPublicMethods) {
            data[`__${methodName}`] = this[methodName].bind(this);
        }

        data.__getEvents = () => {
            return this._events;
        };
        data.__getValues = this.getValues.bind(this);
        data.__handleChange = this.handleChange.bind(this);
        data.__isDataItem = true;
        data.__changed_at = Date.now();

        this.data = new Proxy(data, {
            set: this.handleChange.bind(this),
            deleteProperty: this.handleChange.bind(this),
        });
        return this.data;
    }

    handleChange (item, property, value) {
        if (item[property] === value) {
            return true;
        }
        item[property] = value;
        if (!item.__action) {
            item.__action = 'update';
        }
        if (property.startsWith('__')) {
            return true;
        }
        item.__changed_at = Date.now();
        this.emit('changed', value, { targetOfType: 'dataItem', property, dataItem: item });
        return true;
    }

    getId (data) {
        let isIDsDefined = true;
        data.__id = '';
        for (const idProp of this._config.primaryKey) {
            if (!(idProp in data)) {
                isIDsDefined = false;
                break;
            }
            data.__id += data[idProp] + ',';
        }
        data.__id = data.__id.substring(0, data.__id.length-1);
        if (!isIDsDefined) {
            data.__id = ''+Date.now() + Math.random();
        }
    }

    _logDebug () {
        console.log(`dataItem (__id: ${this.data.__id})`, ...arguments);
    }

    getValues () {
        const clone = {};
        for (const prop of Object.keys(this.data)) {
            if (!prop.startsWith('__')) {
                clone[prop] = this.data[prop];
            }
        }
        return object.clone(clone);
    }
}