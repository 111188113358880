const { each, forOwn } = require('lodash');
const condition = require('./condition');
const conjunction = require('./conjunction');
const { buildAggregation } = require('./aggregation');

const DB_INSTRUCTIONS_PROPERTIES = {
    TABLE: 'table',
    SELECT: 'select',
    WHERE: 'where',
    ORDER_BY: 'orderBy',
    GROUP_BY: 'groupBy',
    LIMIT: 'limit',
    OFFSET: 'offset',
    AGGREGATION: 'aggregation',
};

const DEFAULT_AGGREGATION = [{ function: 'count', column: '*', alias: 'count', }];

module.exports = {
    DB_INSTRUCTIONS_PROPERTIES,
    DEFAULT_AGGREGATION,
    buildQuery,
    addTopLvlAndQuery,
    buildOrderBy,
    sanitizeSelect,
};

function buildQuery (knex, dbInstructions) {
    let query = knex(dbInstructions.table);
    if (dbInstructions.select)
        query = buildSelect(query, dbInstructions.select);
    if (dbInstructions.where)
        query = buildWhereConditions(query, dbInstructions.where);
    if (dbInstructions.orderBy)
        query = buildOrderBy(query, dbInstructions.orderBy);
    if (dbInstructions.groupBy)
        query = buildGroupBy(query, dbInstructions.groupBy);
    if (dbInstructions.limit)
        query = addLimit(query, dbInstructions.limit);
    if (dbInstructions.offset)
        query = addOffset(query, dbInstructions.offset);
    if (dbInstructions.aggregation) {
        query = buildAggregation(query, dbInstructions.aggregation);
    }
    return query;
}

function addLimit (query, limit) {
    return query.limit(limit);
}

function addOffset (query, offset) {
    return query.offset(offset);
}

function buildOrderBy (query, orderBy) {
    each(orderBy, ({ column, direction }) => {
        query.orderBy(column, direction);
    });
    return query;
}

function buildGroupBy (query, groupBy) {
    return query.groupBy(...groupBy);
}

function buildSelect (query, select) {
    let parsedSelect = [];
    if (Array.isArray(select))
        parsedSelect = select;
    else {
        forOwn(select, (alias, column) => {
            parsedSelect.push(`${column} AS ${alias}`);
        });
    }
    return query.select(...parsedSelect);
}

function buildWhereConditions (query, whereConditions, currentConjunction = 'and') {
    if (Array.isArray(whereConditions)) {
        each(whereConditions, value => {
            buildWhereConditions(query, value, currentConjunction);
        });
    }
    else if (whereConditions.filters) {
        let conj = conjunction.sanitize(whereConditions);
        if (conj) {
            query[conjunction.definitions[currentConjunction]._function](function () {
                buildWhereConditions(this, conj.filters, conj.logic);
            });
        }
    }
    else {
        condition.set(query, whereConditions, currentConjunction);
    }
    return query;
}

function addTopLvlAndQuery (dbInstructions, query) {
    if (!dbInstructions.where)
        dbInstructions.where = query;
    else
        dbInstructions.where = {
            logic: 'and',
            filters: [
                query,
                dbInstructions.where
            ]
        };
    return dbInstructions.where;
}

function removeAlias (column) {
    const lower = column.toLowerCase();
    const index = lower.indexOf(' as ');
    if (index === -1) {
        return column;
    }
    column = column.substring(0, index);
    return column.trim();
}

function removeUnallowedColumns (dbInstructions, allowedColumns = [], dbInstructionsProperty = 'select', iterableProperty = null, defaultValue = null) {
    if (dbInstructionsProperty in dbInstructions) {
        dbInstructions[dbInstructionsProperty] = dbInstructions[dbInstructionsProperty]
            .filter(c => {
                let isKeep = false;
                if (iterableProperty) {
                    c = c[iterableProperty];
                }
                c = removeAlias(c);
                isKeep = allowedColumns.includes(c);
                const identifier = c.split('.');
                if (identifier.length > 1) {
                    isKeep = allowedColumns.includes(identifier.shift() + '.*');
                }
                return isKeep;
            });
    }
    if (!(dbInstructionsProperty in dbInstructions) || dbInstructions[dbInstructionsProperty].length === 0) {
        dbInstructions[dbInstructionsProperty] = defaultValue || allowedColumns;
    }
    return dbInstructions;
}

function sanitizeSelect (dbInstructions, allowedColumns, defaultSelect = undefined) {
    if (dbInstructions.aggregation) {
        dbInstructions = removeUnallowedColumns(dbInstructions, allowedColumns, DB_INSTRUCTIONS_PROPERTIES.AGGREGATION, 'column', DEFAULT_AGGREGATION);
    }
    if (dbInstructions.select || !dbInstructions.aggregation) {
        dbInstructions = removeUnallowedColumns(dbInstructions, allowedColumns, undefined, undefined, defaultSelect);
    }
    return dbInstructions;
}
