import {store} from '@/store';
import axios, {RawAxiosRequestHeaders} from 'axios';
import {ref} from 'vue';
import * as api from '../api';

export function downloadBlob(URL: string, isUseAuthToken = true) {
    const progressPercent = ref(0);
    const isDownloading = ref(false);
    const fileName = ref(Date.now()+'');
    const fileMimeType = ref<string>('');
    const error = ref<Error | undefined>(undefined);
    const blob = ref<Blob | undefined>(undefined);
    const headers: RawAxiosRequestHeaders = {};

    const download = async function() {
        if (blob.value) {
            return;
        }
        try {
            if (isUseAuthToken && URL.startsWith(store.state.user.credentials.baseURL as string)) {
                const a = api.get();
                if (!a) {
                    error.value = new Error('cannot add auth token');
                    return;
                }
                headers['Authorization'] = await a.checkToken();
            }
            isDownloading.value = true;
            const res = await axios({
                url: URL,
                method: 'GET',
                headers,
                onDownloadProgress: (progressEvent) => {
                    if (progressEvent.total !== undefined) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        progressPercent.value = percentCompleted;
                    }
                },
                responseType: 'blob',
            });
            const contentDisposition = res.headers?.['content-disposition'];
            if (contentDisposition?.includes('filename="')) {
                const startIndex = contentDisposition.indexOf('filename="') + 10;
                fileName.value = contentDisposition.substring(
                    startIndex,
                    contentDisposition.indexOf('"', startIndex),
                );
            }
            if (res.headers?.['content-type']) {
                fileMimeType.value = res.headers?.['content-type'];
            }
            blob.value = new Blob([res.data]);
        } catch (e: any) {
            error.value = e;
        }
        isDownloading.value = false;
    };

    return {
        download,
        blob,
        progressPercent,
        isDownloading,
        error,
        fileName,
        fileMimeType,
    };
}
