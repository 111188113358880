import {action} from './actions';

export async function emit(
    eventName: string,
    definition: {
        events?: any[]
    },
    records: any,
) {
    if (!definition || !Array.isArray(definition.events)) {
        return;
    }

    const eventDefinitions = definition
        .events
        .filter((e: any) => e.type === eventName);

    if (!eventDefinitions.length) {
        return;
    }

    const actions = await Promise.all(
        eventDefinitions
            .flatMap((event: any) => {
                if (Array.isArray(event.actions)) {
                    return event.actions.map(async (definition: any) => {
                        try {
                            const {executeAction} = action({
                                definition,
                                data: records,
                            });
                            const result = await executeAction();
                            return {definition, result};
                        } catch (e) {
                            const result = {
                                isError: true,
                                error: e,
                                definition,
                            };
                            console.error('action triggered by event failed', result);
                            return result;
                        }
                    });
                }
                return [];
            }),
    );

    return {actions};
}
