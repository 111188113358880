import resolve from '../framework2-sdk/dependencyResolver';
import dataSourceRegistry from '../dataSourceRegistry';
import {useStore, Store} from 'vuex';
import {key, State} from '../store';
import {onUnmounted, Ref, ref, watch} from 'vue';
import {TagsToResolve} from '../helpers';

export function resolveTemplate(props: any) {
    const store = useStore(key);
    const template = ref('');
    let isTemplateDefined = true;
    if (props?.definition?.template) {
        const tags = new TagsToResolve({
            storage: store.state.user.storage,
        });
        if (props.data) {
            watch(() => props.data, () => {
                _resolveTemplate(props, tags, template, store);
            });
        }
        _resolveTemplate(props, tags, template, store);
    } else {
        isTemplateDefined = false;
    }

    return {
        template,
        isTemplateDefined,
    };
}

function _resolveTemplate(props: any, tags: TagsToResolve, template: Ref<string>, store: Store<State>) {
    let eventsRemover: any;
    const self = Array.isArray(props.data) ? props.data[0] : props.data;
    if (self) {
        tags.self = self;
    } else {
        delete tags.self;
    }

    const resolvedTemplate = resolve(
        props.definition.template,
        tags,
        (isAllDependenciesResolved, dependendyID, propertiesChanged) => {
            if (!isAllDependenciesResolved) {
                return;
            }
            if (eventsRemover) {
                eventsRemover();
            }
            _resolveTemplate(props, tags, template, store);
        },
        (removeEventsFn) => eventsRemover = removeEventsFn,
        dataSourceRegistry,
        store.state.user.locale,
        false,
    );

    if (resolvedTemplate !== undefined) {
        template.value = resolvedTemplate as string;
    }

    onUnmounted(() => {
        if (eventsRemover) {
            eventsRemover();
        }
    });
}
