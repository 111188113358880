<template>
    <ion-grid
        :style="props.definition.resolvedStyles"
        :class="props.definition.resolvedClasses"
    >
        <ion-progress-bar
            v-if="isLoadingDefinitions"
            type="indeterminate"
        />
        <data-source-status :data-source-info="dataSourceInfo" />
        <ion-row v-if="isDataReady">
            <ion-col
                v-for="(component, i) of resolvedComponents"
                :key="i"
                size="12"
                :size-xs="component.colSpan?.xs"
                :size-sm="component.colSpan?.sm"
                :size-md="component.colSpan?.md"
                :size-lg="component.colSpan?.lg"
                :size-xl="component.colSpan?.xl"
            >
                <f2-image-or-video
                    v-if="component.type === 'image' || component.type === 'video'"
                    :type="component.type"
                    :definition="component.definition"
                    :data="dataObject"
                />
                <f2-action
                    v-else-if="component.type === 'action'"
                    :definition="component.definition"
                    :data="dataObject"
                />
                <f2-list
                    v-else-if="component.type === 'list'"
                    :definition="component.definition"
                    :data="dataObject"
                />
                <f2-grid
                    v-else-if="component.type === 'grid'"
                    :definition="component.definition"
                    :data="dataObject"
                />
                <f2-text
                    v-else-if="component.type === 'text'"
                    :definition="component.definition"
                    :data="dataObject"
                />
                <f2-component
                    v-else-if="component.type === 'container'"
                    :definition="component.definition"
                    :data="dataObject"
                    :model="props.model"
                />
                <f2-form
                    v-else-if="component.type === 'form' || component.type === 'submit-button'"
                    :component="component"
                    :data="dataObject"
                    :model="props.model"
                    :data-definition="dataDefinition || props.dataDefinition"
                    :is-show-submit="component.type === 'submit-button'"
                    :should-clone-data="shouldCloneData"
                />
                <f2-tabstrip
                    v-else-if="component.type === 'tab'"
                    :definition="component.definition"
                    :data-source="props.dataSource"
                    :model="props.model"
                />
                <f2-map
                    v-else-if="component.type === 'map'"
                    :definition="component.definition"
                    :data-source="props.dataSource"
                />
                <f2-web-view
                    v-else-if="component.type === 'web-view'"
                    :definition="component.definition"
                    :data-source="props.dataSource"
                />
                <f2-chart
                    v-else-if="component.type === 'chart'"
                    :definition="component.definition"
                    :data-source="props.dataSource"
                />
                <f2-form-input-group
                    v-else-if="component.type === 'input-group'"
                    :component="component"
                    :data-source="props.dataSource"
                    :model="props.model"
                    :data="props.data"
                    :data-definition="dataDefinition || props.dataDefinition"
                />
                <f2-form-input
                    v-else
                    :definition="component"
                    :data-source="props.dataSource"
                    :model="props.model"
                    :data-definition="dataDefinition || props.dataDefinition"
                />
            </ion-col>
        </ion-row>
    </ion-grid>
</template>
<script lang="ts">
import {defineComponent, computed} from 'vue';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonProgressBar,
} from '@ionic/vue';
import {Component} from '@/framework2-sdk/f2Api';
import F2ImageOrVideo from './F2ImageOrVideo.vue';
import F2Action from './F2Action.vue';
import F2List from './F2List.vue';
import F2Grid from './F2Grid.vue';
import F2Text from './F2Text.vue';
import F2Form from './F2Form.vue';
import F2Tabstrip from './F2Tabstrip.vue';
import F2Map from './F2Map.vue';
import F2WebView from './F2WebView.vue';
import F2Chart from './F2Chart.vue';
import F2FormInput from './F2FormInput.vue';
import F2FormInputGroup from './F2FormInputGroup.vue';
import DataSourceStatus from './DataSourceStatus.vue';
import {dataSource} from '@/functionality/dataSource';
import {useI18n} from 'vue-i18n';
import {loadDefinitions} from '../functionality/componentDefinitions';

export default defineComponent({
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonProgressBar,
        F2ImageOrVideo,
        F2Action,
        F2List,
        F2Grid,
        F2Text,
        F2Form,
        F2FormInput,
        F2FormInputGroup,
        F2Tabstrip,
        F2Map,
        F2WebView,
        F2Chart,
        DataSourceStatus,
    },
    props: {
        definition: {
            required: true,
            type: Object as () => Array<Component> | Component,
        },
        data: {
            required: false,
            type: Object,
        },
        model: {
            required: false,
            type: Object,
        },
        dataSource: {
            required: false,
            type: Object,
        },
        shouldCloneData: {
            type: Boolean,
            default: false,
        },
        dataDefinition: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        const {t} = useI18n();

        const components = Array.isArray(props.definition) ?
            props.definition :
            props.definition.subcomponents ||
        [props.definition];

        const dataSourceInfo = dataSource(props.definition);

        const {
            data: containerData,
            isLoading,
            isDependenciesMet,
            isDataReady,
            dataDefinition,
        } = dataSourceInfo;

        const dataObject = computed(() => {
            return containerData.value ? containerData.value[0] : props.data;
        });
        const {
            isLoadingDefinitions,
            resolvedComponents,
        } = loadDefinitions(components, dataObject);

        return {
            t,
            dataObject,
            dataDefinition, // eslint-disable-line vue/no-dupe-keys
            isLoadingDefinitions,
            isLoading,
            isDependenciesMet,
            resolvedComponents,
            props,
            dataSourceInfo,
            isDataReady,
        };
    },
});

</script>
<style>
ion-grid,
ion-row,
ion-col {
  padding: 0;
  max-width: 100%;
}

ion-item {
  --padding-end: 0;
  --inner-padding-end: 0;
  --padding-start: 0;
}
</style>
