<template>
    <div class="flex f2-no-padding">
        <ion-progress-bar
            v-if="isLoading"
            type="indeterminate"
        />
        <f2-toolbar
            v-if="allSeriesUseSameDatasource"
            :definition="props.definition"
            :data-source-info="dataSourceInfo"
            :selected-items="[]"
            @setpage="pagination?.changePage($event)"
        />
        <div
            :style="{visibility: isLoading ? 'hidden' : 'visible'}"
            class="chart-container"
        >
            <canvas
                ref="canvas"
                :style="props.definition.resolvedStyles"
                :class="props.definition.resolvedClasses"
                class="chart"
            />
        </div>
        <f2-pagination
            v-if="allSeriesUseSameDatasource"
            ref="pagination"
            :data-source-info="dataSourceInfo"
        />
    </div>
</template>
<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import {IonProgressBar} from '@ionic/vue';
import F2Toolbar from '@/components/F2Toolbar.vue';
import F2Pagination from '@/components/F2Pagination.vue';
import {Chart, generateColors} from '../functionality/chart';
import {dataSource, DataSourceInfo} from '@/functionality/dataSource';
import DataSource from '@/framework2-sdk/dataSource';
import {useI18n} from 'vue-i18n';
import moment from 'moment';

export default defineComponent({
    components: {
        IonProgressBar,
        F2Toolbar,
        F2Pagination,
    },
    props: {
        definition: {
            required: true,
            type: Object,
        },
        dataSource: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        const {t} = useI18n();
        const {definition}:any = props;
        let colors:string[] = [];
        const pagination = ref();
        const canvas:any = ref(null);
        const isLoading = ref(true);
        const isDataReady = ref(false);
        const allSeriesUseSameDatasource = ref(false);
        const resolvedDataSources:any = {};
        const resolvingDataSources:Array<string> = [];

        let chart: any;
        let chartType = definition.type;
        let xAxisKey:string = 'textKey';
        let yAxisKey:string = 'valueKey';
        let xAxisType:string = '';
        let yAxisType:string = '';
        let isDateXAxis:boolean = false;
        let isCartesianChart:boolean = false;

        const series = definition.series.filter((serie:any) => {
            return serie?.data && serie?.data?.source;
        });

        const dsIds = definition.series.map((serie:any) => {
            return serie?.data?.source?.uid;
        });

        const uniqueDsIds = [...new Set(dsIds)];

        const commonDataSourceInfo = dataSource(series[0]);

        if (series.length === 1 || uniqueDsIds.length < 2) {
            allSeriesUseSameDatasource.value = true;
        }

        const getData = () => {
            for (const serie of series) {
                const dsID:string = serie?.data?.source?.uid;
                serie.label = getLabel(serie.label);

                if (resolvingDataSources.includes(dsID)) {
                    continue;
                }

                resolvingDataSources.push(dsID);
                const serieDataSourceInfo = dataSource(serie);
                let chartDataSourceInfo:DataSourceInfo;

                if (allSeriesUseSameDatasource.value === true) {
                    chartDataSourceInfo = commonDataSourceInfo;
                } else {
                    chartDataSourceInfo = serieDataSourceInfo;
                }

                const {
                    dataSource: ds,
                    isDataReady,
                } = chartDataSourceInfo;

                ds?.on('changed', onDatasourceChanged);
                watch(isDataReady, (isReady) => {
                    if (isReady) {
                        resolvedDataSources[dsID] = ds;
                        resolvingDataSources.splice(resolvingDataSources.indexOf(dsID), 1);

                        if (resolvingDataSources.length === 0) {
                            initChart();
                        }
                    }
                });
            }
        };

        const initChart = () => {
            isCartesianChart = chartType === 'cartesian';

            if (isCartesianChart) {
                chartType = 'line';
                xAxisKey = 'xAxis';
                yAxisKey = 'yAxis';
            }

            xAxisType = getAxisType(xAxisKey);
            yAxisType= getAxisType(yAxisKey);
            isDateXAxis = ['date', 'time', 'datetime'].includes(xAxisType);

            const ctx:any = canvas.value.getContext('2d');
            chart = new Chart(
                ctx,
                {
                    type: chartType,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: isCartesianChart ? {
                            x: {
                                type: isDateXAxis ? 'time' : xAxisType,
                                time: isDateXAxis ? {
                                    unit: 'time' === xAxisType ? 'hour' : 'day',
                                    unitStepSize: 1,
                                    displayFormats: {
                                        day: 'date' === xAxisType ? 'L' : 'L LT',
                                        hour: 'LT',
                                    },
                                } : undefined,
                            },
                            y: {
                                type: yAxisType,
                            },
                        } : undefined,
                        plugins: {
                            legend: {
                                position: 'bottom',
                            },
                            zoom: {
                                pan: {
                                    enabled: true,
                                    mode: 'xy',
                                },
                                zoom: {
                                    wheel: {
                                        enabled: true,
                                    },
                                    pinch: {
                                        enabled: true,
                                    },
                                    mode: 'xy',
                                },
                            },
                        },
                    },
                    data: initData(),
                },
            );
            mapData();
            isLoading.value = false;
            isDataReady.value = true;
        };

        const mapData = () => {
            const xValues:any[] = [];
            chart.data.datasets
                .forEach((dataset:any, i:number) => {
                    if (Array.isArray(dataset.data)) {
                        const serie:any = series[i];
                        const dsId:string = serie.data.source.uid;
                        const ds:DataSource = resolvedDataSources[dsId];

                        if (isCartesianChart) {
                            const scalingFactor = (serie.zScalingFactor || 1) / 50;
                            dataset.data = ds.data
                                .map((item:any) => {
                                    let x = item[serie.xAxis];
                                    if (isDateXAxis) {
                                        x = moment(x, xAxisType === 'time' ? 'hh:mm:ss' : undefined)
                                            .toDate()
                                            .getTime();
                                    }
                                    if (!xValues.includes(x)) {
                                        xValues.push(x);
                                    }

                                    return serie.type === 'bubble' ? {
                                        x,
                                        y: item[serie.yAxis],
                                        r: item[serie.zAxis] * scalingFactor,
                                    } : {
                                        x,
                                        y: item[serie.yAxis],
                                    };
                                });
                        } else {
                            dataset.backgroundColor =
							dataset.borderColor =
							dataset.hoverBackgroundColor =
							dataset.hoverBorderColor = chartType === 'radar' ? colors[i] : colors;
                            dataset.data = ds.data
                                .map((item:any) => {
                                    if (!xValues.includes(item[serie.textKey])) {
                                        xValues.push(item[serie.textKey]);
                                    }
                                    return item[serie.valueKey];
                                });
                        }
                    }
                });

            if (xAxisType === 'category' || !isCartesianChart) {
                chart.data.labels = xValues;
            } else if (isDateXAxis && xValues.length) {
                const difference = Math.max(...xValues) - Math.min(...xValues);
                const time = chart.options.scales.x.time;
                if (time.unit === 'day') {
                    const days = difference / 86400000; // 86400000ms = 1 day
                    time.unitStepSize = Math.floor(days / 20);
                } else {
                    const hours = difference / 3600000; // 3600000 = 1 hour
                    time.unitStepSize = Math.floor(hours / 20);
                }
            }

            chart.update();
        };

        const initData = () => {
            return {
                labels: series.map((serie:any, i: number) => {
                    return resolvedDataSources[serie.data.source.uid].data.map((item:any) => {
                        return item[serie[xAxisKey]];
                    });
                })[0],
                datasets: series.map((serie: any, i: number) => {
                    let type:string = serie.type;
                    let fill:boolean = false;
                    let lineTension:number = 0;
                    const color:string = colors[i % 18];

                    if (type === 'donut') {
                        type = 'doughnut';
                    } else if (type === 'spline') {
                        type = 'line';
                        lineTension = .4;
                    } else if (type === 'area') {
                        type = 'line';
                        fill = true;
                    } else if (type === 'splineArea') {
                        type = 'line';
                        fill = true;
                        lineTension = .4;
                    }

                    return {
                        data: resolvedDataSources[serie.data.source.uid].data.map((item:any) => {
                            return item[serie[yAxisKey]];
                        }),
                        label: serie.label,
                        type,
                        fill,
                        lineTension,
                        color: isCartesianChart ? color : colors,
                        backgroundColor: fill ? ((hex, alpha) => {
                            const r = parseInt(hex.slice(1, 3), 16);
                            const g = parseInt(hex.slice(3, 5), 16);
                            const b = parseInt(hex.slice(5, 7), 16);
                            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
                        })(color, .5) : isCartesianChart ? color : colors,
                        borderColor: isCartesianChart ? color : colors,
                        hoverBackgroundColor: isCartesianChart ? color : colors,
                        hoverBorderColor: isCartesianChart ? color : colors,
                        pointRadius: 2,
                        borderWidth: 2,
                    };
                }),
            };
        };

        const getAxisType = (propertyName: string):string => {
            let isDate:boolean = true;
            let isTime:boolean = true;
            let isDateTime:boolean = true;
            let isLinear:boolean = true;

            for (const serie of series) {
                const dataSource:any = resolvedDataSources[serie.data.source.uid];

                if (dataSource) {
                    const dataSourceFields:any = dataSource?.definition?._fields;
                    const property:string = serie[propertyName];
                    const field:any = dataSourceFields[property];

                    if (field) {
                        if (isDate) {
                            isDate = 'date' === field.type;
                        }
                        if (isTime) {
                            isTime = 'time' === field.type;
                        }
                        if (isDateTime) {
                            isDateTime = 'datetime' === field.type;
                        }
                        if (isLinear) {
                            isLinear = ['integer', 'float'].includes(field.type);
                        }
                    }
                }
            }
            if (isDate) {
                return 'date';
            }
            if (isTime) {
                return 'time';
            }
            if (isDateTime) {
                return 'datetime';
            }
            if (isLinear) {
                return 'linear';
            }
            return 'category';
        };

        const getLabel = (labelUID:string):string => {
            const label:any = definition?.labels?.find((l: any) => {
                return l.uid == labelUID;
            }) || null;

            if (label) {
                return label.text || label.default_text_overwrite || label.default_text;
            }
            return '';
        };

        const onDatasourceChanged = ( {sender}: { sender: any;} ) => {
            if (resolvedDataSources[sender?.id]) {
                mapData();
            }
        };

        onMounted(() => {
            const colorPrimary:string = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary');
            colors = generateColors(colorPrimary);
            getData();
        });

        return {
            t,
            pagination,
            props,
            isLoading,
            isDataReady,
            canvas,
            dataSourceInfo: commonDataSourceInfo,
            allSeriesUseSameDatasource,
        };
    },
});

</script>
<style scoped>
	.chart-container {
		width: 100%;
		min-height: calc(100vh - 224px);
		position: relative;
	}
</style>
