module.exports = {
    stringifyFlat,
    stringify,
    parse,
};

function objectToJson (object, stringJsonValuesArray) {
    let jsonString = JSON.stringify(object, (key, value) => {
        if (stringJsonValuesArray.includes(key))
            return undefined;
        return value;
    });
    jsonString = jsonString.substring(0, jsonString.length - 1);
    for (const propName of stringJsonValuesArray) {
        if (object[propName] !== undefined) {
            jsonString += `,"${propName}":${object[propName]}`;
        }
    }
    if (jsonString.startsWith('{,')) {
        jsonString = '{' + jsonString.substring(2);
    }
    return jsonString + '}';
}

/**
 * pass an flat object/array of flat objects that already contains some stringified properties that not shall be stringified again by adding them to the second parameter
 * @param {object/array} objectOrArrayOfObjects to stringify
 * @param {array - string} stringJsonValuesArray array that contains properties that already are in valid json format
 */
function stringifyFlat (objectOrArrayOfObjects, stringJsonValuesArray) {
    if (Array.isArray(objectOrArrayOfObjects)) {
        let jsonString = '[';
        for (const propName of objectOrArrayOfObjects) {
            jsonString += objectToJson(propName, stringJsonValuesArray) + ',';
        }
        if (objectOrArrayOfObjects.length) {
            jsonString = jsonString.substring(0, jsonString.length - 1);
        }
        return jsonString + ']';
    }
    else {
        return objectToJson(objectOrArrayOfObjects, stringJsonValuesArray);
    }
}

/**
 * this funtion checks if string properties are JSON strings and stringifies them properly; properly means they are then recognized as JSON by standard parsers
 * @param {*} arrayOrObject
 * @returns {string} JSON
 */
function stringify (arrayOrObject) {
    return stringifyRecursively(arrayOrObject);
}

function stringifyRecursively (arrayOrObject) {
    let result = '';
    if (Array.isArray(arrayOrObject)) {
        let length = arrayOrObject.length;
        result += '[';
        for (let i = 0; i < length; i++) {
            result += stringifyRecursively(arrayOrObject[i]) + ',';
        }
        result = trimEndComma(result);
        result += ']';
    }
    else if (arrayOrObject && typeof arrayOrObject === 'object') {
        if (arrayOrObject instanceof Date) {
            result += stringifyFlatValue(arrayOrObject);
        }
        else {
            result += '{';
            for (let key of Object.keys(arrayOrObject)) {
                if (arrayOrObject[key] === undefined) {
                    continue;
                }
                result += `"${key}":`;
                if (isJSONString(arrayOrObject[key]))
                    result += arrayOrObject[key];
                else
                    result += stringifyRecursively(arrayOrObject[key]);
                result += ',';
            }
            result = trimEndComma(result);
            result += '}';
        }
    }
    else
        result += stringifyFlatValue(arrayOrObject);
    return result;
}

// we only check for strings generated by JSON.stringify without indentation
const regJSONObject = /^{"[\w]*":"?[^",]*"?,?/;
const regJSONArray = /^\["?[^,"]*"?,?/;
function isJSONString (value) {
    if (typeof value !== 'string') {
        return false;
    }
    if (
        value.startsWith('[')
        && value.endsWith(']')
    ) {
        if (value.length === 2) {
            return true;
        }
        return regJSONArray.test(value);
    }
    else if (
        value.startsWith('{')
        && value.endsWith('}')
    ) {
        if (value.length === 2) {
            return true;
        }
        return regJSONObject.test(value);
    }
    return false;
}

function stringifyFlatValue (value) {
    return JSON.stringify(value);
}

function trimEndComma (string) {
    let length = string.length;
    if (length && string[length - 1] === ',')
        return string.substr(0, length - 1);
    return string;
}

/**
 * behaves like JSON.parse but also parses dates, if you know there are no dates use JSON.parse instead
 * @param {*} string
 * @param {*} keyHandler function that return value is added to resulting object, this function has key, value as parameters
 */
function parse (string, keyHandler = null) {
    return JSON.parse(string, (key, value) => {
        if (
            typeof value === 'string'
            && value.length === 24
            && value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/)
        ) {
            value = new Date(value);
        }
        if (keyHandler) {
            value = keyHandler(key, value);
        }
        return value;
    });
}