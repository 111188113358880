import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_data_source_status = _resolveComponent("data-source-status")!
  const _component_f2_component = _resolveComponent("f2-component")!
  const _component_f2_icon = _resolveComponent("f2-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_text = _resolveComponent("ion-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_data_source_status, { "data-source-info": _ctx.dataSourceInfo }, null, 8, ["data-source-info"]),
    (_ctx.isDataReady && _ctx.definitions.length)
      ? (_openBlock(), _createBlock(_component_f2_component, {
          key: 0,
          definition: _ctx.definitions,
          data: _ctx.formModel,
          model: _ctx.masterModel,
          "data-source": _ctx.ds,
          "data-definition": _ctx.dataDefinition,
          style: _normalizeStyle(_ctx.component.definition.resolvedStyles),
          class: _normalizeClass(_ctx.component.definition.resolvedClasses)
        }, null, 8, ["definition", "data", "model", "data-source", "data-definition", "style", "class"]))
      : _createCommentVNode("", true),
    (_ctx.isDataReady && _ctx.props.isShowSubmit && _ctx.isModifyable)
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 1,
          disabled: _ctx.isSubmitting,
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => [
            (_ctx.isSubmitting)
              ? (_openBlock(), _createBlock(_component_f2_icon, {
                  key: 0,
                  class: "spin",
                  definition: "mdi:loading",
                  style: {"margin-right":"0.5em"}
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.t("action.save")), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.submitError)
      ? (_openBlock(), _createBlock(_component_ion_text, {
          key: 2,
          color: "danger"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.submitError), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isSubmitSuccess)
      ? (_openBlock(), _createBlock(_component_ion_text, {
          key: 3,
          color: "success"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("success.recordSaved")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}