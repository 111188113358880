import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f2_icon = _resolveComponent("f2-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_f2_component = _resolveComponent("f2-component")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
    class: _normalizeClass(_ctx.props.definition.resolvedClasses)
  }, [
    _createVNode(_component_ion_segment, {
      value: _ctx.selectedTabID,
      onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTab($event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.definition, (subcomponent) => {
          return (_openBlock(), _createBlock(_component_ion_segment_button, {
            key: subcomponent.id,
            value: subcomponent.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_f2_icon, {
                class: "tab-button-icon",
                definition: subcomponent.definition
              }, null, 8, ["definition"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(subcomponent.resolvedTemplate || subcomponent.label), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"]),
    _createVNode(_TransitionGroup, { name: "left" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activatedSubcomponents, (subcomponent) => {
          return _withDirectives((_openBlock(), _createBlock(_component_f2_component, {
            key: subcomponent.id,
            class: "f2-no-padding",
            definition: subcomponent,
            data: _ctx.props.data,
            model: _ctx.props.model,
            "data-source": _ctx.props.dataSource
          }, null, 8, ["definition", "data", "model", "data-source"])), [
            [_vShow, subcomponent.id == _ctx.selectedTabID]
          ])
        }), 128))
      ]),
      _: 1
    })
  ], 6))
}