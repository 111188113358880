import {App, URLOpenListenerEvent} from '@capacitor/app';
import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import {store} from '@/store';
import {showErrorToast} from '@/functionality/logging';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('../views/Tabs.vue'),
        name: 'tabs',
        children: [
            {
                path: 'pages/:id?',
                component: () => import('../views/PagePage.vue'),
                name: 'pages',
            },
            {
                path: 'files/:uid',
                component: () => import('../views/FilePreviewPage.vue'),
                props: true,
                name: 'files',
            },
            {
                path: 'components/:id',
                component: () => import('../views/PagePage.vue'),
                name: 'components',
            },
            {
                path: 'offline',
                component: () => import('../views/OfflinePage.vue'),
                name: 'offline',
            },
            {
                path: '/barcode/read',
                component: () => import('../views/BarcodePage.vue'),
                props: true,
                name: 'readBarcode',
            },
            {
                path: '/barcode/show/:barcodeType/:value',
                component: () => import('../views/BarcodePage.vue'),
                props: true,
                name: 'showBarcode',
            },
            {
                path: 'profile',
                component: () => import('../views/ProfilePage.vue'),
                name: 'profile',
            },
            {
                path: 'notifications',
                component: () => import('../views/NotificationsPage.vue'),
                name: 'notifications',
            },
            {
                path: '/oidc-cb',
                redirect: 'login',
            },
            {
                name: 'login',
                path: '/login',
                component: () => import('../views/LoginPage.vue'),
            },
            {
                name: 'forgotPassword',
                path: '/forgot-password',
                component: () => import('../views/ForgotPasswordPage.vue'),
            },
            {
                name: 'register',
                path: '/register',
                component: () => import('../views/RegisterPage.vue'),
            },
            {
                path: '',
                redirect: '/pages',
            },
        ],
    },
    {
        name: 'setup',
        path: '/setup',
        component: () => import('../views/SetupPage.vue'),
    },
];

App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    const path = event.url.split(':/')[1];
    path && router.push(path);
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name === 'login' && to.query) {
        if (to.query.token && to.query.expiresInMinutes) {
            store.commit('user/setTokenInfo', to.query);
        } else if (to.query.error) {
            showErrorToast(<string>to.query.error);
        }
    }
    if (!store.state.app.isSetupDone) {
        if (to.name !== 'setup') {
            next({name: 'setup'});
            return;
        }
    } else {
        // accessible routes without login
        if (
            to.name === 'forgotPassword' ||
			to.name === 'register' ||
			to.name === 'readBarcode'
        ) {
            next();
            return;
        }
        if (
            to.name !== 'login' &&
			!store.state.user.isLoggedIn
        ) {
            next({name: 'login'});
            return;
        }
    }
    next();
});

router.afterEach((to, from, isFailure) => {
    if (isFailure) {
        return;
    }
    if (to.name === 'pages') {
        store.commit('user/page', to.params.id);
    }
});

export default router;
