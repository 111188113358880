import {accessUsingDotNotationRemoveZeroIndexesOnFail} from '@/framework2-sdk/tools/object';
import {watch} from 'vue';

export function formField(props: any) {
    if (!props.definition?.formID || !props.definition?.field) {
        return {
            formModel: undefined,
            fieldName: undefined,
            value: undefined,
        };
    }
    const selector = [props.definition.formID, 'value', '0'];
    const model = accessUsingDotNotationRemoveZeroIndexesOnFail(props.model, selector).data;
    const value = model[props.definition.field];
    if (props.dataSource) {
        const record = Array.isArray(props.dataSource.data) ?
            props.dataSource.data[0] :
            props.dataSource.data;
        if (record) {
            watch(model, (value) => {
                record[props.definition.field] = value[props.definition.field];
            });
        }
    }
    return {
        formModel: model,
        fieldName: props.definition.field,
        value,
    };
}
