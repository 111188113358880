// translation api
import moment from 'moment';
import {nextTick} from 'vue';
import {createI18n} from 'vue-i18n';
import * as api from './api';
import {store} from './store';

export const DEFAULT_LOCALES = [
    {language_code: 'en-us', name: 'English'},
    {language_code: 'de-de', name: 'Deutsch'},
    {language_code: 'it-it', name: 'Italiano'},
];
const languageCodes = DEFAULT_LOCALES.map((language) => language.language_code);
export const i18n = createI18n({
    legacy: false,
    locale: languageCodes
        .find((locale) => locale === navigator.language.toLocaleLowerCase()) ||
		languageCodes.find((locale) => navigator.languages.map((l) => l.toLocaleLowerCase()).includes(locale)) ||
		languageCodes[0],
    missing: (locale, key) => {
        return key
            .replace(/^.+\./, '')
            .replace(/([A-Z0-1])/g, ' $1')
            .toLocaleLowerCase();
    },
    messages: {
        'en-us': {
            'serverKey': 'Server key',
            'username': 'Username',
            'email': 'Email',
            'password': 'Password',
            'confirm password': 'Confirm password',
            'submit': 'Submit',
            'forgotPassword': 'Forgot password?',
            'signOut': 'Sign out',
            'register': 'Register',
            'accept': 'Accept',
            'applications': 'Applications',
            'please choose an application': 'please choose an application',
            'api not set': 'Please login again',
            'language': 'Language',
            'ok': 'Ok',
            'abort': 'Abort',
            'f1_is_user_origin': 'The password change can be done ONLY through the web platform Reftree.',
            'dependencies not met': 'Dependencies of component not met',
            'an email to change the password has been sent': 'An email to change the password has been sent',
            'user has no assigned applications': 'User has no assigned applications',
            'or enter with': 'or enter with',
            'insert your pin': 'Insert your pin',
            'paste from clipboard': 'Paste from clipboard',
            'authentication code': 'Authentication code',
            'get your two factor authentication key': 'Get your two factor authentication key',
            'an email with the factor authentication key has been sent': 'An email with the factor authentication key has been sent',
            'no network connection': 'No network connection',
        },
        'de-de': {
            'serverKey': 'Server key',
            'username': 'Benutzer',
            'email': 'Email',
            'password': 'Passwort',
            'confirm password': 'Password bestätigen',
            'submit': 'Senden',
            'forgotPassword': 'Passwort vergessen?',
            'signOut': 'Abmelden',
            'register': 'Registrieren',
            'accept': 'Annehmen',
            'applications': 'Anwendungen',
            'please choose an application': 'Bitte eine App auswählen',
            'api not set': 'Bitte erneut einloggen',
            'language': 'Sprache',
            'ok': 'Ok',
            'abort': 'Abbrechen',
            'f1_is_user_origin': 'The password change can be done ONLY through the web platform Reftree.',
            'dependencies not met': 'Abhängigkeiten des Komponenten nicht erfüllt',
            'an email to change the password has been sent': 'Eine Email zum ändern des Passwortes wurde versandt',
            'user has no assigned applications': 'Benutzer hat keine zugewiesenen Anwendungen',
            'or enter with': 'oder anmelden mit',
            'insert your pin': 'Geben Sie Ihre PIN ein',
            'paste from clipboard': 'Aus Zwischenablage einfügen',
            'authentication code': 'Authentifizierungscode',
            'get your two factor authentication key': 'Holen Sie sich Ihren Zwei-Faktor-Authentifizierungsschlüssel',
            'an email with the factor authentication key has been sent': 'Eine E-Mail mit dem Faktor-Authentifizierungsschlüssel wurde gesendet',
            'no network connection': 'Keine Netzwerk Verbindung',
        },
        'it-it': {
            'serverKey': 'Server key',
            'username': 'Utente',
            'email': 'E-mail',
            'password': 'Password',
            'confirm password': 'Conferma password',
            'submit': 'Invia',
            'forgotPassword': 'Password dimenticata?',
            'signOut': 'Esci',
            'register': 'Registra',
            'accept': 'Accetta',
            'applications': 'Applicazioni',
            'please choose an application': 'Selezionare applicazione',
            'api not set': 'Prego fare login',
            'language': 'Lingua',
            'ok': 'Ok',
            'abort': 'Anulla',
            'f1_is_user_origin': 'Il cambio password si può fare SOLO tramite la piattaforma Reftree web.',
            'dependencies not met': 'Dipendenze del componente non soddisfatte',
            'an email to change the password has been sent': 'È stata inviata un\'e-mail per cambiare la password',
            'user has no assigned applications': 'L\'utente non ha applicazioni assegnate',
            'or enter with': 'oppure entra con',
            'insert your pin': 'Inserisci il tuo pin',
            'paste from clipboard': 'Incolla dagli appunti',
            'authentication code': 'Codice di Autenticazione',
            'get your two factor authentication key': 'Ottieni la tua chiave di autenticazione a due fattori',
            'an email with the factor authentication key has been sent': 'È stata inviata un\'e-mail con la chiave di autenticazione del fattore',
            'no network connection': 'Nessuna connessione di rete',
        },
    },
});

export default i18n;

/**
 * set locale
 * @param {string} locale locale
 * @param {boolean} setStore setStore
 */
export function setLocale(locale: string, setStore = true) {
    api.setAPILocale(locale);
    i18n.global.locale.value = locale as 'en-us' | 'de-de' | 'it-it';
    moment.locale(locale);
    if (setStore) {
        store.commit('user/locale', locale);
    }
}

/**
 * add messages for specific locale
 * @param {string} locale
 * @param {any} messages
 */
export async function mergeTranslations(locale: string, messages: any) {
    i18n.global.mergeLocaleMessage(locale, messages);
    return nextTick();
}
