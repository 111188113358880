import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0dd4e645"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f2_toolbar = _resolveComponent("f2-toolbar")!
  const _component_data_source_status = _resolveComponent("data-source-status")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_f2_component = _resolveComponent("f2-component")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_f2_pagination = _resolveComponent("f2-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_f2_toolbar, {
      ref: "toolbar",
      definition: _ctx.props.definition,
      "data-source-info": _ctx.dataSourceInfo,
      "selected-items": _ctx.selectedItems,
      onSetpage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pagination ? _ctx.pagination.changePage($event) : _ctx.dataSourceInfo.dataSource?.offset(0)))
    }, null, 8, ["definition", "data-source-info", "selected-items"]),
    _createVNode(_component_data_source_status, { "data-source-info": _ctx.dataSourceInfo }, null, 8, ["data-source-info"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        onIonRefresh: _ctx.doRefresh
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content)
        ]),
        _: 1
      }, 8, ["onIonRefresh"]),
      (_ctx.isDataReady && _ctx.data?.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ion_list, {
              style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
              class: _normalizeClass(_ctx.props.definition.resolvedClasses)
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: item.__id + '-' + item.__changed_at,
                    href: "javascript:void(0)",
                    color: _ctx.selectedItems.find(_item => _item.__id === item.__id) ? 'medium' : undefined,
                    onClick: ($event: any) => (_ctx.toggleSelectItem(item))
                  }, {
                    default: _withCtx(() => [
                      (_ctx.props.definition.look.preview.subcomponents)
                        ? (_openBlock(), _createBlock(_component_f2_component, {
                            key: 0,
                            definition: _ctx.clone(
                                _ctx.props.definition.look.preview?.subcomponents,
                            ),
                            data: item
                          }, null, 8, ["definition", "data"]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_2, " form not implemented "))
                    ]),
                    _: 2
                  }, 1032, ["color", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["style", "class"]),
            (_ctx.props.definition.isInfiniteScroll && _ctx.isInfiniteScrollActive)
              ? (_openBlock(), _createBlock(_component_ion_infinite_scroll, {
                  key: 0,
                  onIonInfinite: _ctx.ionInfinite
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_infinite_scroll_content)
                  ]),
                  _: 1
                }, 8, ["onIonInfinite"]))
              : _createCommentVNode("", true)
          ], 64))
        : (_ctx.isDataReady)
          ? (_openBlock(), _createBlock(_component_ion_label, {
              key: 1,
              class: "ion-margin",
              style: {"display":"flex","justify-content":"center"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("error.noRecordsFound")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
    ]),
    (!_ctx.props.definition.isInfiniteScroll)
      ? (_openBlock(), _createBlock(_component_f2_pagination, {
          key: 0,
          ref: "pagination",
          "data-source-info": _ctx.dataSourceInfo
        }, null, 8, ["data-source-info"]))
      : _createCommentVNode("", true)
  ]))
}