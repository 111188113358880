const definitions = {
    'and': {
        _function: 'andWhere'
    },
    'or': {
        _function: 'orWhere'
    }
};

module.exports = {
    sanitize,
    definitions
};

function sanitize (conjunctionDefinition) {
    if (conjunctionDefinition.filters
        && Array.isArray(conjunctionDefinition.filters)
        && conjunctionDefinition.filters.length
    ) {
        return {
            logic: conjunctionDefinition.logic in definitions ? conjunctionDefinition.logic : 'and',
            filters: conjunctionDefinition.filters
        };
    }
    return null;
}