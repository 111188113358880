export default {
    SQLiteSecret: '',
    serverKey: '',
    customUrlScheme: 'f2',
    imageSizes: [
        {
            key: 'xs',
            width: 150,
        },
        {
            key: 'sm',
            width: 320,
        },
        {
            key: 'md',
            width: 768,
        },
        {
            key: 'lg',
            width: 1024,
        },
        {
            key: 'xl',
            width: 1920,
        },
    ],
};
