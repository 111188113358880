import {Icon, addCollection} from '@iconify/vue/dist/offline';
import mdi from './mdi.json';

addCollection(mdi);

export const OfflineIcon = Icon;

export function getIcon(object: { family: string, name: string }) {
    if (
        object?.name &&
        object?.family === 'mdi' &&
        object.name in mdi.icons
    ) {
        // @ts-ignore-start
        return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 ${mdi.info.height} ${mdi.info.height}'><title>${object.name}</title>${mdi.icons[object.name].body}</svg>`;
    }
}
