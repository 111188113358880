import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-238435c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "f2-no-padding" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.src && !_ctx.isInlineHtml)
      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
          key: 0,
          type: "indeterminate"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("iframe", {
      ref: "iframe",
      src: _ctx.src,
      style: _normalizeStyle(_ctx.definition.resolvedStyles),
      class: _normalizeClass(_ctx.definition.resolvedClasses)
    }, null, 14, _hoisted_2)
  ]))
}