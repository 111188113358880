<template>
    <ion-grid
        class="filter-row"
        style="flex-grow: 1;"
    >
        <ion-row>
            <ion-col
                size="12"
                size-lg="6"
            >
                <ion-item>
                    <!-- eslint-disable vue/no-mutating-props -->
                    <ion-select
                        v-model="filter.field"
                        :label="$t('label.field')"
                        label-placement="floating"
                        style="max-width: 100%"
                    >
                        <!-- eslint-enable -->
                        <ion-select-option
                            v-for="field of fields"
                            :key="field.key"
                            :value="field.key"
                        >
                            {{ field.label }}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
            <ion-col
                v-if="dsField.type !== 'boolean'"
                v-show="filter.field"
                size="12"
                size-lg="6"
            >
                <ion-item>
                    <!-- eslint-disable vue/no-mutating-props -->
                    <ion-select
                        v-model="filter.operator"
                        :label="$t('label.operator')"
                        label-placement="floating"
                        style="max-width: 100%"
                    >
                        <!-- eslint-enable -->
                        <ion-select-option
                            v-for="operator of operators"
                            :key="operator.key"
                            :value="operator.key"
                        >
                            {{ operator.label }}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col
                size="12"
                size-lg="6"
            >
                <f2-form-input
                    v-if="hasFilterValue"
                    :key="filter.field"
                    :definition="{
                        field: 'value',
                        definition: {
                            formID: 'filterModel',
                            field: 'value',
                            ...dsField,
                            label: {
                                text: fields.find(({key}) => key === filter.field)?.label
                            },
                        },
                        type: valueFieldType,
                    }"
                    :data-source="dataSource"
                    :model="{
                        filterModel: {
                            value: filter
                        }
                    }"
                    :ds-field-name="filter.field"
                    :placeholder="$t('label.value')"
                />
            </ion-col>
            <ion-col
                size="12"
                size-lg="6"
                class="ion-padding-end ion-padding-start"
                style="display: flex"
            >
                <div
                    v-if="showLogic"
                    style="width: 100%; padding-top: 20px;"
                    class="ion-align-self-end"
                >
                    <ion-segment
                        :value="filter.logic"
                        color="primary"
                        mode="ios"
                        @ionChange="filter.direction = $event.detail.value"
                    >
                        <ion-segment-button value="and">
                            <ion-label>{{ $t('label.and') }}</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="or">
                            <ion-label>{{ $t('label.or') }}</ion-label>
                        </ion-segment-button>
                    </ion-segment>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonSegment,
    IonSegmentButton,
    IonLabel,
} from '@ionic/vue';
import {
    trashBinOutline,
} from 'ionicons/icons';
import {DataSourceFields} from '@/framework2-sdk/dataSource';
import F2DataSource from '@/framework2-sdk/f2DataSource';
import F2FormInput from './F2FormInput.vue';

export default defineComponent({
    components: {
        IonGrid,
        IonRow,
        IonCol,
        IonItem,
        IonSelect,
        IonSelectOption,
        IonSegment,
        IonSegmentButton,
        IonLabel,
        F2FormInput,
    },
    props: {
        fields: {
            required: true,
            type: Array as () => any[],
        },
        dsFields: {
            required: true,
            type: Object as () => DataSourceFields,
        },
        filter: {
            required: true,
            type: Object as () => any,
        },
        showLogic: {
            required: true,
            type: Boolean,
        },
        dataSource: {
            required: true,
            type: Object as () => F2DataSource,
        },
    },
    emits: ['delete'],
    setup(props, {emit}) {
        return {
            emit,
            trashBinOutline,
        };
    },
    computed: {
        operators() {
            let operators = [
                {key: '=', label: this.$t('label.equals')},
                {key: '!=', label: this.$t('label.notEquals')},
            ];
            if (
                !this.dsField.data?.source &&
                ['integer', 'float', 'date', 'datetime', 'time']
                    .includes(this.dsField.type)
            ) {
                operators = operators.concat([
                    {key: '<', label: this.$t('label.lt')},
                    {key: '<=', label: this.$t('label.lte')},
                    {key: '>', label: this.$t('label.gt')},
                    {key: '>=', label: this.$t('label.gte')},
                ]);
            } else if (this.dsField.type === 'string') {
                operators = operators.concat([
                    {key: 'contains', label: this.$t('label.contains')},
                    {key: 'startswith', label: this.$t('label.startsWith')},
                    {key: 'endswith', label: this.$t('label.endsWith')},
                    {key: 'doesnotcontain', label: this.$t('label.doesNotContain')},
                    {key: 'isempty', label: this.$t('label.isEmpty')},
                    {key: 'isnotempty', label: this.$t('label.isNotEmpty')},
                ]);
            }
            operators = operators.concat([
                {key: 'isnull', label: this.$t('label.isNull')},
                {key: 'isnotnull', label: this.$t('label.isNotNull')},
            ]);
            return operators;
        },
        dsField() {
            return this.dsFields[this.filter.field] || {};
        },
        hasFilterValue() {
            return this.dsField.type && ![
                'isnull',
                'isnotnull',
                'isempty',
                'isnotempty',
            ].includes(this.filter.operator);
        },
        valueFieldType() {
            if (this.dsField.type === 'boolean') {
                return 'checkbox';
            }
            if (this.dsField.data?.source) {
                return 'dropdown';
            }
            if (this.dsField.type === 'integer') {
                return 'number';
            }
            if (this.dsField.type === 'string') {
                return 'text-input';
            }
            return this.dsField.type;
        },
    },
});
</script>

<style>
    .filter-row ion-select {
        width: 100%;
    }
</style>
