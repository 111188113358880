module.exports = {
    replaceTags,
    replaceTagsFn,
    replace__Tags,
    replaceTagsAsync,
    replace__TagsAsync
};

function replaceTags (string, tags, tagStart = '{', tagEnd = '}') {
    let tag = new RegExp(`${tagStart}(.*?)${tagEnd}`, 'g');
    return string.replace(tag, function (match, key) {
        return tags[key];
    });
}

function replaceTagsFn (string, func, tagStart = '{', tagEnd = '}') {
    let tag = new RegExp(`${tagStart}(.*?)${tagEnd}`, 'g');
    return string.replace(tag, function (_, key, offset) {
        return func(key, offset);
    });
}

function replace__Tags (string, tags) {
    return replaceTags(string, tags, '__', '\\b');
}

function replace__TagsAsync (string, action) {
    return replaceTagsAsync(string, action, '__', '\\b');
}

function replaceTagsAsync (string, action, tagStart = '{', tagEnd = '}') {
    let promises = [];
    let results = [];
    let cache = {};
    let match;
    let tag = new RegExp(`${tagStart}(.*?)${tagEnd}`, 'g');
    while ((match = tag.exec(string)) !== null) {
        let key = match[1];
        if (!(key in cache))
            promises.push(cache[key] = action(key));
        let result = {};
        result.__index = match.index;
        result.__lastIndex = tag.lastIndex;
        results.push(result);
        cache[key]
            .then(res => {
                result.value = res;
                return res;
            });
    }
    return Promise
        .all(promises)
        .then(() => {
            if (results.length === 1
                && results[0].__index === 0
                && string.length === results[0].__lastIndex
            )
                return results[0].value;
            for (let i = results.length -1; i > -1; i--) {
                let result = results[i];
                string = string.substring(0, result.__index) + result.value + string.slice(result.__lastIndex);
            }
            return string;
        });
}
