import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4eca79c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f2_toolbar = _resolveComponent("f2-toolbar")!
  const _component_data_source_status = _resolveComponent("data-source-status")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_f2_component = _resolveComponent("f2-component")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_f2_pagination = _resolveComponent("f2-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_f2_toolbar, {
      ref: "toolbar",
      definition: _ctx.props.definition,
      "data-source-info": _ctx.dataSourceInfo,
      "selected-items": _ctx.selectedItems,
      onSetpage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pagination.changePage($event)))
    }, null, 8, ["definition", "data-source-info", "selected-items"]),
    _createVNode(_component_data_source_status, { "data-source-info": _ctx.dataSourceInfo }, null, 8, ["data-source-info"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        onIonRefresh: _ctx.doRefresh
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content)
        ]),
        _: 1
      }, 8, ["onIonRefresh"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", {
          style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
          class: _normalizeClass(_ctx.props.definition.resolvedClasses)
        }, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subcomponents, (subcomponent) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: subcomponent.id
                }, _toDisplayString(subcomponent.resolvedTemplate || subcomponent.label), 1))
              }), 128))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_ctx.isDataReady && _ctx.data?.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.data, (item) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: item.__id + '-' + item.__changed_at,
                    class: _normalizeClass({
                                    'active ion-color-medium': _ctx.selectedItems.find(_item => _item.__id === item.__id)
                                }),
                    onClick: ($event: any) => (_ctx.toggleSelectItem(item))
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subcomponents, (subcomponent) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: subcomponent.id
                      }, [
                        _createVNode(_component_f2_component, {
                          definition: _ctx.clone(subcomponent),
                          data: item
                        }, null, 8, ["definition", "data"])
                      ]))
                    }), 128))
                  ], 10, _hoisted_3))
                }), 128))
              : (_ctx.isDataReady)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_4, [
                    _createElementVNode("td", {
                      colspan: _ctx.labels.length
                    }, [
                      _createVNode(_component_ion_label, { style: {"display":"flex","justify-content":"center"} }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("error.noRecordsFound")), 1)
                        ]),
                        _: 1
                      })
                    ], 8, _hoisted_5)
                  ]))
                : _createCommentVNode("", true)
          ])
        ], 6)
      ])
    ]),
    _createVNode(_component_f2_pagination, {
      ref: "pagination",
      "data-source-info": _ctx.dataSourceInfo
    }, null, 8, ["data-source-info"])
  ]))
}