<template>
    <div
        :style="props.definition.resolvedStyles"
        :class="props.definition.resolvedClasses"
    >
        <f2-toolbar
            :definition="props.definition"
            :data-source-info="dataSourceInfo"
            :selected-items="selectedItems"
            @setpage="pagination.changePage($event)"
        />
        <data-source-status :data-source-info="dataSourceInfo" />

        <ion-progress-bar
            v-if="isLoading || !isMarkersReady"
            type="indeterminate"
        />
        <div
            id="map-container"
            :style="{visibility: !isMarkersReady ? 'hidden' : 'visible'}"
        >
            <div id="map" />
        </div>
        <f2-pagination
            ref="pagination"
            :data-source-info="dataSourceInfo"
        />
    </div>
</template>
<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import {IonProgressBar} from '@ionic/vue';
import {dataSource} from '@/functionality/dataSource';
import DataSourceStatus from '@/components/DataSourceStatus.vue';
import F2Toolbar from '@/components/F2Toolbar.vue';
import F2Pagination from '@/components/F2Pagination.vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {key} from '@/store';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import resolve from '@/framework2-sdk/dependencyResolver';
import dataSourceRegistry from '@/dataSourceRegistry';

export default defineComponent({
    components: {
        DataSourceStatus,
        IonProgressBar,
        F2Toolbar,
        F2Pagination,
    },
    props: {
        definition: {
            required: true,
            type: Object,
        },
        dataSource: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        const {t, locale} = useI18n();
        const store = useStore(key);
        const pagination = ref();
        const mapboxToken = store.state.user.mapKey;
        let selectedMarker: L.Marker | null = null;
        const dataSourceInfo = dataSource(
            props.definition,
        );

        const {
            data,
            isLoading,
            error,
            isDependenciesMet,
            isGeoLocationNeeded,
            geoLocation,
            isDataReady,
            count,
            selectedItems,
        } = dataSourceInfo;

        const markerIcon = L.icon({
            iconUrl: 'assets/marker/marker-icon.png',
            shadowUrl: 'assets/marker/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [0, 0],
            shadowSize: [41, 41],
            shadowAnchor: [0, 0],
            popupAnchor: [15, 0],
        });

        let map: L.Map;
        const isMarkersReady = ref(false);
        onMounted(() => {
            // init map
            map = L.map('map', {
                scrollWheelZoom: false,
            }).setView([41.902782, 12.496365], 13);

            // add tile layer
            L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token='+ mapboxToken, {
                attribution: 'Ilos ' + new Date().getFullYear(),
                maxZoom: 18,
                minZoom: 2,
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1,
                accessToken: mapboxToken,
            }).addTo(map);

            map.on('click', (e) => {
                if (selectedMarker) {
                    selectedMarker.setOpacity(.8);
                    selectedMarker = null;
                    selectedItems.value.pop();
                }
            });

            setTimeout(() => {
                map.invalidateSize(true);
            }, 100);
        });

        const setMarkers = async (locations: any) => {
            // clear old markers
            map.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                    map.removeLayer(layer);
                }
            });
            if (locations.length) {
                const bounds = L.latLngBounds([]);
                for (const location of locations) {
                    if (location.latitude && location.longitude) {
                        const tags = {self: location};
                        const marker = L.marker([location.latitude, location.longitude], {
                            icon: markerIcon,
                            opacity: .8,
                        }).addTo(map);

                        let popupHTML = '<div>';
                        let showPopup = false;
                        if (props.definition.title) {
                            popupHTML += '<h4>'+ resolve(props.definition.title, tags, undefined, undefined, dataSourceRegistry, locale.value) +'</h4>';
                            showPopup = true;
                        };
                        if (props.definition.text) {
                            popupHTML += '<p>' + resolve(props.definition.text, tags, undefined, undefined, dataSourceRegistry, locale.value) + '</p>';
                            showPopup = true;
                        }
                        popupHTML += '</div>';

                        if (showPopup) {
                            const popup = L.popup({closeButton: false, autoClose: true}).setContent(popupHTML);
                            marker.bindPopup(popup);
                        }
                        marker.on('click', () => {
                            if (selectedMarker) {
                                selectedMarker.setOpacity(.8);
                                selectedMarker = null;
                                selectedItems.value.pop();
                            }
                            marker.setOpacity(1);
                            selectedMarker = marker;
                            selectedItems.value.push(location);
                        });
                        bounds.extend(marker.getLatLng());
                    }
                }
                map.fitBounds(bounds);
            }
            isMarkersReady.value = true;
            setTimeout(() => {
                map.invalidateSize(true);
            }, 100);
        };

        watch(data, (locations) => {
            setMarkers(locations);
        });

        return {
            t,
            isDependenciesMet,
            props,
            isLoading,
            error,
            data,
            isGeoLocationNeeded,
            geoLocation,
            dataSourceInfo,
            isDataReady,
            count,
            isMarkersReady,
            setMarkers,
            selectedItems,
            pagination,
        };
    },
});

</script>
<style scoped>
	#map-container {
		width: 100%;
		height: calc(100vh - 224px);
		min-height: 300px;
		position: relative;
	}
	#map {
		width: 100%;
		height: 100%;
	}

	.pointer {
		cursor: pointer;
	}
</style>
