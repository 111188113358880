
import {Capacitor} from '@capacitor/core';
import {alertController} from '@ionic/vue';
import {NdefEvent, NfcTag} from '@awesome-cordova-plugins/nfc';
import {phonePortraitOutline} from 'ionicons/icons';
import {showErrorToast} from '@/functionality/logging';
import localization from '../localization';

const isIOS = Capacitor.getPlatform() === 'ios';

export function available(): Promise<boolean> {
    return new Promise((resolve) => {
        nfc.enabled(
            () => resolve(true),
            (reason: string) => {
                if (reason === 'NFC_DISABLED') {
                    showErrorToast('error.nfcDisabled');
                } else if (reason === 'NO_NFC') {
                    showErrorToast('error.nfcNotAvailable');
                }
                resolve(false);
            },
        );
    });
}

export function read(): Promise<NfcTag> {
    if (isIOS) {
        return nfc.scanNdef();
    } else {
        return createSession();
    }
}

export function write(message: any[]) {
    function getPromise() {
        return new Promise((resolve, reject) => {
            nfc.write(message, resolve, (reason: string) => {
                console.error('Error write NFC ' + reason);
                reject(reason);
            });
        });
    }
    if (isIOS) {
        return getPromise();
    } else {
        return createSession(() => getPromise());
    }
}

export function erase() {
    if (isIOS) {
        showErrorToast('error.nfcEraseNotSupportedOnIos');
        return Promise.reject(new Error('NFC erase not supported on iOS'));
    } else {
        return createSession(
            () => new Promise((resolve, reject) => {
                nfc.erase(resolve, (reason: string) => {
                    console.error('Error erase NFC ' + reason);
                    reject(reason);
                });
            }),
        );
    }
}

async function createSession(callback?: Function): Promise<NfcTag> {
    const alert = await alertController.create({
        header: localization.global.t('info.readyToScan'),
        message: localization.global.t('info.holdYourDeviceNearTheNfcTag'),
        buttons: [
            {
                text: localization.global.t('action.cancel'),
                role: 'cancel',
            },
        ],
    });
    await fetch(phonePortraitOutline)
        .then((res) => res.text())
        .then((html) => {
            const icon = document.createElement('div');
            icon.innerHTML = `<div style="
            stroke: var(--ion-text-color, #000);
            fill: transparent;
            overflow: hidden;
            height: 150px;
            width: 150px;
            border-radius: 50%;
            border: 1px solid var(--ion-text-color, #000);
            margin: 0 auto;"><div style="width: 170px; margin: 36px -10px;">${html}</div></div>`;
            alert.getElementsByClassName('alert-head')[0].after(icon);
        });
    return new Promise<NfcTag>((resolve, reject) => {
        async function listener(nfcEvent: NdefEvent) {
            nfc.removeNdefListener(listener);
            if (callback) {
                try {
                    await callback();
                } catch (e) {
                    reject(e);
                    return;
                }
            }
            resolve(nfcEvent.tag);
        }
        nfc.addNdefListener(listener, async () => {
            await alert.present();
            const {role} = await alert.onDidDismiss();
            nfc.removeNdefListener(listener);
            reject(role);
        }, async (reason: any) => {
            console.error('Error adding NFC Listener ' + reason);
            reject(reason);
        });
    })
        .finally(async () => {
            await alert.dismiss();
            alert.parentNode?.removeChild(alert);
        });
}
