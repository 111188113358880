<template>
    <div
        :style="props.definition.resolvedStyles"
        :class="props.definition.resolvedClasses"
    >
        <f2-icon
            :definition="props.definition"
            :data="data"
        />
        <ion-label
            v-if="template"
            :style="{
                '-webkit-line-clamp': props.definition.maxLines,
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                'white-space': 'pre-line'
            }"
        >
            {{ template }}
        </ion-label>
    </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {resolveTemplate} from '../functionality/template';
import {
    IonLabel,
} from '@ionic/vue';
import F2Icon from '../components/F2Icon.vue';

export default defineComponent({
    components: {
        IonLabel,
        F2Icon,
    },
    props: {
        definition: {
            required: true,
            type: Object,
        },
        data: {
            type: Object,
        },
    },
    setup(props) {
        const {template} = resolveTemplate(props);
        return {
            props,
            template,
        };
    },
});

</script>
<style scoped lang="scss">
	div {
		display: flex;

		svg {
			flex-grow: 0;

			+ ion-label{
				margin-left: 3px;
			}
		}

		ion-label{
			flex-grow: 1;
		}
	}
	.f2-circle-icon-primary,
	.f2-circle-icon-primary-light,
	.f2-circle-icon-secondary {
		svg {
			background: var(--ion-color-primary);
			color: var(--ion-color-primary-contrast);
			font-size: 1.4em;
			width: 1.8em;
			height: 1.8em;
			border-radius: .9em;
			padding: 0.3em;
		}
	}
	.f2-circle-icon-primary-light svg {
		background: var(--ion-color-primary-tint);
		color: var(--ion-color-secondary);
	}
	.f2-circle-icon-secondary svg {
		background: var(--ion-color-secondary);
		color: var(--ion-color-secondary-contrast);
	}
</style>
