const self = {
    /**
     * resolves when all promises are resolved or rejected
     * @param {Array<Promise>} promises multiple promises
     * @returns {Promise} that resolves when all promises are completed, returns an array with results of promieses [{ isError: bool, result: whatever the promise returns - error if isError == true }]
     */
    finally: (promises) => {
        return Promise.all(
            promises
                .map(promise => {
                    if (self.isPromise(promise)) {
                        return promise
                            .then(
                                result => ({
                                    isError: false,
                                    result,
                                }),
                                result => ({
                                    isError: true,
                                    result,
                                })
                            );
                    }
                    return {
                        isError: false,
                        result: promise,
                    };
                })
        );
    },

    isPromise (val) {
        return typeof val === 'object' && typeof val.then === 'function';
    },

    handleStreamAsPromise (stream, data, outputEncoding, inputEncoding) {
        return new Promise((resolve, reject) => {
            let result = '';

            stream.on('error', error => {
                reject(error);
            });

            stream.on('readable', () => {
                const data = stream.read();
                if (data)
                    result += data.toString(outputEncoding);
            });

            stream.on('end', () => {
                resolve(result);
            });

            stream.write(data, inputEncoding);
            stream.end();
        });
    },

    new () {
        let resolve, reject;
        const promise = new Promise((re, rej) => {
            resolve = re;
            reject = rej;
        });
        promise.resolve = (...props) => {
            resolve(...props);
            return promise;
        };
        promise.reject = (...e) => {
            reject(...e);
            return promise;
        };
        return promise;
    }
};

module.exports = self;
