<template>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button
                color="primary"
                :disabled="currentPage === 1"
                @click="currentPage !== 1 ? changePage(currentPage-1) : undefined"
            >
                <offline-icon icon="mdi:skip-previous" />
            </ion-button>
        </ion-buttons>
        <ion-buttons class="pagination-center">
            <span>
                <ion-button
                    v-for="page of pages"
                    :key="page"
                    :color="page === currentPage ? undefined : 'primary'"
                    @click="page === currentPage ? undefined : changePage(page)"
                >{{ page }}</ion-button>
            </span>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button
                color="primary"
                :disabled="currentPage === amountPages"
                @click="currentPage !== amountPages ? changePage(currentPage+1) : undefined"
            >
                <offline-icon icon="mdi:skip-next" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</template>
<script lang="ts">
import {defineComponent, onUnmounted, ref} from 'vue';
import {DataSourceInfo} from '../functionality/dataSource';
import {OfflineIcon} from '@/icons/icons';

import {
    IonToolbar,
    IonButtons,
    IonButton,
} from '@ionic/vue';
import {useRoute} from 'vue-router';

const PAGES_TO_DISPLAY = 5;

export default defineComponent({
    components: {
        IonToolbar,
        IonButtons,
        IonButton,
        OfflineIcon,
    },
    props: {
        dataSourceInfo: {
            required: true,
            type: Object as () => DataSourceInfo,
        },
    },
    setup(props) {
        const dataSource = props.dataSourceInfo.dataSource;
        const route = useRoute();
        const pages = ref<number[]>([]);
        const currentPage = ref(1);
        const amountPages = ref(1);

        if ('page' in route.query) {
            console.log(route.query['page']);
        }

        if (!dataSource) {
            return {};
        }

        const createPagination = function() {
            const count = dataSource.count;

            if (!count) {
                return;
            }

            const limit = dataSource.limit();
            const offset = dataSource.offset() || 0;

            amountPages.value = Math.ceil(count/limit);

            if (!currentPage.value && !isNaN(offset)) {
                currentPage.value = offset/limit || 1;
            }
            if (!currentPage.value) {
                currentPage.value = 1;
            }

            // calculate pages to show in picker
            const half = Math.floor(PAGES_TO_DISPLAY/2);
            pages.value = [currentPage.value];
            addBefore(pages.value, half);
            addAfter(pages.value, PAGES_TO_DISPLAY-pages.value.length, amountPages.value);
            addBefore(pages.value, PAGES_TO_DISPLAY-pages.value.length);
        };

        function changePage(page: number) {
            const limit = dataSource?.limit();
            if (!limit) {
                console.error('no limit set');
                return;
            }
            currentPage.value = page;
            dataSource?.offset((page-1) * limit);
        }

        dataSource.on('changed', createPagination);

        onUnmounted(() => {
            dataSource.off('changed', createPagination);
        });

        return {
            currentPage,
            pages,
            amountPages,
            changePage,
        };
    },
});

function addBefore(pages: number[], amount: number) {
    const first = pages[0];
    for (let i = 1; i < amount; i++) {
        const add = first-i;
        if (add < 1) {
            break;
        }
        pages.unshift(add);
    }
}

function addAfter(pages: number[], amount: number, maxPageNumber: number) {
    const lastPage = pages[pages.length-1];
    for (let i = 1; i < amount; i++) {
        const add = lastPage+i;
        if (add > maxPageNumber) {
            break;
        }
        pages.push(add);
    }
}

</script>
<style scoped>
svg {
    font-size: 2em;
}

.pagination-center span {
    margin: auto;
}

.pagination-center ion-button {
    font-size: 1.2em;
}
</style>
