import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import f2Src from './directives/f2Src';

import {IonicVue} from '@ionic/vue';

// sqlite: needed for sqlite usage in the browser
import {defineCustomElements as jeepSqlite, applyPolyfills} from 'jeep-sqlite/loader';

applyPolyfills().then(() => {
    jeepSqlite(window);
});

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import '@fontsource/montserrat';

import F2Component from './components/F2Component.vue';

/* Theme variables */
import './theme/custom.scss';

// vuex store
import {store, key} from './store';

// initialize localization
import {i18n} from './localization';

// init api
import {Capacitor} from '@capacitor/core';

const app = createApp(App)
    .use(IonicVue, {
        mode: 'md',
    })
    .use(router)
    .use(i18n)
    .use(store, key)
    // used recursively
    .component('F2Component', F2Component)
    .directive('f2Src', f2Src);

const platform = Capacitor.getPlatform();
if (platform === 'ios') {
    // solve iOS console bug
    app.config.warnHandler = () => null;
}

router.isReady().then(() => {
    app.mount('#app');
});
