import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_f2_filter_row = _resolveComponent("f2-filter-row")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dismiss()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('action.abort')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  strong: true,
                  onClick: _ctx.submit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('action.apply')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.userFilters.length)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.userFilters, (item, i) => {
              return (_openBlock(), _createBlock(_component_ion_card, {
                key: i,
                value: item
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_f2_filter_row, {
                        ref_for: true,
                        ref: "filterRows",
                        fields: _ctx.fields,
                        "ds-fields": _ctx.dsFields,
                        "show-logic": _ctx.userFilters.length > 1 && i < _ctx.userFilters.length - 1,
                        filter: item,
                        "data-source": _ctx.dataSource
                      }, null, 8, ["fields", "ds-fields", "show-logic", "filter", "data-source"]),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.close,
                        class: "ion-margin-start",
                        onClick: ($event: any) => (_ctx.userFilters.splice(i, 1))
                      }, null, 8, ["icon", "onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_fab, {
          slot: "fixed",
          vertical: "bottom",
          horizontal: "end"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              color: "primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userFilters.push({
                    field: null,
                    operator: '=',
                    value: null,
                    logic: 'and',
                })))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}