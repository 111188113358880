const operators = {
    '=': {
    },
    'in': {
        _function: {
            'and': 'whereIn',
            'or': 'orWhereIn'
        }
    },
    'not in': {
        _function: {
            'and': 'whereNotIn',
            'or': 'orWhereNotIn'
        }
    },
    '<>': {
    },
    '!=': {
        conversion: '<>'
    },
    '<': {
    },
    '<=': {
    },
    '>=': {
    },
    '>': {
    },
    'like': {
    },
    'not like': {
    },
    'eq': {
        conversion: '='
    },
    'neq': {
        conversion: '<>'
    },
    'isnull': {
        _function: {
            'and': 'whereNull',
            'or': 'orWhereNull',
        },
    },
    'isnotnull': {
        _function: {
            'and': 'whereNotNull',
            'or': 'orWhereNotNull',
        },
    },
    'lt': {
        conversion: '<'
    },
    'lte': {
        conversion: '<='
    },
    'gt': {
        conversion: '>'
    },
    'gte': {
        conversion: '>='
    },
    'startswith': {
        conversion: 'like',
        valueModifier (value) {
            return value + '%';
        }
    },
    'endswith': {
        conversion: 'like',
        valueModifier (value) {
            return '%' + value;
        }
    },
    'contains': {
        conversion: 'like',
        valueModifier (value) {
            return '%' + value + '%';
        }
    },
    'doesnotcontain': {
        conversion: 'not like',
        valueModifier (value) {
            return '%' + value + '%';
        }
    },
    'isempty': {
        conversion: '='
    },
    'isnotempty': {
        conversion: '<>'
    },
};

module.exports = {
    set,
    operators
};

function set (knex, filter, conjunction = 'and') {
    if (
        filter.field
    ) {
        let operatorDefinition = getOperatorDefinition(filter.operator);
        let _function = operatorDefinition._function;
        let params;
        if (!_function) {
            _function = {
                'and': 'andWhere',
                'or': 'orWhere',
                'params': 3
            };
        }
        if (operatorDefinition.valueModifier) {
            filter.value = operatorDefinition.valueModifier(filter.value);
        }
        if (_function.params === 3) {
            params = [filter.field, operatorDefinition.operator, filter.value];
        }
        else {
            params = [filter.field, filter.value];
        }
        knex[_function[conjunction]].apply(knex, params);
        return knex;
    }
    return null;
}

function getOperatorDefinition (operator) {
    let operatorDefinition;
    if (operator in operators) {
        operatorDefinition = operators[operator];
        if (operatorDefinition.conversion) {
            operator = operatorDefinition.conversion;
        }
    }
    else {
        operator = '=';
    }
    if (!operatorDefinition)
        operatorDefinition = operators[operator];
    operatorDefinition.operator = operator;
    return operatorDefinition;
}