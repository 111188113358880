import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_data_source_status = _resolveComponent("data-source-status")!
  const _component_f2_image_or_video = _resolveComponent("f2-image-or-video")!
  const _component_f2_action = _resolveComponent("f2-action")!
  const _component_f2_list = _resolveComponent("f2-list")!
  const _component_f2_grid = _resolveComponent("f2-grid")!
  const _component_f2_text = _resolveComponent("f2-text")!
  const _component_f2_component = _resolveComponent("f2-component", true)!
  const _component_f2_form = _resolveComponent("f2-form")!
  const _component_f2_tabstrip = _resolveComponent("f2-tabstrip")!
  const _component_f2_map = _resolveComponent("f2-map")!
  const _component_f2_web_view = _resolveComponent("f2-web-view")!
  const _component_f2_chart = _resolveComponent("f2-chart")!
  const _component_f2_form_input_group = _resolveComponent("f2-form-input-group")!
  const _component_f2_form_input = _resolveComponent("f2-form-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, {
    style: _normalizeStyle(_ctx.props.definition.resolvedStyles),
    class: _normalizeClass(_ctx.props.definition.resolvedClasses)
  }, {
    default: _withCtx(() => [
      (_ctx.isLoadingDefinitions)
        ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
            key: 0,
            type: "indeterminate"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_data_source_status, { "data-source-info": _ctx.dataSourceInfo }, null, 8, ["data-source-info"]),
      (_ctx.isDataReady)
        ? (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resolvedComponents, (component, i) => {
                return (_openBlock(), _createBlock(_component_ion_col, {
                  key: i,
                  size: "12",
                  "size-xs": component.colSpan?.xs,
                  "size-sm": component.colSpan?.sm,
                  "size-md": component.colSpan?.md,
                  "size-lg": component.colSpan?.lg,
                  "size-xl": component.colSpan?.xl
                }, {
                  default: _withCtx(() => [
                    (component.type === 'image' || component.type === 'video')
                      ? (_openBlock(), _createBlock(_component_f2_image_or_video, {
                          key: 0,
                          type: component.type,
                          definition: component.definition,
                          data: _ctx.dataObject
                        }, null, 8, ["type", "definition", "data"]))
                      : (component.type === 'action')
                        ? (_openBlock(), _createBlock(_component_f2_action, {
                            key: 1,
                            definition: component.definition,
                            data: _ctx.dataObject
                          }, null, 8, ["definition", "data"]))
                        : (component.type === 'list')
                          ? (_openBlock(), _createBlock(_component_f2_list, {
                              key: 2,
                              definition: component.definition,
                              data: _ctx.dataObject
                            }, null, 8, ["definition", "data"]))
                          : (component.type === 'grid')
                            ? (_openBlock(), _createBlock(_component_f2_grid, {
                                key: 3,
                                definition: component.definition,
                                data: _ctx.dataObject
                              }, null, 8, ["definition", "data"]))
                            : (component.type === 'text')
                              ? (_openBlock(), _createBlock(_component_f2_text, {
                                  key: 4,
                                  definition: component.definition,
                                  data: _ctx.dataObject
                                }, null, 8, ["definition", "data"]))
                              : (component.type === 'container')
                                ? (_openBlock(), _createBlock(_component_f2_component, {
                                    key: 5,
                                    definition: component.definition,
                                    data: _ctx.dataObject,
                                    model: _ctx.props.model
                                  }, null, 8, ["definition", "data", "model"]))
                                : (component.type === 'form' || component.type === 'submit-button')
                                  ? (_openBlock(), _createBlock(_component_f2_form, {
                                      key: 6,
                                      component: component,
                                      data: _ctx.dataObject,
                                      model: _ctx.props.model,
                                      "data-definition": _ctx.dataDefinition || _ctx.props.dataDefinition,
                                      "is-show-submit": component.type === 'submit-button',
                                      "should-clone-data": _ctx.shouldCloneData
                                    }, null, 8, ["component", "data", "model", "data-definition", "is-show-submit", "should-clone-data"]))
                                  : (component.type === 'tab')
                                    ? (_openBlock(), _createBlock(_component_f2_tabstrip, {
                                        key: 7,
                                        definition: component.definition,
                                        "data-source": _ctx.props.dataSource,
                                        model: _ctx.props.model
                                      }, null, 8, ["definition", "data-source", "model"]))
                                    : (component.type === 'map')
                                      ? (_openBlock(), _createBlock(_component_f2_map, {
                                          key: 8,
                                          definition: component.definition,
                                          "data-source": _ctx.props.dataSource
                                        }, null, 8, ["definition", "data-source"]))
                                      : (component.type === 'web-view')
                                        ? (_openBlock(), _createBlock(_component_f2_web_view, {
                                            key: 9,
                                            definition: component.definition,
                                            "data-source": _ctx.props.dataSource
                                          }, null, 8, ["definition", "data-source"]))
                                        : (component.type === 'chart')
                                          ? (_openBlock(), _createBlock(_component_f2_chart, {
                                              key: 10,
                                              definition: component.definition,
                                              "data-source": _ctx.props.dataSource
                                            }, null, 8, ["definition", "data-source"]))
                                          : (component.type === 'input-group')
                                            ? (_openBlock(), _createBlock(_component_f2_form_input_group, {
                                                key: 11,
                                                component: component,
                                                "data-source": _ctx.props.dataSource,
                                                model: _ctx.props.model,
                                                data: _ctx.props.data,
                                                "data-definition": _ctx.dataDefinition || _ctx.props.dataDefinition
                                              }, null, 8, ["component", "data-source", "model", "data", "data-definition"]))
                                            : (_openBlock(), _createBlock(_component_f2_form_input, {
                                                key: 12,
                                                definition: component,
                                                "data-source": _ctx.props.dataSource,
                                                model: _ctx.props.model,
                                                "data-definition": _ctx.dataDefinition || _ctx.props.dataDefinition
                                              }, null, 8, ["definition", "data-source", "model", "data-definition"]))
                  ]),
                  _: 2
                }, 1032, ["size-xs", "size-sm", "size-md", "size-lg", "size-xl"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["style", "class"]))
}