import {Capacitor} from '@capacitor/core';
import {Filesystem as FS, Directory, Encoding} from '@capacitor/filesystem';
import {Network} from '@capacitor/network';
import {alertController} from '@ionic/vue';
import {isEmpty, isObject} from 'lodash';
import {RawAxiosRequestConfig, AxiosResponse} from 'axios';
import {db, getSetting, setSetting, deleteSetting} from './db';
import {Knex} from './knex';
import F2Api, {ClientType, FileInfo} from './framework2-sdk/f2Api';
import {F2DataSourceConfig, F2DataSourceAction} from './framework2-sdk/f2DataSource';
import {DataSourceFields} from './framework2-sdk/dataSource';
import {validators} from './framework2-sdk/tools/validator';
import {buildQuery, ServerDBInstructions} from './framework2-sdk/tools/querybuilder';
import localization from './localization';
import * as api from './api';
import config from './config';
import {store} from './store';
import {getUid, stringRegexpEscape} from './helpers';
import {showSuccessToast, showWarningToast} from './functionality/logging';

const PKColumnName = '__id';

type FileResltTypeName = 'arraybuffer' | 'blob' | 'base64';
type FileResltType<T> =
    T extends 'arraybuffer' ? Uint8Array :
    T extends 'blob' ? Blob :
    T extends 'base64' ? string :
    never;

export default class CacheHandler {
    isWeb: boolean;
    baseName: string;
    imageSizesRegex: string;
    maxSizeModifier: string = 'xs';
    syncedOn: Date | undefined;
    _isSyncingRequests: boolean = false;

    constructor(serverKey: string, applicationId: string, userId: number) {
        this.isWeb = Capacitor.getPlatform() === 'web';
        this.baseName = `${serverKey}_${applicationId}_${userId}`;
        this.imageSizesRegex = config.imageSizes
            .map(({key}) => key)
            .join('|');
        const maxSize = Math.max(window.outerWidth, window.outerHeight);
        for (const imageSize of config.imageSizes) {
            this.maxSizeModifier = imageSize.key as string;
            if (imageSize.width >= maxSize) {
                break;
            }
        }
    }

    get isActive(): boolean {
        return !!this.syncedOn;
    }

    get settingsName(): string {
        return `${this.baseName}-sync-date`;
    }

    get configDBPrefix(): string {
        return `config_${this.baseName}`;
    }

    get dataDBPrefix(): string {
        return `data_${this.baseName}`;
    }

    async init() {
        const syncedOn = await getSetting(this.settingsName);
        this.syncedOn = syncedOn && new Date(syncedOn);
        Network.addListener('networkStatusChange', (status) => {
            if (this.isActive && status.connected) {
                this.syncRequests();
            }
        });
    }

    get(config: RawAxiosRequestConfig, isFoceCacheRequest = false) {
        if (
            !this.isWeb &&
            config.url &&
            (isFoceCacheRequest || store.getters['app/isOffline'])
        ) {
            const isGet = !config.method || config.method.toLowerCase() === 'get';
            const errorResponse = {
                status: 0,
                message: localization.global.t('no network connection'),
            };
            // global routes to handle always
            if (isGet && config.url.match(new RegExp(`^/api/users/me$`))) {
                return getSetting(`user-info-${this.baseName}`)
                    .then((data) => {
                        if (!data) {
                            return Promise.reject(errorResponse);
                        }
                        return this._response(data);
                    });
            } else if (this.isActive) {
                let match;

                // data
                if (match = config.url.match(new RegExp(`^/api/data/(${validators.regExpIsUIDString})(/delete)?$`))) {
                    if (isGet) {
                        // read
                        return this._readData(match[1], config.params, config);
                    } else if (config.method?.toLowerCase() === 'post') {
                        // write
                        // is delete (workaround)
                        if (match[2]) {
                            return this._deleteData(match[1], config.data, config);
                        } else {
                            return this._insertData(match[1], config.data, config);
                        }
                    } else if (config.method?.toLowerCase() === 'patch') {
                        // update
                        return this._updateData(match[1], config.data, config);
                    } else if (config.method?.toLowerCase() === 'delete') {
                        // delete
                        return this._deleteData(match[1], config.data, config);
                    }
                } else if (isGet) {
                    if (match = config.url.match(new RegExp(`^/api/data/(${validators.regExpIsUIDString})/definition$`))) {
                        // data definition
                        return this._selectSingleConfig(`${this.configDBPrefix}_data_definitions`, {
                            uid: match[1],
                        });
                    } else if (match = config.url.match(new RegExp(`^/api/(components|pages)/(${validators.regExpIsUIDString})$`))) {
                        // component, page
                        return this._selectSingleConfig(`${this.configDBPrefix}_${match[1]}`, {
                            uid: match[2],
                        });
                    } else if (match = config.url.match(new RegExp(`^/api/files(/(${validators.regExpIsUIDString}))?(/(${this.imageSizesRegex}|info))?$`))) {
                        // file
                        if (match[4] === 'info') {
                            return this._selectSingleConfig(`${this.configDBPrefix}_files`, {
                                uid: match[2],
                            });
                        } else if (match[2]) {
                            // return this.getFile(match[2], 'base64', match[4])
                            //     .then(this._response);
                        } else {
                            const dbInstructions = config.params && config.params.q ? JSON.parse(global.atob(config.params.q)) : {};
                            dbInstructions.table = `${this.configDBPrefix}_files`;
                            dbInstructions.select = ['config'];
                            return buildQuery(db, dbInstructions)
                                .then((files: any[]) => files.map((file) => file.config))
                                .then(this._response);
                        }
                    } else if (match = config.url.match(new RegExp('^/api/menus/([0-9]+)/items$'))) {
                        // menu items
                        return this._selectMultiple({
                            table: `${this.configDBPrefix}_menu_items`,
                            where: {
                                field: 'menu_id',
                                value: match[1],
                            },
                            orderBy: [{
                                column: 'sequence_number',
                            }],
                        });
                    } else if (match = config.url.match(new RegExp('^/api/(menus|tasks)/me$'))) {
                        // menus & tasks
                        return this._selectMultiple({
                            table: `${this.configDBPrefix}_${match[1]}`,
                        });
                    }
                } else if (
                    config.data &&
                    config.method?.toLowerCase() === 'post' &&
                    config.url.match(new RegExp(`^/api/files$`))
                ) {
                    return (async () => {
                        const requestConfig: any = {
                            files: {},
                        };
                        const files = (config.data as FormData).entries();
                        for (const [fileName, file] of files) {
                            const uid = getUid();
                            const b64String: string = await new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(file as File);
                                reader.onload = () => resolve(reader.result as string);
                                reader.onerror = (error) => reject(error);
                            });
                            const {uri} = await FS.writeFile({
                                path: `${this.baseName}/${uid}`,
                                data: b64String,
                                directory: Directory.Data,
                                recursive: true,
                            });
                            requestConfig.files[uid] = fileName;
                            await this._insertFile({
                                uid,
                                name: fileName,
                                path: uri,
                                mime_type: (file as File).type,
                                size_in_bytes: (file as File).size,
                            }, true);
                        }
                        await db(`${this.configDBPrefix}_requests`)
                            .insert({
                                config: JSON.stringify(requestConfig),
                            });
                        return this._response(Object.keys(requestConfig.files));
                    })();
                }

                console.error('route nor defined in cache', config);
            }
            return Promise.reject(errorResponse);
        }
    }

    async getFile<T extends FileResltTypeName>(uid: string, type: T = 'base64' as T, sizeModifier?: string): Promise<FileResltType<T> | undefined> {
        if (!this.isWeb && this.isActive) {
            const file = await db(`${this.configDBPrefix}_files`)
                .first('mime_type', 'is_thumbnail_stored')
                .where({
                    uid,
                    is_stored: true,
                });
            if (file) {
                let path = `${this.baseName}/${uid}`;
                if (file.is_thumbnail_stored && sizeModifier === 'xs') {
                    path += '-xs';
                    if (file.mime_type && file.mime_type.startsWith('video/')) {
                        file.mime_type = 'image/jpeg';
                    }
                }

                return this._getFile(path, type, file.mime_type as string);
            }
        }
    }

    async getStaticDataDefinitionContent(uid: string) {
        const {data} = await FS.readFile({
            path: `${this.baseName}/${uid}`,
            directory: Directory.Data,
            encoding: Encoding.UTF8,
        });
        return data;
    }

    async set(config: RawAxiosRequestConfig, response: AxiosResponse) {
        if (
            !this.isWeb &&
            config.url &&
            (!config.method || config.method.toLowerCase() === 'get')
        ) {
            if (config.url.match(new RegExp(`^/api/users/me$`))) {
                await setSetting(`user-info-${this.baseName}`, JSON.stringify(response.data));
            }
        }
    }

    async sync(onProgressUpdate: (type: 'config' | 'data' | 'files', progress: number) => void) {
        if (this.isWeb) {
            throw new Error('Not supported in web');
        }
        const startedSettinsName = `${this.baseName}-sync-started`;
        try {
            const f2Api = api.get() as F2Api;

            // clear before sync again
            if (this.isActive || await getSetting(startedSettinsName)) {
                await this.clear();
            }

            setSetting(startedSettinsName, '1');
            let configProgress = 0;
            let dataProgress = 0;
            let filesProgress = 0;
            const requestMaxProgress = .95;
            const [configCount] = await Promise.all([
                f2Api.getOfflineConfigCount(),
                this._initConfigDB(),
            ]);

            // START CONFIGS
            onProgressUpdate('config', configProgress += .05);
            const interval = setInterval(() => {
                if (configProgress < requestMaxProgress) {
                    onProgressUpdate('config', configProgress += ((requestMaxProgress - .05) / configCount));
                }
            }, 100);
            const client: ClientType = !this.isWeb ? 'mobile' : 'web';
            const objects = await f2Api.getOfflineConfig(client);
            clearInterval(interval);
            onProgressUpdate('config', requestMaxProgress);
            let dataDefinitions: F2DataSourceConfig[] = [];
            await Promise.all(
                objects
                    .map(async ({name, items}) => {
                        if (name === 'tasks' || name === 'menus') {
                            await this._batchInsert(
                                `${this.configDBPrefix}_${name}`,
                                items
                                    .map((item) => ({
                                        id: item.id,
                                        config: JSON.stringify(item),
                                    })),
                            );
                        } else if (name === 'menu_items') {
                            await this._batchInsert(
                                `${this.configDBPrefix}_${name}`,
                                items
                                    .map((item) => ({
                                        id: item.id,
                                        menu_id: item.menu_id,
                                        sequence_number: item.sequence_number,
                                        config: JSON.stringify(item),
                                    })),
                            );
                        } else if (name === 'errors') {
                            for (const error of items) {
                                console.warn('Offline download error', error);
                            }
                        } else {
                            if (name === 'data_definitions') {
                                dataDefinitions = items;
                            }
                            await this._batchInsert(
                                `${this.configDBPrefix}_${name}`,
                                items
                                    .map((item) => ({
                                        uid: item.uid,
                                        config: JSON.stringify(item),
                                    })),
                            );
                        }
                    }),
            );
            onProgressUpdate('config', configProgress = 1);

            // START DATA
            onProgressUpdate('data', dataProgress += .01);

            for (const dataDefinition of dataDefinitions) {
                let data = await f2Api.readData(dataDefinition.uid);
                if (
                    Array.isArray(data) &&
                    isObject(dataDefinition.fields) &&
                    !isEmpty(dataDefinition.fields)
                ) {
                    if (data.length && Array.isArray(data[0])) {
                        data = data[0];
                    }
                    await this._initDataDB(dataDefinition);
                    await this._insertData(dataDefinition.uid, data, undefined, dataDefinition);
                } else if (typeof data === 'string') {
                    await FS.writeFile({
                        path: `${this.baseName}/${dataDefinition.uid}`,
                        data: dataDefinition.contentType === 'text/html' ? await inlineHTML(data) : data,
                        directory: Directory.Data,
                        encoding: Encoding.UTF8,
                        recursive: true,
                    });
                } else {
                    console.warn(`Unexpected definition or result for DD ${dataDefinition.uid}`, data);
                }
                onProgressUpdate('data', dataProgress += (.99 / dataDefinitions.length));
            }

            onProgressUpdate('data', dataProgress = 1);

            // START FILES
            onProgressUpdate('files', filesProgress = .01);
            const files = await f2Api.getFiles();
            onProgressUpdate('files', filesProgress += .05);

            if (files.length) {
                const alert = await alertController.create({
                    backdropDismiss: false,
                    header: getFilesDownloadLabel(files),
                    inputs: files.map((file) => ({
                        label: file.name,
                        type: 'checkbox',
                        value: file.uid,
                        checked: true,
                    })),
                    buttons: [{
                        text: localization.global.t('action.no'),
                        role: 'no',
                    }, {
                        text: localization.global.t('action.yes'),
                        role: 'yes',
                    }],
                });
                const buttonElements = alert.querySelectorAll('.alert-checkbox-group button');
                buttonElements
                    .forEach((el) => {
                        el.addEventListener('click', () => {
                            // wait for html update of ionic
                            setTimeout(() => {
                                const filteredFiles = files.filter((file, i) => buttonElements[i].getAttribute('aria-checked') === 'true');
                                alert.getElementsByClassName('alert-title')[0].innerHTML = getFilesDownloadLabel(filteredFiles);
                            });
                        });
                    });
                await alert.present();

                const {role, data} = await alert.onDidDismiss();
                const saveFilesLocally = role === 'yes';
                const totalFilesSize = saveFilesLocally && getTotalFilesSize(files.filter((file) => data.values.includes(file.uid))) || 0;
                let singleFileProgress = 0;

                for (const file of files) {
                    const isImage = file.mime_type && file.mime_type.startsWith('image/');
                    const isVideo = file.mime_type && file.mime_type.startsWith('video/');
                    let hasThumbnail = false;
                    let isStored = false;
                    singleFileProgress = saveFilesLocally ? .94 / files.length : 0;

                    if (saveFilesLocally && data.values.includes(file.uid)) {
                        singleFileProgress = .94 / totalFilesSize * file.size_in_bytes;
                        try {
                            // max dimension for images
                            const fileArray = await f2Api.callApi({
                                url: `/api/files/${file.uid}` + (isImage ? `/${this.maxSizeModifier}` : ''),
                                responseType: 'arraybuffer',
                                onDownloadProgress: ({progress}) => {
                                    if (progress) {
                                        onProgressUpdate('files', singleFileProgress * progress + filesProgress);
                                    }
                                },
                            });
                            await FS.writeFile({
                                path: `${this.baseName}/${file.uid}`,
                                data: btoa(new Uint8Array(fileArray).reduce((d, byte) => d + String.fromCharCode(byte), '')),
                                directory: Directory.Data,
                                recursive: true,
                            });
                            isStored = true;

                            // xs preview for images & videos
                            if (isImage || isVideo) {
                                await f2Api.callApi({
                                    url: `/api/files/${file.uid}/xs`,
                                    responseType: 'arraybuffer',
                                })
                                    .then((fileArray) => FS.writeFile({
                                        path: `${this.baseName}/${file.uid}-xs`,
                                        data: btoa(new Uint8Array(fileArray).reduce((d, byte) => d + String.fromCharCode(byte), '')),
                                        directory: Directory.Data,
                                        recursive: true,
                                    }))
                                    .then(() => {
                                        hasThumbnail = true;
                                    }, () => {
                                        return hasThumbnail = false;
                                    });
                            }
                        } catch (e) {
                            console.warn('Error during file download', e);
                        }
                    }
                    await this._insertFile(file, isStored, hasThumbnail);
                    onProgressUpdate('files', filesProgress += singleFileProgress);
                }
            }

            onProgressUpdate('files', filesProgress = 1);

            this.syncedOn = new Date();
            await setSetting(this.settingsName, this.syncedOn.toISOString());
            await deleteSetting(startedSettinsName);
        } catch (e) {
            await this.clear();
            await deleteSetting(startedSettinsName);
            throw e;
        }
    }

    async clear(clearFiles: boolean = true) {
        await this.syncRequests()
            .catch(() => null);
        this.syncedOn = undefined;
        await deleteSetting(this.settingsName);
        await Promise.allSettled([
            db('sqlite_master')
                .select('name')
                .where('type', 'table')
                .where('name', 'like', `${this.dataDBPrefix}_%`)
                .then((tables) => {
                    return Promise.all(
                        tables
                            .map((table) => db.schema.dropTable(table.name)),
                    );
                }),
            await Promise.all(
                [
                    `${this.configDBPrefix}_menus`,
                    `${this.configDBPrefix}_menu_items`,
                    `${this.configDBPrefix}_pages`,
                    `${this.configDBPrefix}_components`,
                    `${this.configDBPrefix}_data_definitions`,
                    `${this.configDBPrefix}_tasks`,
                    `${this.configDBPrefix}_files`,
                    `${this.configDBPrefix}_requests`,
                ]
                    .map((tableName) => db.schema.dropTableIfExists(tableName)),
            ),
            clearFiles ? FS.rmdir({
                path: this.baseName,
                directory: Directory.Data,
            }) : undefined,
        ]);
    }

    async syncRequests() {
        if (store.getters['app/isOffline'] || this._isSyncingRequests || this.isWeb || !this.isActive) {
            return;
        }
        this._isSyncingRequests = true;
        const requests = await db(`${this.configDBPrefix}_requests`)
            .select('id', 'config')
            .orderBy('created_at');
        if (!requests.length) {
            this._isSyncingRequests = false;
            return;
        }
        const alert = await alertController.create({
            backdropDismiss: false,
            header: localization.global.t('confirm.syncOfflineData', {amount: requests.length}),
            buttons: [{
                text: localization.global.t('action.no'),
                role: 'no',
            }, {
                text: localization.global.t('label.dropModifications'),
                role: 'never',
            }, {
                text: localization.global.t('action.yes'),
                role: 'yes',
            }],
        });
        await alert.present();
        const {role} = await alert.onDidDismiss();
        if (role !== 'yes') {
            if (role === 'never') {
                for (const {config} of requests) {
                    if (config.files) {
                        await Promise.allSettled(
                            Object.keys(config.files)
                                .map(async (uid: string) => {
                                    await Promise.allSettled([
                                        FS.deleteFile({
                                            path: `${this.baseName}/${uid}`,
                                            directory: Directory.Data,
                                        }),
                                        db(`${this.configDBPrefix}_files`)
                                            .delete()
                                            .where('uid', uid),
                                    ]);
                                }),
                        );
                    }
                }
                await db(`${this.configDBPrefix}_requests`)
                    .delete();
            }
            this._isSyncingRequests = false;
            return;
        }

        const passedRequestIds = [];
        for (const {id, config} of requests) {
            try {
                if (config.files) {
                    const files: File[] = [];
                    const uids: {[fileName: string]: string} = {};
                    await Promise.all(
                        Object.entries(config.files)
                            .map(async ([uid, fileName]) => {
                                const blob = await this._getFile(`${this.baseName}/${uid}`, 'blob');
                                files.push(new File([blob], fileName as string));
                                uids[fileName as string] = uid;
                            }),
                    );
                    await api.get()?.sendFile(files, undefined, undefined, uids);
                } else {
                    await api.get()?.callApi(config);
                }
                passedRequestIds.push(id);
            } catch (e) {
                console.warn('Error in request while syncRequests', e);
            }
        }
        await db(`${this.configDBPrefix}_requests`)
            .where('id', 'in', passedRequestIds)
            .delete();
        this._isSyncingRequests = false;
        const message = localization.global.t('info.syncOfflineFinished', {
            toSync: requests.length,
            synced: passedRequestIds.length,
        });
        if (requests.length < passedRequestIds.length) {
            showWarningToast(message);
        } else {
            showSuccessToast(message);
        }
    }

    async _initConfigDB() {
        await Promise.all([
            db.schema.hasTable(`${this.configDBPrefix}_menus`)
                .then((extsts) => {
                    if (!extsts) {
                        return db.schema.createTable(`${this.configDBPrefix}_menus`, (table) => {
                            this._createConfigTableSchema(table, true);
                        });
                    }
                }),
            db.schema.hasTable(`${this.configDBPrefix}_menu_items`)
                .then((extsts) => {
                    if (!extsts) {
                        return db.schema.createTable(`${this.configDBPrefix}_menu_items`, (table) => {
                            this._createConfigTableSchema(table, true);
                            table
                                .integer('menu_id')
                                .notNullable()
                                .unsigned();
                            table
                                .integer('sequence_number')
                                .unsigned();
                        });
                    }
                }),
            db.schema.hasTable(`${this.configDBPrefix}_pages`)
                .then((extsts) => {
                    if (!extsts) {
                        return db.schema.createTable(`${this.configDBPrefix}_pages`, (table) => {
                            this._createConfigTableSchema(table);
                        });
                    }
                }),
            db.schema.hasTable(`${this.configDBPrefix}_components`)
                .then((extsts) => {
                    if (!extsts) {
                        return db.schema.createTable(`${this.configDBPrefix}_components`, (table) => {
                            this._createConfigTableSchema(table);
                        });
                    }
                }),
            db.schema.hasTable(`${this.configDBPrefix}_data_definitions`)
                .then((extsts) => {
                    if (!extsts) {
                        return db.schema.createTable(`${this.configDBPrefix}_data_definitions`, (table) => {
                            this._createConfigTableSchema(table);
                        });
                    }
                }),
            db.schema.hasTable(`${this.configDBPrefix}_tasks`)
                .then((extsts) => {
                    if (!extsts) {
                        return db.schema.createTable(`${this.configDBPrefix}_tasks`, (table) => {
                            this._createConfigTableSchema(table, true);
                        });
                    }
                }),
            db.schema.hasTable(`${this.configDBPrefix}_files`)
                .then((extsts) => {
                    if (!extsts) {
                        return db.schema.createTable(`${this.configDBPrefix}_files`, (table) => {
                            this._createConfigTableSchema(table);
                            table
                                .string('mime_type', 50);
                            table
                                .boolean('is_stored')
                                .defaultTo(false);
                            table
                                .boolean('is_thumbnail_stored')
                                .defaultTo(false);
                        });
                    }
                }),
            db.schema.hasTable(`${this.configDBPrefix}_requests`)
                .then((extsts) => {
                    if (!extsts) {
                        return db.schema.createTable(`${this.configDBPrefix}_requests`, (table) => {
                            table
                                .increments();
                            table
                                .text('config')
                                .notNullable();
                            table
                                .timestamps(undefined, true);
                        });
                    }
                }),
        ]);
    }

    _createConfigTableSchema(table: Knex.CreateTableBuilder, hasID: boolean = false) {
        if (hasID) {
            table
                .integer('id')
                .unsigned()
                .notNullable()
                .primary();
        } else {
            table
                .string('uid', 27)
                .notNullable()
                .primary();
        }
        table
            .text('config')
            .notNullable();
        table
            .timestamps(undefined, true);
    }

    async _initDataDB(dataDefinition: F2DataSourceConfig) {
        const tableName = this._getDDTableName(dataDefinition.uid);
        await db.schema.dropTableIfExists(tableName);
        await db.schema.createTable(tableName, (table) => {
            table.increments(PKColumnName);

            for (const [name, field] of Object.entries(dataDefinition.fields as DataSourceFields)) {
                switch (field.type) {
                case 'integer':
                    table.integer(name);
                    break;
                case 'float':
                    table.decimal(name);
                    break;
                case 'boolean':
                    table.boolean(name);
                    break;
                case 'datetime':
                    table.datetime(name);
                    break;
                default:
                    table.text(name);
                }
            }
        });
    }

    async _readData(uid: string, params: {q: string}, httpConfig?: RawAxiosRequestConfig, dataDefinition?: F2DataSourceConfig) {
        const info = await this._getDataInfo(uid, undefined, dataDefinition, 'read', !!httpConfig);
        const dbInstructions = params && params.q ? JSON.parse(atob(params.q)) : {} as ServerDBInstructions;
        dbInstructions.table = info.tableName;
        return buildQuery(db, dbInstructions)
            .then(this._response);
    }

    async _insertData(uid: string, data: any[], httpConfig?: RawAxiosRequestConfig, dataDefinition?: F2DataSourceConfig) {
        const config = httpConfig ? JSON.stringify(httpConfig) : null;
        const info = await this._getDataInfo(uid, data, dataDefinition, 'create', !!httpConfig);
        if (httpConfig) {
            await db(`${this.configDBPrefix}_requests`)
                .insert({config});
        }

        await this._batchInsert(info.tableName, info.data as any[]);
        return this._response();
    }

    async _updateData(uid: string, data: any[], httpConfig?: RawAxiosRequestConfig, dataDefinition?: F2DataSourceConfig) {
        const config = httpConfig ? JSON.stringify(httpConfig) : null;
        const info = await this._getDataInfo(uid, data, dataDefinition, 'update', !!httpConfig);
        if (config) {
            await db(`${this.configDBPrefix}_requests`)
                .insert({config});
        }
        for (const row of info.data as any[]) {
            let query = db(info.tableName);

            if (row[PKColumnName]) {
                query = query.where(PKColumnName, row[PKColumnName]);
                delete row[PKColumnName];
            } else {
                for (const primaryKey of info.dataDefinition.primaryKey as string[]) {
                    query = query.where(primaryKey, row[primaryKey]);
                    delete row[primaryKey];
                }
            }
            await query
                .update(row);
        }
        return this._response();
    }

    async _deleteData(uid: string, data: any[], httpConfig?: RawAxiosRequestConfig, dataDefinition?: F2DataSourceConfig) {
        const config = httpConfig ? JSON.stringify(httpConfig) : null;
        const info = await this._getDataInfo(uid, data, dataDefinition, 'delete', !!httpConfig);
        if (config) {
            await db(`${this.configDBPrefix}_requests`)
                .insert({config});
        }
        for (const row of info.data as any[]) {
            let query = db(info.tableName)
                .delete();

            if (row[PKColumnName]) {
                query = query.where(PKColumnName, row[PKColumnName]);
            } else {
                for (const primaryKey of info.dataDefinition.primaryKey as string[]) {
                    query = query.where(primaryKey, row[primaryKey]);
                }
            }
            await query;
        }
        return this._response();
    }

    async _getDataInfo(uid: string, data: any[] | undefined, dataDefinition: F2DataSourceConfig | undefined, action: F2DataSourceAction, checkRights: boolean) {
        if (!dataDefinition) {
            dataDefinition = (await db(`${this.configDBPrefix}_data_definitions`)
                .first('config')
                .where('uid', uid)
                .then((ddRow) => ddRow ? ddRow.config : ddRow)) as F2DataSourceConfig;
        }

        if (checkRights && !dataDefinition.rights[action]) {
            throw new Error(localization.global.t('error.permissionDenied'));
        }

        if (data) {
            for (const row of data) {
                if (dataDefinition && dataDefinition.fields) {
                    for (const field of Object.keys(row)) {
                        if (!(field in dataDefinition.fields)) {
                            delete row[field];
                        } else if (row[field] && !(row[field] instanceof Date) && (Array.isArray(row[field]) || typeof row[field] === 'object')) {
                            row[field] = JSON.stringify(row[field]);
                        }
                    }
                }
            }
        }

        return {
            data,
            dataDefinition,
            tableName: this._getDDTableName(uid),
        };
    }

    _getDDTableName(uid: string): string {
        return `${this.dataDBPrefix}_${uid.replace(/-/g, '_')}`;
    }

    _selectSingleConfig(tablename: string, where?: any) {
        let query = db(tablename)
            .first('config');
        if (where) {
            query = query.where(where);
        }
        return query
            .then((item) => item? item.config : item)
            .then(this._response);
    }

    _selectMultiple(dbInstructions: ServerDBInstructions) {
        dbInstructions.select = ['config'];
        return buildQuery(db, dbInstructions)
            .then((items) => items.map((item: any) => item.config))
            .then(this._response);
    }

    async _insertFile(file: any, isStored: boolean = false, hasThumbnail: boolean = false) {
        await db(`${this.configDBPrefix}_files`)
            .insert({
                uid: file.uid,
                config: JSON.stringify(file),
                mime_type: file.mime_type,
                is_stored: isStored,
                is_thumbnail_stored: hasThumbnail,
            });
    }

    _response(data?: any) {
        return {
            status: 200,
            data,
        };
    }

    async _getFile<T extends FileResltTypeName>(path: string, type: T, mimeType: string = ''): Promise<FileResltType<T>> {
        const {data} = await FS.readFile({
            path,
            directory: Directory.Data,
        });
        if (type === 'base64') {
            return `data:${mimeType.toLowerCase()};base64,${data}` as FileResltType<T>;
        }
        const binaryString = atob(data as string);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        if (type === 'arraybuffer') {
            return bytes as FileResltType<T>;
        } else {
            return new Blob([bytes]) as FileResltType<T>;
        }
    }

    async _batchInsert(tableName: string, rows: any[], chunkSize = 250) {
        const chunks = [...Array(Math.ceil(rows.length / chunkSize))]
            .map((_, i) => rows.slice(chunkSize * i, chunkSize + chunkSize * i));
        for (const rows of chunks) {
            await db(tableName)
                .insert(rows);
        }
    }
}

function getTotalFilesSize(files: FileInfo[]): number {
    return files.reduce((size, file) => size += file.size_in_bytes, 0);
}

function getFilesDownloadLabel(files: FileInfo[]) {
    const totalFilesSize = getTotalFilesSize(files);
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const unitIndex: number = Math.floor(Math.log(totalFilesSize) / Math.log(1024));
    const size = totalFilesSize / Math.pow(1024, unitIndex);
    return localization.global.t('confirm.saveFilesLocally', {
        amount: files.length,
        size: new Intl.NumberFormat(localization.global.locale.value, {
            maximumFractionDigits: size < 10 ? 2 : 1,
        }).format(size) + ' ' + units[unitIndex],
    });
}

// function escapeHtml(string: string) {
//     return string.replace(/(&|<|>)/g, '\\$1');
// }

export async function inlineHTML(htmlString: string) {
    const html = new DOMParser().parseFromString(htmlString, 'text/html');
    await Promise.all([
        Promise.all(
            Array.from(html.querySelectorAll('script[src]:not([src=""])'))
                .map(async (element) => {
                    try {
                        const base64 = await getBase64(element.getAttribute('src') as string);
                        if (base64) {
                            element.setAttribute('src', base64.replace('application/javascript', 'text/javascript'));
                        }
                    } catch (e) {
                        console.error('Error during script inlining', e);
                    }
                }),
        ),
        Promise.all(
            Array.from(html.querySelectorAll('img[src]:not([src=""])'))
                .map(async (element) => {
                    try {
                        const base64 = await getBase64(element.getAttribute('src') as string);
                        element.setAttribute('src', base64);
                    } catch (e) {
                        console.error('Error during image inlining', e);
                    }
                }),
        ),
        Promise.all(
            Array.from(html.querySelectorAll('link[rel="stylesheet"][href]:not([href=""]'))
                .map(async (element) => {
                    try {
                        const href = element.getAttribute('href') as string;
                        let style = await getContent(href);
                        const domainMatch = href.match(/(^https?:\/\/[^/]+)?\/(.+)$/);
                        const regex = /url\((?!['"]?(?:data):)['"]?([^'")]*)['"]?\)/gmi;
                        let match;
                        const urls: any = {};

                        while ((match = regex.exec(style)) !== null) {
                            if (match.index === regex.lastIndex) {
                                regex.lastIndex++;
                            }
                            if (!urls[match[1]]) {
                                // relative resources inside css like: url(../file.ext)
                                if (domainMatch && !match[1].startsWith('http')) {
                                    match[1] = match[1].replace(/^(\.\.\/)+/, (prefix) => {
                                        const backsteps = prefix
                                            .split('/')
                                            .length;
                                        const pathsegments = domainMatch[2]
                                            .split('/');
                                        return '/' + pathsegments
                                            .splice(0, pathsegments.length - backsteps)
                                            .join('/') + '/';
                                    });
                                    if (domainMatch[1]) {
                                        match[1] = domainMatch[1] + match[1];
                                    }
                                }
                                urls[match[1]] = {
                                    request: getBase64(match[1]),
                                    fullMatch: match[0],
                                };
                            }
                        }

                        // keep only woff2 format of fonts
                        const allUrls = Object.keys(urls);
                        const woff2Urls = allUrls
                            .filter((url) => url.endsWith('.woff2'))
                            .map((url) => url.replace(/woff2/i, ''));
                        allUrls
                            .forEach((url: string) => {
                                if (!url.endsWith('woff2') && woff2Urls.find((woff2Url) => url.startsWith(woff2Url))) {
                                    delete urls[url];
                                }
                            });

                        try {
                            await Promise.all(
                                Object.values(urls)
                                    .map((info: any) => info.request),
                            )
                                .then((base64s) => {
                                    Object.keys(urls)
                                        .forEach((url, i) => {
                                            if (base64s[i]) {
                                                style = style.replace(
                                                    new RegExp(stringRegexpEscape(urls[url].fullMatch), 'gmi')
                                                    , `url("${base64s[i]}")`,
                                                );
                                            }
                                        });
                                });
                        } catch (e) {
                            console.warn('Error during style url inlining', e);
                        }
                        if (style) {
                            element.setAttribute('href', `data:text/css;base64,${btoa(style)}`);
                        }
                    } catch (e) {
                        console.error('Error during style inlining', e);
                    }
                }),
        ),
    ]);
    htmlString = new XMLSerializer().serializeToString(html);
    return htmlString;
}

function getContent(url: string) {
    return api.get()?.callApi({url});
}

async function getBase64(url: string) {
    if (/^data:/.test(url)) {
        return url;
    }
    return api.get()?.callApi({
        url,
        responseType: 'arraybuffer',
        transformResponse: (data, headers) => {
            const image = global.btoa(
                new Uint8Array(data)
                    .reduce((d, byte) => d + String.fromCharCode(byte), ''),
            );
            return `data:${headers['content-type'].split(';')[0].toLowerCase()};base64,${image}`;
        },
    });
}
