
import {isPlatform, createAnimation} from '@ionic/vue';
import {geoLocation} from './location';

const UID_ALPHABET = 'abcdefghijklmonpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

export interface Condition {
	conditions?: Array<Condition>;
	logic: 'or'|'and';
	operator: '!='|'contains'|'startswith'|'endswith'|'doesnotcontain'|'<'|'<='|'>'|'>='|'isnull'|'isnotnull'|'isdefined'|'isnotdefined'|'=';
	baseValue: any;
	targetValue: any;
}

export function isConditionsMet(condition: Condition): boolean {
    if (Array.isArray(condition.conditions)) {
        if (condition.logic === 'or') {
            return !!condition.conditions
                .find((singleCondition: Condition) => isConditionsMet(singleCondition));
        } else {
            return !condition.conditions
                .find((singleCondition: Condition) => !isConditionsMet(singleCondition));
        }
    } else {
        switch (condition.operator) {
        case '!=':
            return condition.baseValue != condition.targetValue;
        case 'contains':
            return (condition.baseValue + '')
                .includes(condition.targetValue);
        case 'startswith':
            return (condition.baseValue + '')
                .startsWith(condition.targetValue);
        case 'endswith':
            return (condition.baseValue + '')
                .endsWith(condition.targetValue);
        case 'doesnotcontain':
            return !(condition.baseValue + '')
                .includes(condition.targetValue);
        case '<':
            return condition.baseValue < condition.targetValue;
        case '<=':
            return condition.baseValue <= condition.targetValue;
        case '>':
            return condition.baseValue > condition.targetValue;
        case '>=':
            return condition.baseValue >= condition.targetValue;
        case 'isnull':
            return condition.baseValue === null;
        case 'isnotnull':
            return condition.baseValue !== null;
        case 'isdefined':
            return condition.baseValue !== undefined;
        case 'isnotdefined':
            return condition.baseValue === undefined;
        default:
            return condition.baseValue == condition.targetValue;
        }
    }
}


export class TagsToResolve {
    private _location: any = null;
    self: any;

    constructor(tagsToResolve: Object) {
        Object.assign(this, tagsToResolve, {
            environment: 'mobile',
            isMobile: isPlatform('mobile'),
            isAndroid: isPlatform('android'),
            isIOS: isPlatform('ios'),
        });
    }

    get date(): Date {
        return new Date();
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    get location() {
        if (!this._location) {
            this._location = geoLocation();
        }
        return this._location?.position?.value?.coords || {};
    }
}

export function enterModalAnimation(baseEl: HTMLElement) {
    const root = baseEl.shadowRoot || new ShadowRoot();
    const backdropAnimation = createAnimation()
    // @ts-ignore-start
        .addElement(root.querySelector('ion-backdrop'))
    // @ts-ignore-end
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
    const wrapperAnimation = createAnimation()
    // @ts-ignore-start
        .addElement(root.querySelector('.modal-wrapper'))
    // @ts-ignore-end
        .keyframes([
            {offset: 0, opacity: '0', transform: 'scale(0)'},
            {offset: 1, opacity: '0.99', transform: 'scale(1)'},
        ]);

    return createAnimation()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(300)
        .addAnimation([backdropAnimation, wrapperAnimation]);
};

export function leaveModalAnimation(baseEl: HTMLElement) {
    return enterModalAnimation(baseEl)
        .direction('reverse');
};

export function getRandomString(length: number): string {
    return Array.from(
        crypto.getRandomValues(new Uint8ClampedArray(length)),
        (dec) => UID_ALPHABET[dec % UID_ALPHABET.length],
    )
        .join('');
}

export function getUid(length: number = 27) {
    let uid = Date.now() + '-' + getRandomString(length - 17);
    while (uid.length < length) {
        uid = '0' + uid;
    }
    return uid;
}

export function stringRegexpEscape(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
