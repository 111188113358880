<template>
    <div style="width: 100%; margin: 6px 0 0 0;">
        <div
            v-if="definition.type === 'file'"
            style="display: flex; flex-direction: row; align-items: center;"
        >
            <ion-button @click="$refs.input.click()">
                {{ label }}
                <input
                    ref="input"
                    type="file"
                    :accept="accept"
                    :multiple="isMulti"
                    :disabled="props.disabled || isUploading"
                    :required="isRequired"
                    v-bind="additionalInputAttributes"
                    @change="uploadFile"
                >
            </ion-button>
            <ion-button
                v-if="isImagePicker && platform !== 'web'"
                slot="end"
                @click="openCamera()"
            >
                <ion-icon
                    slot="icon-only"
                    :icon="cameraOutline"
                />
            </ion-button>
        </div>
        <template v-else>
            <ion-button @click="isSingatureModalOpen = true">
                {{ label }}
            </ion-button>
            <ion-modal
                :is-open="isSingatureModalOpen"
                :backdrop-dismiss="false"
                @didPresent="onSignatureModalReady"
            >
                <ion-header>
                    <ion-toolbar>
                        <ion-buttons slot="start">
                            <ion-button @click="confirmSignature">
                                {{ $t('action.ok') }}
                            </ion-button>
                        </ion-buttons>
                        <ion-title class="ion-text-center">
                            {{ $t('label.signature') }}
                        </ion-title>
                        <ion-buttons slot="end">
                            <ion-button @click="isSingatureModalOpen = false">
                                {{ $t('action.cancel') }}
                            </ion-button>
                        </ion-buttons>
                    </ion-toolbar>
                </ion-header>
                <ion-content class="ion-padding">
                    <canvas
                        v-if="isSingatureModalOpen"
                        ref="canvas"
                        width="0"
                        height="0"
                    />
                </ion-content>
            </ion-modal>
        </template>
        <ion-progress-bar
            :style="{visibility: !isUploading ? 'hidden' : 'visible'}"
            :value="uploadProgress"
        />
        <ion-list
            v-if="files.length"
            slot="end"
            lines="none"
            style="padding: 0"
        >
            <ion-item
                v-for="file of files"
                :key="file.uid"
                class="files"
            >
                <ion-thumbnail
                    v-if="file.mime_type.startsWith('image/') || file.mime_type.startsWith('video/') && !file.additional_info?.origin"
                    slot="start"
                    :router-link="`/files/${file.uid}`"
                >
                    <ion-img v-f2Src.xs="file.uid" />
                </ion-thumbnail>
                <ion-label> {{ file.name }} </ion-label>
                <ion-icon
                    slot="end"
                    :icon="close"
                    @click="removeFile(file)"
                />
            </ion-item>
        </ion-list>
    </div>
</template>
<script lang="ts">
import {Capacitor} from '@capacitor/core';
import {Component} from '@/framework2-sdk/f2Api';
import {defineComponent, ref} from 'vue';
import {
    IonList,
    IonItem,
    IonLabel,
    IonProgressBar,
    IonThumbnail,
    IonImg,
    IonIcon,
    IonButton,
    IonButtons,
    IonModal,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
} from '@ionic/vue';
import {close, cameraOutline, pencilOutline} from 'ionicons/icons';
import {Camera, CameraResultType} from '@capacitor/camera';

import {useStore} from 'vuex';
import {key} from '../store';
import {formField} from '@/functionality/form';
import * as f2Api from '../api';
import {showErrorToast} from '@/functionality/logging';
import {useI18n} from 'vue-i18n';

export default defineComponent({
    components: {
        IonList,
        IonItem,
        IonLabel,
        IonProgressBar,
        IonThumbnail,
        IonImg,
        IonIcon,
        IonButton,
        IonButtons,
        IonModal,
        IonContent,
        IonHeader,
        IonToolbar,
        IonTitle,
    },
    props: {
        definition: {
            required: true,
            type: Object as () => Component,
        },
        model: {
            required: true,
            type: Object,
        },
        disabled: Boolean,
        isRequired: {
            required: false,
            type: Boolean,
            default: false,
        },
        validationRules: {
            required: false,
            type: Object,
        },
        additionalInputAttributes: {
            required: false,
            type: Object,
        },
        label: {
            required: true,
            type: String,
        },
    },
    setup(props) {
        const store = useStore(key);
        const {t} = useI18n();
        const {formModel, fieldName, value} = formField({definition: props.definition.definition, model: props.model});
        const files = ref<any[]>([]);
        let existingFileUIDs: string[] = [];
        const isUploading = ref(false);
        const uploadProgress = ref(0);
        const isSingatureModalOpen = ref(false);
        const canvas = ref();
        const mimeTypes = props.validationRules?.isFileMimeType?.options?.mimeTypes;
        const isImagePicker = !!mimeTypes ? !!mimeTypes.find((mimeType: string) => mimeType.startsWith('image')) : true;
        const isMulti = !props.validationRules?.isArray?.options ||
                        !props.validationRules?.isArray?.options.max ||
                        props.validationRules?.isArray?.options.max > 1;

        const api = f2Api.get();
        if (!api) {
            console.error('api not defined in F2Upload component');
            throw Error('api not defined');
        }

        if (value) {
            if (!Array.isArray(value)) {
                existingFileUIDs = [value];
            } else {
                existingFileUIDs = value;
            }
        }

        (async function() {
            files.value = await fetchExistingFileInfo(existingFileUIDs);
        })();

        async function fetchExistingFileInfo(fileUIDs: string []) {
            const fileInfos = [];
            for (const uid of fileUIDs) {
                try {
                    const fileInfo = await api?.getFileInfo(uid);
                    if (fileInfo) {
                        fileInfo.uid = uid;
                        fileInfos.push(fileInfo);
                    }
                } catch (e) {
                    console.error('F2Upload error fetching file info', e);
                }
            }
            return fileInfos;
        }

        function removeFile(file: any) {
            const index = files.value.findIndex((f) => f.uid === file.uid);
            if (index !== -1) {
                files.value.splice(index, 1);
            }
            formModel[fieldName] = files.value.map((f) => f.uid);
        }

        async function uploadFile(event: any) {
            const selectedFiles = event.target.files;
            if (!selectedFiles) {
                return;
            }
            isUploading.value = true;
            try {
                const fileUIDs = await api?.sendFile(selectedFiles, (event) => {
                    uploadProgress.value = event.total / event.loaded;
                });
                // ANDROID: if this is done before "if (!selectedFiles) {" selectedFiles will become null on android and the upload will break - https://gitlab.ilosgroup.com/framework2/framework2-app-ionic/-/issues/21
                event.target.value = null;
                const newFileInfos = await fetchExistingFileInfo(fileUIDs as string[]);
                files.value.push(...newFileInfos);
                formModel[fieldName] = files.value.map((f) => f.uid);
            } catch (e: any) {
                console.error('error on file upload', e);
                showErrorToast(t('error.uploadingFile'));
            } finally	{
                isUploading.value = false;
            }
        }

        async function openCamera() {
            const image = await Camera.getPhoto({
                quality: 90,
                resultType: CameraResultType.DataUrl,
                promptLabelHeader: t('label.picture'),
                promptLabelPhoto: t('action.fromGallery'),
                promptLabelPicture: t('action.fromCamera'),
            });
            if (image.dataUrl) {
                uploadDataUrl(image.dataUrl, `picture.${image.format}`);
            }
        }

        async function uploadDataUrl(dataUrl: string, fileName: string) {
            try {
                isUploading.value = true;
                const blob = await fetch(dataUrl).then((res) => res.blob());
                const file = new File([blob], fileName);
                const fileUIDs = await api?.sendFile([file], (event) => {
                    uploadProgress.value = event.total / event.loaded;
                });
                const newFileInfos = await fetchExistingFileInfo(fileUIDs as string[]);
                files.value.push(...newFileInfos);
                formModel[fieldName] = files.value.map((f) => f.uid);
            } catch (e: any) {
                console.error('error on file upload', e);
                showErrorToast(t('error.uploadingFile'));
            } finally	{
                isUploading.value = false;
            }
        }

        let signaturePad: any;
        async function onSignatureModalReady() {
            canvas.value.width = canvas.value.parentElement.offsetWidth;
            canvas.value.height = canvas.value.width / 2;
            const {default: SignaturePad} = await import('signature_pad');
            signaturePad = new SignaturePad(canvas.value);
        }

        function confirmSignature() {
            const dataUrl = signaturePad?.toDataURL();
            if (dataUrl) {
                uploadDataUrl(dataUrl, 'signature.png');
            }
            isSingatureModalOpen.value = false;
        }

        return {
            isUploading,
            uploadProgress,
            removeFile,
            files,
            formModel,
            fieldName,
            uploadFile,
            openCamera,
            component: props.definition,
            props,
            platform: Capacitor.getPlatform(),
            baseUrl: store.state.user.credentials.baseURL,
            close,
            cameraOutline,
            pencilOutline,
            store,
            isImagePicker,
            isMulti,
            accept: mimeTypes?.map((mimeType: string) => mimeType.includes('/') ? mimeType : `${mimeType}/*`).join(', '),
            isSingatureModalOpen,
            canvas,
            confirmSignature,
            onSignatureModalReady,
        };
    },
});
</script>

<style scoped lang="scss">
ion-thumbnail {
	width: 42px;
    height: 42px;
}
canvas {
    cursor: crosshair;
    background-color: #fff;

    &:not([width="0"]) {
        border: 2px dotted #CCCCCC;
    }
}

input[type="file"] {
    display: none;
}
</style>
