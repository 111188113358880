<template>
    <ion-progress-bar
        v-if="isLoadingDefinitions"
        type="indeterminate"
    />
    <f2-tabstrip-content
        v-else-if="resolvedComponents?.length"
        :data="props.data"
        :model="props.model"
        :data-source="props.dataSource"
        :definition="resolvedComponents"
    />
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {
    IonProgressBar,
} from '@ionic/vue';
import {Component} from '@/framework2-sdk/f2Api';
import {loadDefinitions} from '@/functionality/componentDefinitions';
import F2TabstripContent from './F2TabstripContent.vue';

export default defineComponent({
    components: {
        IonProgressBar,
        F2TabstripContent,
    },
    props: {
        definition: {
            required: true,
            type: Object as () => Component,
        },
        data: {
            required: false,
            type: Object,
        },
        model: {
            required: false,
            type: Object,
        },
        dataSource: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        if (!props.definition.subcomponents) {
            return {
                isLoadingDefinitions: false,
                resolvedComponents: [],
                props,
            };
        }

        const subcomponents = props.definition.subcomponents;

        const {isLoadingDefinitions, resolvedComponents} = loadDefinitions(subcomponents);

        return {
            isLoadingDefinitions,
            resolvedComponents,
            props,
        };
    },
});
</script>
