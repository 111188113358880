const { each, forOwn } = require('lodash');
const _validator = require('validator');

const regExpIsUIDString = '[0-9]{16}-[A-z0-9]{10}';
const regExpIsUID = new RegExp(`^${regExpIsUIDString}$`);

const validators = {
    regExpIsUIDString,
    isArray: function (value, options) {
        if (typeof value === 'string') {
            value = value.split(',');
        }
        let isValidArray = Array.isArray(value);
        if (isValidArray && options) {
            if (options.type) {
                each(value, value => {
                    return isValidArray = validators.isType(value, options);
                });
            }
            else if (isValidArray && options.max) {
                isValidArray = value.length <= options.max;
            }
            else if (isValidArray && options.min) {
                isValidArray = value.length >= options.min;
            }
        }
        return isValidArray;
    },
    translationData: () => true, // rule in order to accept translation object that is used to replace placeholders in localization messages
    parser: () => true, // rule in order to accept parser method in order to parse certain properties if needed
    isAlphanumericWithUnderscores: function (value) {
        if (typeof value === 'string')
            return value.match(/^[A-z0-9_]+$/) !== null;
        return false;
    },
    isType: function (value, options) {
        if (options.type === 'uid') {
            return validators.isUid(value);
        }
        return typeof value === options.type;
    },
    isUid: function (value, options) {
        let length = options ? options.length : 27;
        if (length < 27)
            throw new Error('an uid needs to have a min length of 27');
        return validators.isType(value, { type: 'string' })
            && value.length === length
            && regExpIsUID.test(value);
    },
    isFileMimeType: () => true, // TODO: check file mime type
    notEmpty: (value) => {
        if (value === undefined || value === null) {
            return false;
        }
        else if (Array.isArray(value)) {
            return value.length !== 0;
        }
        else {
            const type = typeof value;
            if (type === 'object') {
                for (const key in value) {
                    if (value.hasOwnProperty(key)) {
                        return true;
                    }
                }
                return false;
            }
        }
        return value !== '';
    },
    notNull: (value) => {
        if (value === undefined || value === null) {
            return false;
        }
        return true;
    }
};

const validator = Object.assign(_validator, validators);

function validate (value, rules, options = {}) {
    let errors = [];
    forOwn(rules, (config, rule) => {
        if (!(rule in validator))
            errors.push({ rule, config: Object.assign(config, { errorMessage: 'rule not found' }), ruleNotFound: true });
        else if (!validateRule(value, rule, config)) {
            errors.push({ rule, config });
        }
        if (options.stopAtFirstError && errors.length)
            return false;
    });
    return errors;
}

function validateRule (value, rule, config) {
    if (Array.isArray(config.options))
        return validator[rule](value + '', ...config.options);
    else
        return validator[rule](value + '', config.options);
}

module.exports = {
    validators,
    validator,
    validate,
    validateRule
};