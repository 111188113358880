import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f2_component = _resolveComponent("f2-component")!

  return (_openBlock(), _createElementBlock("fieldset", null, [
    (_ctx.component?.definition?.label?.text_overwrite || _ctx.component?.definition?.label?.text)
      ? (_openBlock(), _createElementBlock("legend", _hoisted_1, _toDisplayString(_ctx.component?.definition?.label?.text_overwrite || _ctx.component?.definition?.label?.text), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_f2_component, {
      definition: _ctx.component.definition.subcomponents,
      data: _ctx.data,
      model: _ctx.model,
      "data-source": _ctx.dataSource,
      "data-definition": _ctx.dataDefinition
    }, null, 8, ["definition", "data", "model", "data-source", "data-definition"])
  ]))
}