import {Capacitor} from '@capacitor/core';
import {toastController} from '@ionic/vue';
import {PredefinedColors} from '@ionic/core';
import {
    informationCircleOutline,
    checkmarkCircleOutline,
    alertCircleOutline,
    closeCircleOutline,
} from 'ionicons/icons';

const DEFAULT_TOAST_APPEARANCE_DURATION_MS = 5000;

export function showErrorToast(message: string, header?: string, duration?: number) {
    return showToast(message, 'danger', header, closeCircleOutline, duration);
}

export function showWarningToast(message: string, header?: string, duration?: number) {
    return showToast(message, 'warning', header, alertCircleOutline, duration);
}

export function showSuccessToast(message: string, header?: string, duration?: number) {
    return showToast(message, 'success', header, checkmarkCircleOutline, duration);
}

export function showInfoToast(message: string, header?: string, duration?: number) {
    return showToast(message, 'light', header, informationCircleOutline, duration);
}

export async function showToast(message: string, color: PredefinedColors, header?: string, icon?: string, duration: number = DEFAULT_TOAST_APPEARANCE_DURATION_MS) {
    try {
        const toast = await toastController
            .create({
                icon,
                message,
                header,
                duration,
                position: Capacitor.getPlatform() !== 'web' ? 'top' : 'bottom',
                color,
            });
        return toast.present();
    } catch (e) {
        console.error(`error showing ${color} toast`, e);
    }
}
