import {isEmpty} from 'lodash';
import dataSourceRegistry from '@/dataSourceRegistry';
import DataSource, {DataSourceConfig, DataSourceFields} from '@/framework2-sdk/dataSource';
import F2DataSource from '@/framework2-sdk/f2DataSource';
import DataItem from '@/framework2-sdk/dataItem';
import {clone} from '@/framework2-sdk/tools/object';
import {GeoLocationInfo, geoLocationStatus} from '@/location';
import {store} from '@/store';
import {onUnmounted, Ref, ref} from 'vue';
import {emit} from './events';
import * as api from '@/api';
import localization from '@/localization';
import F2Api from '@/framework2-sdk/f2Api';
import {replaceTags} from '@/framework2-sdk/tools/string';
import {validator} from '@/framework2-sdk/tools/validatorWrapper';

export interface DataSourceInfo {
    isLoading: Ref<boolean>
    data: Ref<any[] | undefined>
    isDependenciesMet: Ref<boolean>
    error: Ref<Error | undefined>
    dataSource: DataSource | undefined
    geoLocation: GeoLocationInfo | undefined
    isGeoLocationNeeded: Ref<boolean>
    isDataReady: Ref<boolean>
	count: Ref<number>
	dataDefinition: Ref<DataSourceConfig | undefined>
	selectedItems: Ref<Array<DataItem>>,
    validationResult: Ref<ValidationResult>,
    toggleSelectItem: Function,
}

export interface Source {
	uid: string,
}

export interface OnDataDefinitionFetched {
	(fetchedDataDefinition: any): Promise<any>
}

export const componentRegistry: {[componentUid: string]: F2DataSource} = {};

export function dataSource(definition: any, onDataDefinitionFetched?: OnDataDefinitionFetched, isInfiniteScroll: boolean = false): DataSourceInfo {
    const isLoading = ref(false);
    const isDependenciesMet = ref(true);
    const data = ref<Array<any> | undefined>(undefined);
    const error = ref<Error | undefined>(undefined);
    const isGeoLocationNeeded = ref<boolean>(false);
    const isDataReady = ref<boolean>(false);
    const count = ref(0);
    const dataDefinition = ref<DataSourceConfig | undefined>(undefined);
    const selectedItems: Ref<Array<DataItem>> = ref([]);
    const validationResult = ref<any>();

    function toggleSelectItem(item: DataItem) {
        const isMultiSelect = definition?.actions && definition?.actions.length || definition?.data?.source?.rights?.delete !== false;
        if (
            isMultiSelect ||
            definition?.data?.source?.rights?.update !== false &&
            (definition?.look?.detail?.grid.length || definition?.look?.detail?.subcomponents.length)
        ) {
            const i = selectedItems.value.findIndex((_item) => _item.__id === item.__id);
            if (i === -1) {
                if (!isMultiSelect) {
                    while (selectedItems.value.length) {
                        selectedItems.value.pop();
                    }
                }
                selectedItems.value.push(item);
            } else {
                selectedItems.value.splice(i, 1);
            }
        }
    }

    if (!definition?.data?.source || definition.data.source instanceof F2DataSource) {
        isLoading.value = false;
        isDataReady.value = true;
        const ds = definition?.data?.source;
        if (ds) {
            isDataReady.value = true;
            data.value = ds.data;
            ds.on('validated', (event: any) => {
                validationResult.value = event.data;
            });
            (async function() {
                try {
                    dataDefinition.value = await ds.getDefinition();
                } catch (e: any) {
                    console.error('dataSource.ts - error while obtaining dataSource config', e);
                    error.value = e;
                }
            })();
        }
        return {
            isLoading,
            data,
            isDependenciesMet,
            error,
            dataSource: ds,
            geoLocation: undefined,
            isGeoLocationNeeded,
            isDataReady,
            count,
            dataDefinition,
            selectedItems,
            validationResult,
            toggleSelectItem,
        };
    }

    const location = geoLocationStatus();

    const tagsToResolve = {
        storage: store.state.user.storage,
    };

    let ds: F2DataSource | undefined = definition.dataSource = new F2DataSource(
        {
            tagsToResolve,
            onDataDefinitionFetched,
            ...definition.data.source,
            componentUID: definition.uid,
        },
        dataSourceRegistry,
    );

    if (ds) {
        componentRegistry[definition.uid] = ds;
    }

    ds.getDefinition()
        .then(
            (config) => {
                isGeoLocationNeeded.value = config.needsGeoLocation || false;
                dataDefinition.value = config;
                definition.data.source = config;
            },
            (error) => {
                console.error('dataSource.ts - error while obtaining dataSource config', error);
                error.value = error;
            },
        );

    ds.on('changed', (event) => {
        if (event.targetOfType === 'data') {
            // do not concat records if offset is 0 -> refreshed or filters/order changed
            if (isInfiniteScroll && ds && ds.offset() > 0) {
                data.value = (data.value || []).concat(clone(event.data));
            } else {
                data.value = clone(event.data) as Array<object>;
            }
            count.value = ds?.count || 0;
            isLoading.value = false;
            isDataReady.value = true;
            selectedItems.value = [];
        } else {
            console.error(`dataSource.ts - event targetOfType "${event.targetOfType}" not implemented`);
        }
    });

    ds.on('isDependenciesMet', (event) => {
        isDependenciesMet.value = event.data.isDependenciesMet;
    });

    ds.on('fetch', (event) => {
        error.value = undefined;
        if (event.error) {
            console.error('error while fetching data', event.error);
            error.value = event.error;
            isLoading.value = false;
            isDataReady.value = false;
            return;
        }
        if (event.isRequestStart) {
            isLoading.value = true;
        }
    });

    ds.on('sync', (event) => {
        if (!event.isRequestStart && event.data && event.data.length) {
            const failedResponse = event.data.find((response: any) => response.isError);
            if (failedResponse) {
                emit('onError', definition, [failedResponse.result.error]);
            } else {
                emit('afterSync', definition, event.data[0].result.result[0]);
            }
        }
    });

    ds.on('validated', (event) => {
        validationResult.value = event.data;
    });

    onUnmounted(() => {
        if (ds) {
            ds.dispose();
            ds = undefined;
        }
    });

    return {
        isDependenciesMet,
        isLoading,
        data,
        error,
        dataSource: ds,
        isGeoLocationNeeded,
        geoLocation: location,
        isDataReady,
        count,
        dataDefinition,
        selectedItems,
        validationResult,
        toggleSelectItem,
    };
}

export interface ValidationResult {
    hasErrors: boolean;
    items: any[];
}

export interface ValidationError {
    fieldName: string;
    key: string;
    text: string;
}

export async function translateValidationErrors(itemErrors: Record<string, any[]>, fields?: DataSourceFields): Promise<{ key: string, text: string, fieldName: string }[]> {
    const apiInstance: F2Api = api.get() as F2Api;
    const validationErrors: ValidationError[] = [];
    const dbTranslations: Record<string, any[]> = {};
    await Promise.all(Object.keys(itemErrors)
        .map(async (key) => {
            const errors: any[] = itemErrors[key];
            const fieldName = fields && (key in fields) && fields[key].label && await apiInstance.getLabelText(<string>fields[key].label) || key;
            for (const errorInfo of errors) {
                if (errorInfo.key && errorInfo.text) {
                    validationErrors.push( {
                        fieldName,
                        key: errorInfo.key,
                        text: errorInfo.text,
                    });
                } else if (validator.isUid(errorInfo.config.errorMessage)) {
                    errorInfo.property = key;
                    if (errorInfo.config.errorMessage in dbTranslations) {
                        dbTranslations[errorInfo.config.errorMessage].push(Object.assign(errorInfo, {fieldName}));
                    } else {
                        dbTranslations[errorInfo.config.errorMessage] = [Object.assign(errorInfo, {fieldName})];
                    }
                } else {
                    validationErrors.push({
                        fieldName,
                        key,
                        text: localization.global.t(
                            `${errorInfo.config.scope || 'validation'}.${errorInfo.rule}`,
                            {
                                ...typeof errorInfo.config.options === 'string' ? {str: errorInfo.config.options} : errorInfo.config.options || {},
                                ...errorInfo.config.translationData || {},
                            },
                        ),
                    });
                }
            }
        }),
    );
    if (!isEmpty(dbTranslations)) {
        await Promise.all(
            Object.keys(dbTranslations)
                .map(async (uid: string) => {
                    const labelText: string | undefined = await apiInstance.getLabelText(uid);
                    dbTranslations[uid].forEach((errorInfo: any) => {
                        const text = replaceTags(labelText, errorInfo.config.translationData);
                        validationErrors.push({
                            fieldName: errorInfo.fieldName,
                            key: errorInfo.property,
                            text,
                        });
                    });
                }),
        );
    }
    return validationErrors;
}
