import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["href", "download"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!

  return (_ctx.isDownloading)
    ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
        key: 0,
        value: _ctx.progressPercent / 100
      }, null, 8, ["value"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.objectURL)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              ref: "aTagRef",
              href: _ctx.objectURL,
              download: _ctx.fileName
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.triggerDownload && _ctx.triggerDownload(...args)))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
}