/* eslint-disable no-unused-vars */
import {actionSheetController} from '@ionic/vue';
import resolve from '@/framework2-sdk/dependencyResolver';
import F2DataSource from '@/framework2-sdk/f2DataSource';
import {DataSourceConfig} from '@/framework2-sdk/dataSource';
import router from '@/router';
import {store} from '../store';
import {Condition, isConditionsMet} from '../helpers';
import {validator} from '../framework2-sdk/tools/validatorWrapper';
import {ref} from 'vue';
import {overwrite} from '@/framework2-sdk/tools/object';
import * as api from '@/api';
import CacheHandler from '@/cacheHandler';
import {TagsToResolve} from '@/helpers';
import dataSourceRegistry from '@/dataSourceRegistry';
import {getIcon} from '@/icons/icons';
import {Calendar, CalendarOptions} from '@awesome-cordova-plugins/calendar';
import {showErrorToast, showWarningToast, showSuccessToast, showInfoToast} from '@/functionality/logging';
import * as barcode from '@/functionality/barcode';
import {dataSource, componentRegistry} from '@/functionality/dataSource';
import {NdefOriginal, NfcTag} from '@awesome-cordova-plugins/nfc';
import * as NFC from './nfc';
import F2Api, {LoginInfo} from '@/framework2-sdk/f2Api';

declare global {
	const nfc: any;
    const ndef: NdefOriginal;
}

export interface ActionData {
    type: ActionType,
    definition: ActionDefinition,
    template: string,
    icon?: { family: string, name: string },
}

export interface ActionDefinition {
    uid: string,
	key?: string,
	href?: string,
    navigateBack?: boolean
	srcOrUid?: string,
	type?: LinkType | DataType | NotificationType | FileType | BarcodeType | CalendarType | NFCType | UserType,
	data?: any,
	after?: ActionData[],
	action: 'update' | 'insert' | 'delete',
	title?: string,
	text?: string,
	cancelable?: boolean,
	actions?: ActionData[],
	barcodeFieldName?: string,
	barcodeType?: string,
	value?: string,
	eventId?: string,
	startDate?: string,
	endDate?: string,
	location?: string,
	notes?: string,
	eventIdFieldName?: string
	nfcStringPayloadFieldName?: string
	textRecords?: {text: string, languageCode: string}[]
	uriRecords?: {uri: string}[]
	condition?: Condition
}

type LinkType = 'page' | 'newRecord' | 'historyBack' | 'externalHref';
type DataType = 'modify' | 'httpCall' | 'refresh';
type NotificationType = 'success' | 'warning' | 'error' | 'danger' | 'info' | 'actions';
type FileType = 'preview' | 'download';
type BarcodeType = 'read' | 'show';
type CalendarType = 'add' | 'remove' | 'find';
type NFCType = 'write' | 'read' | 'erase';
type UserType = 'changeApplication' | 'refreshUserRoles';

export enum ActionType {
    Link = 'link',
	File = 'file',
	Data = 'data',
	Notification = 'notification',
	Barcode = 'barcode',
	Calendar = 'calendar',
	NFC = 'nfc',
    USER = 'user'
}

export function action(props: any) {
    const isExecuting = ref(false);
    const error = ref<Error | undefined>(undefined);
    const actionData = props.definition as ActionData;
    const resultData = ref<any>(undefined);

    if (!actionData) {
        return {
            actionType: null,
            executeAction: function() {},
            isExecuting,
            error,
            resultData,
        };
    }

    async function _executeAction(actionData: ActionData, isExecuteActionThrowsError = false, data: any = undefined) {
        let contextData = data || props.data;

        if (
            actionData.definition.condition &&
			!isConditionsMet(
				getResolvedData(actionData.definition.condition, Array.isArray(contextData) ? contextData[0] : contextData) as Condition,
			)
        ) {
            return;
        }

        error.value = undefined;
        isExecuting.value = true;
        try {
            if (actionData.type === ActionType.Link) {
                if (actionData.definition.key) {
                    store.commit('user/storage', {
                        key: actionData.definition.key,
                        value: contextData,
                    });
                }
                if (['page', 'newRecord'].includes(<string>actionData.definition.type) || actionData.definition.uid) {
                    const uid = <string | undefined>(getResolvedData({
                        uid: actionData.definition.uid,
                    }, contextData) as any)?.uid;
                    if (!uid) {
                        throw new Error('action - resolved uid is not a string');
                    } else if (actionData.definition.type === 'newRecord') {
                        const definition = (await api.get()?.component(uid))?.definition;
                        definition.data.source.autoFetch = false;
                        definition.data.source = dataSource(definition).dataSource;
                        store.commit('user/definition', {
                            key: uid,
                            value: {
                                type: 'submit-button',
                                definition,
                            },
                        });
                        store.commit('user/storage', {
                            key: `data-uid-${uid}`,
                            value: undefined,
                        });
                        await router.push({name: 'components', params: {id: uid}});
                    } else {
                        await router.push({name: 'pages', params: {id: uid}});
                    }
                } else if (actionData.definition.type === 'historyBack' || actionData.definition.navigateBack) {
                    router.back();
                } else if (actionData.definition.type === 'externalHref' || actionData.definition.href) {
                    const href = <string | undefined>getResolvedData(actionData.definition.href, contextData);
                    if (href) {
                        const a = document.createElement('a');
                        a.target = '_blank';
                        a.href = href;
                        a.click();
                    } else {
                        throw new Error('action - resolved href is not a string');
                    }
                } else {
                    throw new Error('action - unknown link action definition');
                }
            } else if (actionData.type === ActionType.Data) {
                if (!contextData && actionData.definition.data.action !== 'read') {
                    throw new Error('action - data must be handed in to executeAction function or within props');
                }
                if (actionData.definition.type === 'modify') {
                    if (!actionData.definition.data) {
                        throw new Error('action - definition must contain data property');
                    }
                    contextData = overwriteWithValue(contextData, getResolvedData(actionData.definition.data, contextData));
                } else if (actionData.definition.type === 'httpCall') {
                    if (!actionData?.definition?.data?.source?.uid) {
                        throw new Error('action does not contain .definition.data.source.uid');
                    }
                    if (actionData?.definition.data.source.requestData) {
                        contextData = overwriteWithValue(contextData, getResolvedData(actionData?.definition.data.source.requestData, contextData));
                    }
                    if (actionData.definition.data.action === 'insert') {
                        await api.get()?.createData(actionData.definition.data.source.uid, contextData);
                    } else if (actionData.definition.data.action === 'update') {
                        await api.get()?.updateData(actionData.definition.data.source.uid, contextData);
                    } else if (actionData.definition.data.action === 'delete') {
                        await api.get()?.deleteData(actionData.definition.data.source.uid, contextData);
                    } else if (actionData.definition.data.action === 'read') {
                        const ds = new F2DataSource({
                            autoFetch: false,
                            ...getResolvedData(actionData.definition.data.source, contextData) as DataSourceConfig,
                        }, dataSourceRegistry);
                        await ds.getDefinition();
                        contextData = await ds.callSource(contextData, actionData.definition.data.action);
                    }
                } else if (actionData.definition.type === 'refresh') {
                    // const dataSources = Object.values(dataSourceRegistry.dataSources);
                    // const ds = dataSources.find((d: any) => d.definition._componentUID === actionData.definition.uid);
                    if (actionData.definition.uid in componentRegistry) {
                        await componentRegistry[actionData.definition.uid].fetch();
                    } else {
                        throw new Error('action - component for refresh not found');
                    }
                }
            } else if (actionData.type === ActionType.File) {
                const data = getResolvedData(actionData.definition.srcOrUid, props.data) as string;
                if (actionData.definition.type === 'download') {
                    if (data && validator.isUid(data)) {
                        resultData.value = `${store.state.user.credentials.baseURL}/api/files/${data}`;
                    } else {
                        resultData.value = data;
                    }
                } else if (actionData.definition.type === 'preview') {
                    const data = getResolvedData(actionData.definition.srcOrUid, props.data) as string;
                    if (data && validator.isUid(data)) {
                        router.push({name: 'files', params: {uid: data}});
                    }
                }
            } else if (actionData.type === ActionType.Notification) {
                if (actionData.definition.key) {
                    store.commit('user/storage', {
                        key: actionData.definition.key,
                        value: contextData,
                    });
                }
                const header = getResolvedData(actionData.definition.title, contextData) as string || '';
                const message = getResolvedData(actionData.definition.text, contextData) as string || '';
                if (actionData.definition.type === 'error' || actionData.definition.type === 'danger') {
                    await showErrorToast(message, header);
                } else if (actionData.definition.type === 'warning') {
                    await showWarningToast(message, header);
                } else if (actionData.definition.type === 'success') {
                    await showSuccessToast(message, header);
                } else if (actionData.definition.type === 'info') {
                    await showInfoToast(message, header);
                } else {
                    const actionSheet = await actionSheetController.create({
                        header,
                        subHeader: message,
                        backdropDismiss: typeof actionData.definition.cancelable === 'boolean' ? actionData.definition.cancelable : true,
                        buttons: (actionData.definition.actions || [])
                            .map((_action: ActionData) => {
                                const {executeAction} = action({
                                    definition: _action,
                                    data: contextData,
                                });
                                return {
                                    text: getResolvedData(_action.template as string, contextData) as string,
                                    icon: _action.icon && getIcon(_action.icon),
                                    handler: async () => {
                                        executeAction();
                                    },
                                };
                            }),
                        // .concat([{
                        //     text: 'Cancel',
                        //     icon: undefined,
                        //     handler: async () => {},
                        // }]),
                    });
                    return actionSheet.present();
                }
            } else if (actionData.type === ActionType.Barcode) {
                if (actionData.definition.type === 'read') {
                    try {
                        const result = await barcode.read();
                        if (actionData.definition.barcodeFieldName) {
                            contextData = fillFieldNameWithValue(contextData, actionData.definition.barcodeFieldName, result.rawValue);
                        }
                    } catch (e: any) {
                        if (e && e.message) {
                            showErrorToast(e.message);
                        }
                        throw e;
                    }
                } else {
                    await barcode.show(
						actionData.definition.barcodeType as string,
						getResolvedData(actionData.definition.value, contextData) as string,
                    );
                }
            } else if (actionData.type === ActionType.Calendar) {
                try {
                    const calendarEventOptions = await getCalendarEventOptions(actionData.definition, contextData);
                    if (actionData.definition.type === 'add') {
                        const eventId = await Calendar.createEventWithOptions(
                            calendarEventOptions.title,
                            calendarEventOptions.location,
                            calendarEventOptions.notes,
                            calendarEventOptions.startDate,
                            calendarEventOptions.endDate,
                            calendarEventOptions.calendar,
                        );
                        if (actionData.definition.eventIdFieldName) {
                            contextData = fillFieldNameWithValue(contextData, actionData.definition.eventIdFieldName, eventId);
                        }
                    } else if (actionData.definition.type === 'remove') {
                        if (calendarEventOptions.id) {
                            await Calendar.deleteEventById(
                                calendarEventOptions.id,
                                calendarEventOptions.startDate,
                            );
                        } else {
                            await Calendar.deleteEvent(
                                calendarEventOptions.title,
                                calendarEventOptions.location,
                                calendarEventOptions.notes,
                                calendarEventOptions.startDate,
                                calendarEventOptions.endDate,
                            );
                        }
                        if (actionData.definition.eventIdFieldName) {
                            contextData = fillFieldNameWithValue(contextData, actionData.definition.eventIdFieldName, null);
                        }
                    } else if (actionData.definition.type === 'find') {
                        const events = await Calendar.listEventsInRange(
                            calendarEventOptions.startDate,
                            calendarEventOptions.endDate,
                        );
                        contextData = events
                            .filter((event: any) =>
                                (!calendarEventOptions.id || calendarEventOptions.id == event.event_id) &&
								(!calendarEventOptions.title || calendarEventOptions.title == event.title) &&
								(!calendarEventOptions.location || calendarEventOptions.location == event.eventLocation),
                            )
                            .map((event: any) => ({
                                id: event.event_id,
                                title: event.title,
                                location: event.eventLocation,
                                startDate: new Date(event.dtstart),
                                endDate: new Date(event.dtend),
                                allDay: event.allDay,
                            }));
                        if (actionData.definition.key) {
                            store.commit('user/storage', {
                                key: actionData.definition.key,
                                value: contextData,
                            });
                        }
                    }
                } catch (e: any) {
                    if (e && e.message) {
                        showErrorToast(e.message);
                    }
                    throw e;
                }
            } else if (actionData.type === ActionType.NFC) {
                const available = await NFC.available();
                if (!available) {
                    return;
                }
                if (actionData.definition.key) {
                    store.commit('user/storage', {
                        key: actionData.definition.key,
                        value: contextData,
                    });
                }
                if (actionData.definition.type === 'read') {
                    const tag: NfcTag = await NFC.read();
                    const messages = tag.ndefMessage
                        ?.map((ndefMessage) => {
                            const payloadAsStringWithPrefix = nfc.bytesToString(ndefMessage.payload);
                            return {
                                id: ndefMessage.id[0],
                                tnf: ndefMessage.tnf,
                                type: ndefMessage.type[0],
                                payload: ndefMessage.payload,
                                payloadAsString: payloadAsStringWithPrefix.slice(ndefMessage.payload[0] + 1), // cut first char and language code
                                payloadAsStringWithPrefix,
                                payloadAsHexString: nfc.bytesToHexString(ndefMessage.payload),
                            };
                        }) || [];
                    if (
                        actionData.definition.nfcStringPayloadFieldName &&
						messages?.length
                    ) {
                        contextData = fillFieldNameWithValue(
                            contextData,
                            actionData.definition.nfcStringPayloadFieldName,
                            messages[0].payloadAsString,
                        );
                    } else {
                        contextData = messages;
                    }
                } else if (actionData.definition.type === 'erase') {
                    await NFC.erase();
                    if (actionData.definition.nfcStringPayloadFieldName) {
                        contextData = fillFieldNameWithValue(contextData, actionData.definition.nfcStringPayloadFieldName, null);
                    }
                } else if (actionData.definition.type === 'write') {
                    const ndefMessage = (actionData.definition.textRecords || [])
                        .map(({text, languageCode}) => ndef.textRecord(text, languageCode))
                        .concat(
                            (actionData.definition.uriRecords || [])
                                .map(({uri}) => ndef.uriRecord(uri)),
                        );
                    await NFC.write(ndefMessage);
                }
            } else if (actionData.type === ActionType.USER) {
                if (actionData.definition.type === 'changeApplication') {
                    const key = getResolvedData(actionData.definition.key, props.data) as string;
                    if (!key) {
                        throw new Error('action - no application key defined');
                    }
                    const apiInstance = api.get() as F2Api;
                    const loginInfo = await apiInstance.setApplication(key, store.state.user.credentials.setCookie);
                    const cacheHandler = new CacheHandler(store.state.user?.credentials?.serverKey as string, key, loginInfo.userId);
                    await cacheHandler.init();
                    apiInstance.cacheHandler = cacheHandler;
                    store.commit('app/setCacheActivationStatus', cacheHandler.isActive);
                    await api.initUser(
                        Object.assign({}, store.state.user.credentials, {applicationKey: key}),
                        loginInfo,
                        store.state.user.locale,
                    );
                    await router.replace({path: `/pages/${store.state.user.lastPageUID}`});
                } else if (actionData.definition.type === 'refreshUserRoles') {
                    // change appication to same applicationKey -> server side logged user should get refreshed
                    const loginInfo = await api.get()?.setApplication(store.state.user.credentials.applicationKey || 'none', store.state.user.credentials.setCookie) as LoginInfo;
                    await api.initUser(store.state.user.credentials, loginInfo, store.state.user.locale);
                    await router.replace({path: `/pages/${store.state.user.lastPageUID}`});
                }
            } else {
                const e = new Error(`action not implemented - action type "${actionData.type}"`);
                throw e;
            }

            if (actionData.definition.after) {
                for (const a of actionData.definition.after) {
                    await _executeAction(a, isExecuteActionThrowsError, contextData);
                }
            }
        } catch (e: any) {
            error.value = e;
            if (isExecuteActionThrowsError) {
                throw e;
            }
        } finally {
            isExecuting.value = false;
        }
    };

    const executeAction = async (isExecuteActionThrowsError = false, data: any = undefined) => await _executeAction(actionData, isExecuteActionThrowsError, data);

    return {
        actionType: actionData?.type,
        executeAction,
        isExecuting,
        error,
        resultData,
    };
}

function getResolvedData(toResolve: string | object | undefined, data: any): string | object | undefined {
    if (!toResolve) {
        return toResolve;
    };
    let result = resolve(
        toResolve,
        new TagsToResolve({
            self: data,
            storage: store.state.user.storage,
        }),
        undefined,
        undefined,
        dataSourceRegistry,
        store.state.user.locale,
    );
    if (!result && Array.isArray(data) && data.length === 1) {
        result = resolve(toResolve,
            new TagsToResolve({
                self: data[0],
                storage: store.state.user.storage,
            }),
            undefined,
            undefined,
            dataSourceRegistry,
            store.state.user.locale,
        );
    }
    return result;
}

function fillFieldNameWithValue(data: any, fieldName: string, value: any): any {
    return overwriteWithValue(data, {
        [fieldName]: value,
    });
}

function overwriteWithValue(data: any, value: any): any {
    if (Array.isArray(data)) {
        data.forEach((record) => overwrite(record, value));
    } else if (data) {
        overwrite(data, value);
    } else {
        data = [value];
    }
    return data;
}

async function getCalendarEventOptions(actionDefinition: ActionDefinition, data: any): Promise<CalendarEventOptions> {
    const calendars = await Calendar.listCalendars();
    const calendar = calendars.find((calendar: any) => calendar.isPrimary);
    const options: CalendarEventOptions = getResolvedData({
        id: actionDefinition.eventId,
        startDate: actionDefinition.startDate,
        endDate: actionDefinition.endDate,
        title: actionDefinition.title,
        location: actionDefinition.location,
        notes: actionDefinition.notes,
    }, data) as CalendarEventOptions;
    options.startDate = options.startDate && new Date(options.startDate);
    options.endDate = options.endDate && new Date(options.endDate);
    options.calendar = {
        calendarId: calendar.id,
    };
    return options;
}

interface CalendarEventOptions {
	id: string,
	startDate: Date,
	endDate: Date,
	title: string,
	location: string,
	notes: string,
	calendar: CalendarOptions
}
