import EventEmitter from './eventEmitter';
import DataItem from './dataItem';
import DataSourceRegistry from './dataSourceRegistry';
import { emptyValueFilterOperators } from './dsFilters';
import recursion from './tools/recursion';
import { clone, isEmpty, accessUsingDotNotationRemoveZeroIndexesOnFail } from './tools/object';
import promise from './tools/promise';
import { validate } from './tools/validator';

const fieldsToResolve = [
    'filter',
    'where',
    'orderBy',
    'data',
];

//TODO: isdependymet aredependencies met, return true when tagsToResolve contains main object but does not contain subobject property
export default class DataSource extends EventEmitter {

    constructor (definition, dataSourceRegistry = new DataSourceRegistry()) {
        super();
        this._definition = {};
        this._data = [];
        this._count = null;
        this._viewData = [];
        this.fetchCount = 0;

        this._dependencyChangeHandlers = [];
        this._dependencyFilters = [];
        this._isDependenciesMet = true;
        this._dataSourceRegistry = dataSourceRegistry;

        if (definition) {
            this.setDefinition(definition);
        }
    }

    dispose () {
        if (this.id) {
            this._dataSourceRegistry.remove(this);
        }
        if (Array.isArray(this._data)) {
            this._data.forEach(d => d.__off('changed', this._dataItemChangedTrigger));
        }
        else if (this._data && this._data.__isDataItem) {
            this._data.__off('changed', this._dataItemChangedTrigger);
        }
    }

    setDefinition (definition) {
        if (this._dependencyChangeHandlers.length) {
            this._unregisterDependencies();
        }
        if (this.id) {
            this._dataSourceRegistry.remove(this);
        }

        this.debug = definition.debug;

        this._dependencies = [];
        this._dependencyData = [];
        this._dependencyChangeHandlers = [];
        this._dependencyFilters = [];
        this._isDependenciesMet = true;

        this._viewData = [];
        this._data = [];
        this._count = null;
        this._itemsToDelete = [];

        this._filterValue = {};
        this._DBInstructions = {};
        this.requestData = {};

        this._definition = this._sanitizeDefinition(definition);

        this.id = definition.id;

        if (this.id) {
            this._dataSourceRegistry.register(this);
        }

        if (this._definition._dependencies) {
            this._dependencies = this._definition._dependencies;
        }

        this._DBInstructions = this._definition._DBInstructions;
        this._dependencies = this._searchAndDefineDependencies();

        if (this._dependencies.length) {
            this._registerDependencies();
        }

        if (this._definition._filter) {
            this.setNamedFilter(this._definition._filter, 'DBInstructions');
        }

        if (this._definition._requestData) {
            this.requestData = this._resolve(this._definition._requestData, false);
        }

        if (this._definition._data) {
            this._handleIncomingData(this._definition._data);
        }
        else if (this._definition._source) {
            if (this._areDependenciesMet()) {
                this._fetch();
            }
        }
    }

    _searchDependenciesInObject (object, dependencies, position = 'unknown') {
        dependencies = dependencies || [];
        this._callForPlaceholders(object, (keyFragments) => {
            const startIndex = keyFragments[0] !== '__ds' ? 0 : 1;
            const dataSourceId = keyFragments[startIndex];
            const name = keyFragments.slice(startIndex+1).join('.');
            const dataSourceDep = dependencies.find(dep => dep.dataSourceId === dataSourceId);
            const fieldDep = {
                name,
                required: true,
                position,
            };
            if (dataSourceDep) {
                dataSourceDep.fields.push(fieldDep);
            }
            else {
                const dep = {
                    dataSourceId,
                    fields: [
                        fieldDep,
                    ],
                };
                if (keyFragments[0] !== '__ds') {
                    const key = keyFragments[0].substring(2);
                    if (!(key in this._definition._tagsToResolve)) {
                        fieldDep.required = false;
                    }
                    dep.dataSource = this._definition._tagsToResolve[key];
                    dep.data = this._definition._tagsToResolve[key];
                    if (!dep.dataSource) {
                        return;
                    }
                }
                dependencies.push(dep);
            }
        });
    }

    _searchAndDefineDependencies () {
        for (let fieldName of fieldsToResolve) {
            if (fieldName in this._DBInstructions) {
                this._searchDependenciesInObject(this._DBInstructions[fieldName], this._dependencies, fieldName);
            }
        }
        return this._dependencies;
    }

    _initDependencies () {
        // TODO: use dependency resolver here
    }

    _callForPlaceholders (object, func) {
        recursion.traverseObjectValues(object, (value, key, result, depth) => {
            if (
                typeof value === 'string'
                    && value.startsWith('__')
            ) {
                const keyFragments = value.split('.');
                func(keyFragments, key, result, depth);
            }
        });
    }

    get definition () {
        return this._definition;
    }

    _sanitizeDefinition (definition) {
        let definitionDefaults = {
            _select: null, // select gets passed to server as select clause
            _primaryKey: ['id'],
            _fields: {},
            _defaultSearchParam: 'q',
            _dependencies: [],
            _autoFetch: true, // if false: ds does not call fetch until user calls ds.fetch({ autoFetch: true })
            // _dependencies: [
            //     {
            //         dataSource: {},
            //         dataSourceId: '',
            //         filter: {}, // or function to build filter, return false to prevent filtering
            //         fields: [
            //             {
            //                 name: 'id',
            //                 required: true, // required means the ds will not load data until all required fields are set
            //             }
            //         ],
            //     }
            // ]
            _tagsToResolve: {}, // object of type { __tagToLookFor: { key: 'replaceVal' } } that replaces value of object '__tagToLookFor.key' resulting in 'replaceVal'
            _needsGeoLocation: null
        };

        if (definition) {

            let validDefinition = {
                _DBInstructions: {},
            };

            // case definition is data
            if (
                Array.isArray(definition)
                || !definition.data
                    && !definition.source
                    && !definition.magicDataSource
            ) {
                validDefinition._data = definition;
                validDefinition._autoFetch = false;
                return Object.assign(definitionDefaults, validDefinition);
            }

            if (definition.magicDataSource) {
                validDefinition = this._convertMagicDataSource(definition.magicDataSource);
            }

            if (definition.data) {
                validDefinition._data = definition.data;
            }

            if (definition.fields) {
                validDefinition._fields = definition.fields;
            }

            if (definition.primaryKey) {
                if (Array.isArray(definition.primaryKey)) {
                    validDefinition._primaryKey = definition.primaryKey;
                }
                else {
                    validDefinition._primaryKey = [definition.primaryKey];
                }
            }

            if (definition.source) {
                validDefinition._source = definition.source;
            }

            if (definition.DBInstructions) {
                validDefinition._DBInstructions = definition.DBInstructions;
            }

            if (definition.select) {
                validDefinition._DBInstructions.select = validDefinition._select = definition.select;
            }

            if (definition.orderBy) {
                validDefinition._DBInstructions.orderBy = validDefinition._orderBy = definition.orderBy;
            }

            if (definition.limit) {
                validDefinition._DBInstructions.limit = validDefinition._limit = definition.limit;
            }

            if (Array.isArray(definition.dependencies)) {
                validDefinition._dependencies = definition.dependencies;
            }

            if (definition.filter) {
                validDefinition._DBInstructions.where = validDefinition._filter = definition.filter;
            }

            if (definition.tagsToResolve && typeof definition.tagsToResolve === 'object') {
                validDefinition._tagsToResolve = definition.tagsToResolve;
            }

            if ('autoFetch' in definition) {
                validDefinition._autoFetch = definition.autoFetch;
            }

            if ('requestData' in definition && definition.requestData && typeof definition.requestData === 'object') {
                validDefinition._requestData = definition.requestData;
            }

            if (definition.validation && typeof definition.validation === 'object') {
                validDefinition._validation = definition.validation;
            }

            if (definition.files && typeof definition.files === 'object') {
                validDefinition._files = definition.files;
            }

            if (definition.getFilesByName) {
                validDefinition._getFilesByName = definition.getFilesByName;
            }

            if ('needsGeoLocation' in definition && typeof definition.needsGeoLocation === 'boolean') {
                validDefinition._needsGeoLocation = definition.needsGeoLocation;
            }

            if (definition.componentUID) {
                validDefinition._componentUID = definition.componentUID;
            }

            return Object.assign(definitionDefaults, validDefinition);
        }

        return definitionDefaults;
    }

    // pass function for local filtering or filter to pass to server
    async filter (condition = null, options = {}) {
        let data;
        if (typeof condition === 'function') {
            data = this._data.filter(condition);
        }
        // server filtering
        else {
            this.setNamedFilter(condition);
            data = await this._fetch(options);
        }
        if (data) {
            this._viewData = data;
            this.emit('filtered', data);
        }
    }

    clearFilters () {
        // TODO: remove dependencies
        this._filterValue = {};
    }

    sort (sort) {
        if (sort === undefined) {
            return this._DBInstructions ? (this._DBInstructions.orderBy || []) : [];
        }
        this.mergeDBInstructions({ orderBy: sort || undefined });
    }

    limit (limit) {
        if (limit === undefined) {
            return this._DBInstructions.limit;
        }
        this.mergeDBInstructions({ limit: limit || undefined });
    }

    offset (offset) {
        if (offset === undefined) {
            return this._DBInstructions.offset;
        }
        this.mergeDBInstructions({ offset: offset || undefined });
    }

    clearDBInstructions () {
        // TODO: remove dependencies
        this._DBInstructions = {};
    }

    // removes existing named filter, then sets new filter (condition)
    // TODO: remove/set dependencies
    setNamedFilter (condition = null, name = 'default', logic = 'and') {
        condition = this._parseFilter(condition);
        if (condition !== null) {
            condition.name = name;
        }
        if (!this._filterValue.filters) {
            if (condition === null) {
                return;
            }
            this._filterValue = {
                filters: [
                    condition,
                ],
                logic,
            };
        }
        else {
            let index = this._filterValue.filters.findIndex(condition => condition.name === name);
            if (index > -1) {
                this._filterValue.filters.splice(index, 1);
            }
            if (condition !== null) {
                this._filterValue.filters.push(condition);
            }
        }
    }

    async setSearchFilter (searchTerm) {
        if (!searchTerm) {
            this.setNamedFilter(undefined, 'search');
        }
        else {
            let filters = [];
            for (let fieldName in this._definition._fields) {
                let field = this._definition._fields[fieldName],
                    validation = (this._definition._validation || {})[fieldName];
                // ignore not filtrable fields and JSON array (string) fields
                if (
                    field.filtrable === false
                    || validation
                    && 'isArray' in validation
                ) {
                    continue;
                }
                if (field.dataSource) {
                    // TODO: reset filters
                    // field.dataSource.setNamedFilter();
                    filters.push(
                        field.dataSource
                            .clone({ autoFetch: false })
                            .then(dataSource => {
                                return new Promise(resolve => {
                                    dataSource
                                        .setNamedFilter({
                                            field: field.textColumn || 'description',
                                            value: searchTerm,
                                            operator: 'contains'
                                        });

                                    dataSource.once('changed', event => {
                                        if (event.targetOfType === 'data') {
                                            let ids = event.data.map(dataItem => dataItem[field.valueColumn || 'id']);
                                            resolve(ids.length ? {
                                                field: fieldName,
                                                value: ids,
                                                operator: 'in'
                                            } : null);
                                        }
                                    });
                                    dataSource.fetch({ autoFetch: true });
                                });
                            })
                            .catch(e => {
                                if (this.debug) {
                                    console.warn('Error in setSearchFilter on resolving FK fields: ', e);
                                }
                                return undefined;
                            })
                    );
                }
                else if (
                    this._definition._getFilesByName
                    && this._definition._files
                    && this._definition._files.columns
                    && this._definition._files.columns[fieldName]
                ) {
                    filters.push(
                        this._definition._getFilesByName(searchTerm)
                            .then(files => files.length ? {
                                filters: files.map(file => ({
                                    field: fieldName,
                                    value: file.uid,
                                    operator: 'contains'
                                })),
                                logic: 'or'
                            } : null)
                    );
                }
                else if (field.type === 'string') {
                    filters.push({
                        field: fieldName,
                        value: searchTerm,
                        operator: 'contains'
                    });
                }
            }

            this.setNamedFilter({
                filters: await Promise.all(filters),
                logic: 'or'
            }, 'search');
        }
    }

    _parseFilter (condition) {
        if (condition) {
            if (typeof condition === 'string') {
                if (!condition) {
                    return null;
                }
                condition = {
                    field: this._definition._defaultSearchParam,
                    operator: 'contains',
                    value: condition,
                };
                return condition;
            }
            if (condition.filters) {
                for (const filter of condition.filters) {
                    this._parseFilter(filter);
                }
            }
            else if (condition.field === '__id') {
                condition = this._getIdFilter(condition);
            }
            return condition;
        }
        return null;
    }

    _getIdFilter (condition) {
        let value = condition.value.split(',');
        condition.logic = 'and';
        condition.filters = this._definition._primaryKey
            .map((partialId, i) => ({
                field: partialId,
                operator: condition.operator || 'eq',
                value: value[i],
            }));
        delete condition.field;
        delete condition.value;
        delete condition.operator;
        return condition;
    }

    _sanitizeFilters (filter) {
        if (filter) {
            if ('filters' in filter) {
                // recursion
                filter.filters = filter.filters
                    .map(filter => this._sanitizeFilters(filter))
                    .filter(filter => filter);

                // reduce level if it contain only one child filter
                if (filter.filters.length === 1) {
                    return filter.filters[0];
                }
                // return only if it contains child filter(s)
                else if (filter.filters.length) {
                    return filter;
                }
                return undefined;
            }
            else if ('value' in filter) {
                let field = this._definition.fields ? this._definition.fields[filter.field] : undefined;

                // no operator needed for boolean or foreign filters (has a dataSource)
                if (field && (field.type === 'boolean' || field.data && field.data.source)) {
                    delete filter.operator;
                }
                // those filter operators does not need a value
                else if (filter.operator && emptyValueFilterOperators.indexOf(filter.operator) !== -1) {
                    delete filter.value;
                }
                // clear filter if it has no value
                else if (filter.value === undefined || filter.value === null) {
                    return undefined;
                }
                return filter;
            }
            else if (!Object.keys(filter).length) {
                return undefined;
            }
            // remove filter if it needs a value but has none
            else if (emptyValueFilterOperators.indexOf(filter.operator) === -1) {
                return undefined;
            }
        }
        return filter;
    }

    _project (data) {
        let record = {
            __id: data.__id,
        };
        for (let [ column, alias ] of Object.entries(this._definition._select)) {
            record[alias] = data[column];
        }
        return record;
    }

    _logDebug () {
        console.log(`dataSource (id: ${this.id})`, ...arguments);
    }

    async _fetch (options = {}, isInternalCall = true) {
        if (options.fetch === false || isInternalCall && !this._definition._autoFetch) {
            return;
        }
        let data = this._data;
        const currentFetchNo = ++this.fetchCount;
        if (this._definition._source) {
            if (!this._areDependenciesMet()) {
                throw new Error('dependencies not met');
            }
            let source = this._definition._source;
            let typeOfSource = typeof source;
            if (typeOfSource === 'function') {
                this.emit('fetch', {}, { isRequestStart: true });
                try {
                    let dBInstructions = this.getDBInstructions();
                    if (dBInstructions.where) {
                        dBInstructions.where = this._sanitizeFilters(dBInstructions.where);
                    }
                    if (!isEmpty(this.requestData)) {
                        dBInstructions.data = this.requestData;
                    }
                    data = await source(dBInstructions, 'read');
                }
                catch (e) {
                    this.emit('fetch', {}, { isRequestStart: false, error: e });
                    if (isInternalCall) {
                        return;
                    }
                    else {
                        throw e;
                    }
                }
                this.emit('fetch', {}, { isRequestStart: false });
                if (this.debug) {
                    this._logDebug('called fetch with dbInstructions:', this.getDBInstructions(), 'result:', data, this);
                }
            }
            else {
                throw new Error('no definition.source function provided, that is needed to fetch data');
            }
            if (!('triggerChanged' in options)) {
                options.triggerChanged = currentFetchNo === this.fetchCount;
            }
            data = this._handleIncomingData(data, options);
        }
        return data;
    }

    async fetch (options) {
        return this._fetch(options, false);
    }

    getDBInstructions () {
        // optimization, check if some dependency has changed since last call in order to not resolve dependencies on every call
        const DBInstructions = Object.assign(
            this._DBInstructions,
            {
                where: this._filterValue,
            },
        );
        return this._resolve(DBInstructions);
    }

    _resolve (object, fieldsAreRequired = true) {
        return clone(object, (_, value) => {
            if (typeof value === 'string' && value.startsWith('__')) {
                const fragments = value.split('.');
                fragments[0] = fragments[0].substring(2);
                if (value.startsWith('__ds.')) {
                    const DSID = fragments[1];
                    const fieldName = fragments.slice(2).join('.');
                    const index = this._dependencies.findIndex(d => d.dataSourceId === DSID);
                    if (index === -1) {
                        return value;
                    }
                    const dependency = this._dependencies[index];
                    const data = this._dependencyData[index];
                    const fieldDef = dependency.fields.find(fieldDef => fieldDef.field === fieldName);
                    //TODO: add conditions checking if needed like we did for app for ask if show a field or not
                    const fieldValue = this._accessUsingDotNotation(data, fieldName);
                    if (fieldDef && fieldValue === undefined) {
                        if (fieldDef.required === true) {
                            throw new Error(`did not found required data ${fieldName} in dependency data with id ${DSID}`);
                        }
                        else {
                            return undefined;
                        }
                    }
                    return fieldValue;
                }
                else if (fragments[0] in this._definition._tagsToResolve) {
                    let item = this._accessUsingDotNotation(this._definition._tagsToResolve, fragments);
                    // let item = this._definition._tagsToResolve;
                    // for (const val of fragments) {
                    //     if (Array.isArray(item[val])) {
                    //         if (item[val].length) {
                    //             item = item[val][0];
                    //         }
                    //         else {
                    //             item = undefined;
                    //             break;
                    //         }
                    //     }
                    //     else if (val in item) {
                    //         item = item[val];
                    //     }
                    //     else {
                    //         item = undefined;
                    //         break;
                    //     }
                    // }
                    if (item === undefined) { //TODO: if dependency is not required remove whole object
                        if (this.debug) {
                            console.warn(`key ${fragments[1]} not found within object with key ${fragments[0]} in definition.tagsToResolve`);
                        }
                        if (fieldsAreRequired)
                            throw new Error(`did not found required data in tagsToResolve ${fragments[1]} in dependency data with id ${fragments[0]}`);
                        return undefined;
                    }
                    return item;
                }
                else {
                    return undefined;
                }
            }
            return value;
        });
    }

    _accessUsingDotNotation (data, path) {
        const result = accessUsingDotNotationRemoveZeroIndexesOnFail(data, path);
        return result.data;
    }

    // TODO: set/remove dependencies
    mergeDBInstructions (DBInstructions, options = {}) {
        Object.assign(this._DBInstructions, DBInstructions);
        return this._fetch(options);
    }

    // TODO: set/remove dependencies
    setDBInstructions (DBInstructions, options = {}) {
        this._DBInstructions = DBInstructions;
        return this._fetch(options);
    }

    _registerDependencies () {
        let dependencies = this._dependencies;
        let i = 0;
        while (i < dependencies.length) {
            let dependency = dependencies[i];
            if (
                !dependency.dataSource
                    && !dependency.dataSourceId
                || !dependency.fields
            ) {
                dependencies.splice(i, 1);
                continue;
            }

            this._isDependenciesMet = false;
            let handler = this._dependencyChangeHandler(i);
            this._dependencyFilters.push({});
            this._dependencyChangeHandlers.push(handler);

            if (dependency.dataSource && dependency.dataSource.__on) {
                dependency.dataSource.__on('changed', handler);
                this._dependencyData.push(dependency.dataSource);
            }
            else if (dependency.dataSource && dependency.dataSource.on) {
                dependency.dataSource.on('changed', handler);
                this._dependencyData.push(dependency.dataSource.data);
            }
            else if (dependency.data) {
                this._dependencyData.push(dependency.data);
            }
            else if (dependency.dataSourceId) {
                this._dataSourceRegistry.on('changed', dependency.dataSourceId, handler, this.id);
                this._dependencyData.push(this._dataSourceRegistry.getData(dependency.dataSourceId));
            }
            else {
                continue;
            }
            i++;
        }
    }

    _unregisterDependencies () {
        let dependencies = this._dependencies;
        let i = 0;
        while (i < dependencies.length) {
            let dependency = dependencies[i];
            let handler = this._dependencyChangeHandlers.shift();
            if (dependency.dataSource) {
                dependency.dataSource.off('changed', handler);
            }
            else {
                this._dataSourceRegistry.off('changed', dependency.dataSourceId, handler);
            }
            this._dependencyData.shift();
            i++;
        }
    }

    _dependencyChangeHandler (index) {
        if (this.debug) {
            this._logDebug('dependency handler registered', this._dependencies[index]);
        }
        return (event) => {
            if (!this._isDependency(event, index)) {
                if (this.debug) {
                    this._logDebug('dependency handler called - but its no dependency', this._dependencies[index], 'event', event);
                }
                return;
            }
            if (this.debug) {
                this._logDebug('dependency handler called', this._dependencies[index], 'event', event);
            }
            this._dependencyData[index] = event.dataItem || event.data;
            let isDependenciesMet = false;
            // optimization, if all dependencies are met, we only check changed dependency
            if (this._isDependenciesMet) {
                isDependenciesMet = this._isDependenciesMet = this._isDependencyMet(index);
            }
            else if (this._areDependenciesMet()) {
                isDependenciesMet = true;
            }
            this.emit('dependencyChanged', event.data, Object.assign({ dependencyDefinition: this._dependencies[index], isDependenciesMet }, event.additionalInfo || {}));
            if (isDependenciesMet) {
                this._fetch();
            }
        };
    }

    _isDependency (event, index) {
        let dependency = this._dependencies[index];
        if (event.property && dependency.fields) {
            return dependency.fields.some((field) => field.name === event.property);
        }
        if (event?.originalEvent?.property && dependency.fields && !Array.isArray(event.data)) {
            return dependency.fields.some((field) => field.name === event?.originalEvent?.property);
        }
        if (Array.isArray(event.data)) {
            return true;
        }
        return false;
    }

    _isDependencyMet (index) {
        let dependency = this._dependencies[index];
        let dependencyData = this._dependencyData[index];
        if (dependencyData) {
            if (dependency.fields) {
                for (let field of dependency.fields) {
                    if (field.required && !this._accessUsingDotNotation(dependencyData, field.name)) {
                        if (this.debug) {
                            console.warn(`dataSource id ${this.id}`, 'dependency not met - field:', field, 'dependency', dependency);
                        }
                        return false;
                    }
                }
                let filter = this._parseDependencyFilter(dependency, dependencyData);
                if (filter) {
                    this.setNamedFilter(filter, 'dependencyFilterIndex' + index);
                }
            }
            return true;
        }
        return false;
    }

    _parseDependencyFilter (dependency, dependencyData) {
        if (dependency.filter) {
            return this._recurAndCreateFilter(dependency.filter, dependencyData);
        }
        return null;
    }

    _recurAndCreateFilter (filterBluePrint, data, filter) {
        if (!filter) {
            filter = Object.assign({}, filterBluePrint);
            delete filter.filters;
        }
        if (filterBluePrint.filters) {
            filter.filters = [];
            filterBluePrint.filters.map(filterBluePrint => {
                let newFilter = {};
                newFilter = this._recurAndCreateFilter(filterBluePrint, data, newFilter);
                if (newFilter) {
                    filter.filters.push(newFilter);
                }
            });
            if (!filter.filters.length) {
                delete filter.filters;
            }
        }
        else {
            filter = Object.assign(filter, filterBluePrint);
            if (filter.value.startsWith('__')) {
                let field = filter.value.substring(2);
                if (field in data) {
                    filter.value = data[field];
                }
                else {
                    return null;
                }
            }
        }
        return filter;
    }

    _areDependenciesMet () {
        if (this._isDependenciesMet) {
            return true;
        }
        for (let i = 0; i < this._dependencies.length; i++) {
            if (!this._isDependencyMet(i)) {
                this.emit('isDependenciesMet', { isDependenciesMet: false });
                return this._isDependenciesMet = false;
            }
        }
        this.emit('isDependenciesMet', { isDependenciesMet: true });
        return this._isDependenciesMet = true;
    }

    _handleIncomingData (data, options = {}) {
        let count = null;
        if (data) {
            if (Array.isArray(data)) {
                if (data.count !== undefined) {
                    count = data.count;
                }
                if (data.length) {
                    let type = typeof data[0];
                    data = data.map(dataItem => {
                        if (dataItem && type === 'object') {
                            return this._transformIntoDataItem(dataItem);
                        }
                        return dataItem;
                    });
                }
            }
            else {
                data = this._transformIntoDataItem(data);
            }
            this._itemsToDelete = [];
            this._data = this._viewData = data;
            this._count = count;
            if (options.triggerChanged !== false) {
                this.emit('changed', data, { targetOfType: 'data' });
            }
            return data;
        }
        throw new Error('data has to be of type Array [] or Object {}');
    }

    _dataItemChangedTrigger (event) {
        this.emit('changed', this._data, { originalEvent: event, targetOfType: 'data' });
    }

    _transformIntoDataItem (dataItem) {
        const d = new DataItem(dataItem, {
            id: this._definition._primaryKey,
            debug: this.debug,
        });
        d.__on('changed', this._dataItemChangedTrigger.bind(this));
        return d;
    }

    create (...data) {
        const createdItems = [];
        for (const item of data) {
            item.__action = 'create';
            const dataItem = this._transformIntoDataItem(item);
            this._data.push(dataItem);
            createdItems.push(dataItem);
        }
        return createdItems;
    }

    delete (...dataItems) {
        // I don't think this is used and I doubt this works correctly ;)
        const indexes = this.findIndex(...dataItems);
        for (const i of indexes) {
            if (i === -1) {
                continue;
            }
            this._data[i].__action = 'delete';
            this._data[i].__off('changed', this._dataItemChangedTrigger);
            this._itemsToDelete.push(this._data[i]);
            this._data.splice(i, 1);
        }
        return indexes;
    }

    getChangedItems (items = this._data) {
        const actionItems = {
            create: [],
            update: [],
            delete: [],
        };
        for (const item of items) {
            if (item.__action in actionItems) {
                actionItems[item.__action].push(item);
            }
        }
        if (this._itemsToDelete.length) {
            actionItems.delete = this._itemsToDelete;
        }
        return actionItems;
    }

    callSource (items, action = 'update') {
        let source = this._definition._source;
        if (typeof source === 'function') {
            if (action === 'read') {
                return this.fetch({
                    triggerChanged: false
                });
            }
            return source(items.map(item => ({ ...item.__getValues(), ...this.requestData })), action, items);
        }
        throw new Error('no source provided');
    }

    /**
     * syncs the local data with the source you provided
     * for each type of crud action the source function gets called once (if there are any records) with 2nd parameter as crud string (create, update, delete)
     * @returns a promise that gets resolved in every case and contains an array with the results
     */
    async sync (data = this._data) {
        const promises = [];
        let validationResult = this.validateItems(data);
        this.emit('validated', validationResult);
        if (!validationResult.hasErrors) {
            let source = this._definition._source;
            if (typeof source === 'function') {
                const changedItems = this.getChangedItems(data);
                this.emit('sync', changedItems, { isRequestStart: true });
                for (const [action, items] of Object.entries(changedItems)) {
                    if (items.length) {
                        promises.push(this.callSource(items, action));
                    }
                }
                const res = await promise.finally(promises);
                this.emit('sync', res, { isRequestStart: false });
                return res;
            }
            throw new Error('no source provided');
        }
        return [{ isError: true }];
    }

    validateItems (dataItems) {
        let hasErrors = false,
            items = dataItems
                .map(dataItem => {
                    let result = this.validateItem(dataItem);
                    if (result.hasErrors) {
                        hasErrors = true;
                    }
                    return result;
                });
        return {
            hasErrors,
            items
        };
    }

    validateItem (dataItem) {
        let result = {
            hasErrors: false,
            errors: {},
            dataItem
        };
        if (this._definition._validation) {
            Object.keys(this._definition._validation)
                .map(property => {
                    let rules = this._definition._validation[property];
                    let errors = validate(dataItem[property], rules);
                    if (errors.length) {
                        result.hasErrors = true;
                        result.errors[property] = errors;
                    }
                });
        }
        return result;
    }

    findItem (dataItem, items = this._data) {
        if (!dataItem.__isDataItem) {
            dataItem = this._transformIntoDataItem(dataItem);
        }
        return items.find(item => item.__id === dataItem.__id);
    }

    findIndex (...dataItems) {
        const indexes = [];
        for (const itemToFind of dataItems) {
            indexes.push(this._data.findIndex(i => i.__id === itemToFind.__id));
        }
        return indexes;
    }

    get data () {
        return this._data;
    }

    set data (data) {
        this._handleIncomingData(data);
    }

    get count () {
        return this._count;
    }

    get filterValue () {
        return this._filterValue;
    }

    get viewData () {
        return this._viewData;
    }

    _convertMagicDataSource (magicDataSource) {
        let definition = {};

        // default case DropdownValues
        if (magicDataSource.MagicDataSourceValueField) {
            definition._defaultSearchParam = magicDataSource.MagicDataSourceTextField;
            definition._select = {
                value: 'id',
                text: 'description',
            };
            definition._source = (condition) => {
                return window.$.post({
                    url: '/api/ManageFK/GetDropdownValues',
                    data: JSON.stringify({
                        tablename: magicDataSource.MagicDataSource,
                        valuefield: magicDataSource.MagicDataSourceValueField,
                        textfield: magicDataSource.MagicDataSourceTextField,
                        filter: condition,
                    }),
                    contentType: 'application/json',
                });
            };
            definition._primaryKey = [magicDataSource.MagicDataSourceValueField];
        }

        if (magicDataSource.CascadeColumnName) {
            let dependency = {
                dataSource: magicDataSource.getDataSource(),
                fields: [],
                filter: {
                    logic: 'and',
                    filters: [],
                },
            };
            let dependencyFields = magicDataSource.CascadeColumnName.split(',');
            let filterFieldValues = magicDataSource.CascadeFilterColumnName.split(',');
            dependencyFields.map((dependencyFieldName, i) => {
                dependency.fields.push({
                    name: dependencyFieldName,
                    required: true,
                });
                dependency.filter.filters.push({
                    field: filterFieldValues[i],
                    value: '__' + dependencyFieldName,
                    operator: 'eq',
                });
            });
            definition._dependencies = [dependency];
        }

        return definition;
    }

}
