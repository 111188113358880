import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_offline_icon = _resolveComponent("offline-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_openBlock(), _createBlock(_component_ion_toolbar, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_buttons, { slot: "start" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            color: "primary",
            disabled: _ctx.currentPage === 1,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentPage !== 1 ? _ctx.changePage(_ctx.currentPage-1) : undefined))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_offline_icon, { icon: "mdi:skip-previous" })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_buttons, { class: "pagination-center" }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
              return (_openBlock(), _createBlock(_component_ion_button, {
                key: page,
                color: page === _ctx.currentPage ? undefined : 'primary',
                onClick: ($event: any) => (page === _ctx.currentPage ? undefined : _ctx.changePage(page))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(page), 1)
                ]),
                _: 2
              }, 1032, ["color", "onClick"]))
            }), 128))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_buttons, { slot: "end" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            color: "primary",
            disabled: _ctx.currentPage === _ctx.amountPages,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentPage !== _ctx.amountPages ? _ctx.changePage(_ctx.currentPage+1) : undefined))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_offline_icon, { icon: "mdi:skip-next" })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}