import originalValidator from 'validator';
import * as v from './validator';

declare namespace ValidatorExtension {
    function isArray(value: string | Array<any>, options: any): boolean
    function isAlphanumericWithUnderscores(value: string): boolean
    function isType(value: string, options: { type: string }): boolean
    function isUid(value: any): boolean
    function notEmpty(value: string): boolean
    function notNull(value: string): boolean
}

export const validator: (typeof ValidatorExtension & typeof originalValidator) = v.validator as any;
