<template>
    <ul
        v-if="validationErrors && validationErrors.length > 0"
        class="ion-text-wrap"
    >
        <li
            v-for="(error, i) in validationErrors"
            :key="i"
        >
            <ion-text color="danger">
                {{ error.fieldName }}: {{ error.text }}
            </ion-text>
        </li>
    </ul>
    <ion-label
        v-if="!isDependenciesMet"
        color="warning"
        style="display: flex; justify-content: center"
    >
        {{ t("dependencies not met") }}
    </ion-label>
    <ion-progress-bar
        v-if="isLoading || isGeoLocationNeeded && geoLocation.isRequestingLocation.value"
        type="indeterminate"
    />
    <ion-label
        v-if="isGeoLocationNeeded && geoLocation.isRequestingLocation.value"
        color="primary"
        class="ion-text-wrap"
        style="display: flex; justify-content: center"
    >
        {{ t("info.readingGeoLocationFromDevice") }}
    </ion-label>
    <ion-label
        v-if="error"
        color="danger"
        class="ion-text-wrap"
        style="display: flex; justify-content: center"
    >
        {{ t("error.loadingData") }}
    </ion-label>
    <ion-label
        v-if="isGeoLocationNeeded && geoLocation.error.value"
        color="danger"
        class="ion-text-wrap"
        style="display: flex; justify-content: center"
    >
        {{ t("error.gettingGeoLocation") }}
    </ion-label>
</template>

<script lang="ts">
import {defineComponent, watchEffect, watch, ref} from 'vue';
import {IonProgressBar, IonLabel, IonText} from '@ionic/vue';
import {useI18n} from 'vue-i18n';
import {DataSourceInfo, translateValidationErrors} from '@/functionality/dataSource';

export default defineComponent({
    components: {
        IonProgressBar,
        IonLabel,
        IonText,
    },
    props: {
        dataSourceInfo: Object as () => DataSourceInfo,
    },
    setup(props) {
        const {t} = useI18n();
        const validationErrors = ref<any[]>([]);

        const geoLocation = props.dataSourceInfo?.geoLocation;
        const validationResult = props.dataSourceInfo?.validationResult;

        if (validationResult) {
            watch(validationResult, async (validationResult) => {
                validationErrors.value = validationResult.hasErrors ? await translateValidationErrors(validationResult.items[0].errors, props.dataSourceInfo?.dataDefinition.value?.fields) : [];
            });
        }

        watchEffect(() => {
            if (geoLocation?.error.value) {
                console.error('error on obtaining geoLocation', geoLocation.error.value);
            }
        });

        return {
            t,
            validationErrors,
            ...props.dataSourceInfo,
        };
    },
});
</script>
