import Api from './f2Api';

let api;

export default new Proxy({}, {
    get: (_, prop) => {
        return api[prop];
    },
    set: (_, prop, value) => {
        api[prop] = value;
        return true;
    },
    deleteProperty: (_, prop) => {
        return delete api[prop];
    }
});

export function login (username, password, applicationKey, config = {}) {
    api = new Api(config);
    return api.login(username, password, applicationKey, config.setCookie);
}

export function logout () {
    return api.logout();
}

export function set (_api) {
    return api = _api;
}