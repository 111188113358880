import axios from 'axios';
import { merge, cloneDeep } from 'lodash';

export default class F2Api {

    constructor (config = {}) {
        this.cachedCounts = {};
        this.merge = merge;
        this.labels = {};
        this._labelRequests = {};
        this.http = axios.create();
        this.config = Object.assign(
            {
                storage: null,
                debug: false,
                getGeoLocation: () => Promise.resolve(null),
                base64EncodeFunction: typeof btoa !== 'undefined' && typeof window !== 'undefined' ? btoa.bind(window) : null,
                onRequestError: e => {
                    throw e;
                },
                // onTokenExpired: function - gets called when token is expired - should internally call api.login function to login again
            }
            , config
        );
        this.storage = config.storage;
        this.cancelSource = axios.CancelToken.source();

        if (typeof this.config.base64EncodeFunction !== 'function') {
            throw new Error('this.config.base64EncodeFunction is not a function has to be a function that can convert a string into base64 string');
        }
        if (this.config.language) {
            this.setLanguage(this.config.language);
        }
        if (this.config.baseUrl) {
            this.setBaseUrl(this.config.baseUrl);
        }

        if (this.storage) {
            if (this.storage.getItem('tokenRefreshedTime'))
                this.tokenRefreshed = new Date(this.storage.getItem('tokenRefreshedTime'));
            if (this.storage.getItem('tokenExpiresInMinutes'))
                this.tokenExpiresInMS = this.storage.getItem('tokenExpiresInMinutes') * 60 * 1000;
            if (this.storage.getItem('token')) {
                this.token = this.storage.getItem('token');
                this.http.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
            }
        }
    }

    getServerConfig (serverKey) {
        return this.http.get(`https://ilos.framework2.dev/update/clients/key/${serverKey}`)
            .then(
                (response) => {
                    if (!response.status) {
                        throw { response };
                    }
                    return response.data;
                }
            );
    }

    getLoginInfo (baseUrl = '') {
        return this.http.get(`${baseUrl}/api/config/login-info`)
            .then(
                (response) => {
                    if (!response.status) {
                        throw { response };
                    }
                    return response.data;
                }
            );
    }

    async login (
        username
        , password
        , applicationKey
        , setCookie = typeof window !== 'undefined'
        , baseUrl = ''
    ) {
        let res = await this.http.post(
            `${baseUrl}/api/users/login`
            , {
                username,
                password,
                applicationKey,
                setCookie,
            }
            , {
                withCredentials: setCookie
            }
        )
            .catch(this._handleError);
        return this._setToken(res.data);
    }

    async loginWithOneTimePassword (
        oneTimePassword
        , applicationKey
        , setCookie = typeof window !== 'undefined'
        , baseUrl = ''
    ) {
        let res = await this.http.post(
            `${baseUrl}/api/users/login/one-time-password`
            , {
                oneTimePassword,
                applicationKey,
                setCookie,
            }
            , {
                withCredentials: setCookie
            }
        )
            .catch(this._handleError);
        return this._setToken(res.data);
    }

    async loginWith2FA (
        username
        , totp
        , applicationKey
        , setCookie = typeof window !== 'undefined'
        , baseUrl = ''
    ) {
        let res = await this.http.post(
            `${baseUrl}/api/users/login/2fa`
            , {
                username,
                totp,
                applicationKey,
                setCookie,
            }
            , {
                withCredentials: setCookie
            }
        )
            .catch(this._handleError);
        return this._setToken(res.data);
    }

    getApplications (baseUrl = '') {
        return this.http.get(`${baseUrl}/api/config/applications`)
            .then(
                ({ data }) => data
                , this._handleError
            );
    }

    getUserApplications (isShowInactive = false) {
        return this.queryApi(`/api/users/me/applications?isShowInactive=${isShowInactive}`);
    }

    async setApplication (
        applicationKey
        , setCookie = typeof window !== 'undefined'
        , baseUrl = ''
    ) {
        let res = await this.http.post(
            `${baseUrl}/api/users/me/application/${applicationKey}`
            , {
                setCookie,
            }
            , {
                withCredentials: setCookie
            }
        )
            .catch(this._handleError);
        return this._setToken(res.data);
    }

    _setToken (response) {
        this.token = response.token;
        this.http.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        this.tokenExpiresInMS = response.expiresInMinutes * 60 * 1000;
        this.refreshTokenTime();
        if (this.storage) {
            this.storage.setItem('tokenExpiresInMinutes', response.expiresInMinutes);
            this.storage.setItem('token', this.token);
        }
        return response;
    }

    register (userInfo, baseUrl = '') {
        return this.http.post(
            `${baseUrl}/api/users/register`
            , userInfo
        )
            .catch(this._handleError);
    }

    termsAndConditions (applicationIDs, baseUrl = '') {
        return this.http.get(
            `${baseUrl}/api/config/translations/terms-and-conditions`
            , {
                params: {
                    applicationIDs
                }
            }
        )
            .catch(this._handleError);
    }

    forgotPassword (email, baseUrl = '') {
        return this.http.get(
            `${baseUrl}/api/users/reset/password`
            , {
                params: {
                    email
                }
            }
        )
            .catch(this._handleError);
    }

    resendEmailConfirmation (data, baseUrl = '') {
        return this.http.post(
            `${baseUrl}/api/users/email/confirm/resend`
            , data
        )
            .catch(this._handleError);

    }

    resetPassword (password, userId, token) {
        return this.http.post(
            `/api/users/${userId}/reset/password/${token}`
            , {
                password
            }
        )
            .catch(this._handleError);
    }

    confirmEmail (token) {
        return this.http.get(
            `/api/users/email/confirm/${token}`
        )
            .catch(this._handleError);
    }

    register2FA (username, password, baseUrl = '') {
        return this.http.post(
            `${baseUrl}/api/users/register/2fa`
            , {
                username,
                password
            }
        )
            .catch(this._handleError);
    }

    getLanguages (baseUrl = '') {
        return this.http.get(`${baseUrl}/api/config/languages`)
            .then(
                (response) => {
                    if (!response.status) {
                        throw { response };
                    }
                    return response.data;
                }
            )
            .catch(this._handleError);
    }

    async logout () {
        if (this.cancelSource) {
            this.cancelSource.cancel('Logged out');
            this.cancelSource = axios.CancelToken.source();
        }
        if (!this.isTokenExpired()) {
            await this.callApi({
                url: '/api/users/logout'
            });
        }
        this.calcelToken();
    }

    getCountRequest (config, dbInstructions = null) {
        if (
            config.method
            && config.method.toLowerCase() !== 'get'
        ) {
            Object.keys(this.cachedCounts)
                .forEach(key => {
                    if (key.startsWith(config.url)) {
                        delete this.cachedCounts[key];
                    }
                });
            return null;
        }
        else if (
            !dbInstructions
            || !dbInstructions.limit
            || dbInstructions.aggregation
        ) {
            return null;
        }

        dbInstructions = cloneDeep(dbInstructions);
        config = cloneDeep(config);
        config.isCountRequest = true;
        config.handleError = () => [{}];

        delete dbInstructions.orderBy;
        delete dbInstructions.select;
        delete dbInstructions.offset;
        delete dbInstructions.limit;
        const cacheKey = `${config.url}-${JSON.stringify(dbInstructions)}`;

        if (cacheKey in this.cachedCounts) {
            return Promise.resolve(this.cachedCounts[cacheKey]);
        }

        dbInstructions.aggregation = [
            {
                function: 'count',
                column: '*',
                alias: 'count',
            },
        ];
        dbInstructions.limit = 1;
        this.addDbInstructionsParam(config, dbInstructions);

        return this.callApi(config)
            .then(result => {
                this.cachedCounts[cacheKey] = result;
                return result;
            });
    }

    addCountToResult (data, countData) {
        if (Array.isArray(data[0])) {
            return data.map((v, i) => {
                v.count = countData[i][0].count;
            });
        }
        else {
            data.count = countData[0].count;
            return data;
        }
    }

    async callApi (config, dbInstructions = null, callCount = 1) {
        let request,
            isCache = false;

        // records count
        const countRequest = this.getCountRequest(config, dbInstructions);

        if (this.cacheHandler) {
            request = this.cacheHandler.get(config);
            isCache = !!request;
        }
        if (!request) {
            request = Promise.all([
                'needsGeoLocation' in config
                && this.config.getGeoLocation(config.needsGeoLocation),
                this.checkToken()
            ])
                .then(([geoLocation]) => {
                    if (geoLocation) {
                        if (!config.headers) {
                            config.headers = {};
                        }
                        config.headers['Geo-Position'] = `${geoLocation.latitude};${geoLocation.longitude}`;
                    }

                    // remove Authorization header if url !== baseURL (avoid sending authorization header to external destinations)
                    if (
                        config.url.startsWith('http')
                        && !config.url.startsWith(this.http.defaults.baseURL)
                    ) {
                        if (!config.headers) {
                            config.headers = {};
                        }
                        config.headers['Authorization'] = undefined;
                    }

                    if (global.isDebug) {
                        console.log('http call', config, dbInstructions);
                    }

                    if (
                        !config.cancelToken
                        && this.cancelSource
                    ) {
                        config.cancelToken = this.cancelSource.token;
                    }

                    return this.http(config);
                });
        }

        return request
            .then(async response => {
                // is timeout -> throw error
                if (!response.status) {
                    throw { response };
                }
                if (this.config.debug) {
                    console.log('requestEnd', response, config);
                }

                if (!isCache) {
                    this.refreshTokenTime();
                    if (this.cacheHandler) {
                        await this.cacheHandler.set(config, response);
                    }
                }

                if (countRequest) {
                    return countRequest
                        .then(countResponse => {
                            this.addCountToResult(response.data, countResponse);
                            return response.data;
                        })
                        .catch(e => {
                            console.error('countRequest', e);
                            return response.data;
                        });
                }

                return response.data;
            })
            .catch(error => {
                if (!isCache) {
                    this.refreshTokenTime();
                }
                if (this.config.debug) {
                    console.warn('request failed', error, config);
                }
                this._handleError(error);
            })
            .catch(error => {
                if (
                    error instanceof ApiError
                    && error.key === 'INVALID_TOKEN'
                ) {
                    this.calcelToken();
                    if (callCount <= 1) {
                        return this.callApi(config, dbInstructions, callCount + 1);
                    }
                    else if (this.config.onTokenExpiredFails) {
                        this.config.onTokenExpiredFails(error);
                    }
                }
                if (config.handleError) {
                    return config.handleError(error);
                }
                throw error;
            })
            .catch(error => this.config.onRequestError.call(this, error, config));
    }

    setNotificationToken (id, token, device_info) {
        return this
            .callApi({
                url: `/api/users/notifications/tokens/${id || ''}`,
                method: id ? 'PATCH' : 'POST',
                data: {
                    token,
                    device_info
                },
                handleError: async e => {
                    try {
                        let records = await this
                            .queryApi({
                                url: '/api/users/notifications/tokens/',
                            }, {
                                where: {
                                    field: 'token',
                                    value: token
                                }
                            });
                        if (records.length) {
                            return records[0];
                        }
                        throw e;
                    }
                    catch (_e) {
                        throw e;
                    }
                }
            });
    }

    deleteNotificationToken (id) {
        return this
            .callApi({
                url: `/api/users/notifications/tokens/${id}`,
                method: 'DELETE'
            });
    }

    menus (client) {
        return this.callApi({
            url: '/api/menus/me',
            params: {
                client
            }
        });
    }

    menuItems (menuId) {
        return this.callApi({
            url: `/api/menus/${menuId}/items`
        });
    }

    // contains defaults menu, page, and additional user info
    userInfo () {
        return this.callApi({
            url: '/api/users/me'
        });
    }

    saveUserInfo (user) {
        return this.callApi({
            method: 'patch',
            url: `/api/users/${user.id}`,
            data: user,
        });
    }

    userSettings (dbInstructions) {
        return this.queryApi('/api/users/me/settings', dbInstructions);
    }

    userSetting (key, applicationID) {
        return this.callApi({
            url: `/api/users/me/settings/${key}`,
            params: {
                applicationID
            }
        });
    }

    saveUserSetting (key, value, applicationID) {
        return this.callApi({
            method: 'post',
            url: '/api/users/me/settings',
            data: {
                key,
                value,
                applicationID
            }
        });
    }

    deleteUserSetting (key, applicationID) {
        return this.callApi({
            method: 'delete',
            url: `/api/users/me/settings/${key}`,
            params: {
                applicationID
            }
        });
    }

    addEncryptionToken (publicToken, privateToken) {
        return this.callApi({
            method: 'post',
            url: '/api/users/me/encryption-token',
            data: {
                publicToken,
                privateToken
            }
        });
    }

    removeEncryptionToken (publicToken) {
        return this.callApi({
            method: 'delete',
            url: `/api/users/me/encryption-token/${encodeURIComponent(publicToken)}`,
        });
    }

    deleteUser () {
        return this.callApi({
            method: 'delete',
            url: '/api/users/me/',
        });
    }

    addUserApplications (userID, data) {
        return this.callApi({
            method: 'post',
            url: `/api/users/${userID}/applications`,
            data
        });
    }

    deleteUserApplications (userID, data) {
        return this.callApi({
            method: 'delete',
            url: `/api/users/${userID}/applications`,
            data
        });
    }

    pages (dbInstructions) {
        return this.queryApi('/api/pages', dbInstructions);
    }

    page (uid) {
        return this.callApi({
            url: `/api/pages/${uid}`
        })
            .then(page => {
                if (Array.isArray(page.definition)) {
                    for (let component of page.definition) {
                        if (Array.isArray(component.labels)) {
                            for (let label of component.labels) {
                                this.labels[label.uid] = label;
                            }
                        }
                    }
                }
                return page;
            });
    }

    component (uid) {
        return this.callApi({
            url: `/api/components/${uid}`
        })
            .then(component => {
                if (!component.definition) {
                    component.definition = {};
                }
                else if (Array.isArray(component.definition.labels)) {
                    for (let label of component.definition.labels) {
                        this.labels[label.uid] = label;
                    }
                }
                return component;
            });
    }

    components (components) {
        return Promise.all(
            components
                .map(component => {
                    if (!component.definition) {
                        component.definition = {};
                    }
                    if (component.uid) {
                        // extend component with definition from separate request with uid
                        return this.component(component.uid)
                            .then(componentExtension => {
                                return merge(componentExtension, component);
                            }, () => {
                                // return base definition if request fails
                                return component;
                            });
                    }
                    return component;
                })
        );
    }

    componentsRecursively (components) {
        return this.components(components)
            .then(components => {
                return Promise.all(
                    components
                        .map(component => {
                            if (component.definition && component.definition.subcomponents && component.definition.subcomponents.length) {
                                return this.componentsRecursively(component.definition.subcomponents)
                                    .then(subcomponents => {
                                        // overwrite subcomponents with fetched subcomponents
                                        component.definition.subcomponents = subcomponents;
                                        return component;
                                    }, (e) => {
                                        // return component without subcomponents if request fails
                                        delete component.definition.subcomponents;
                                        if (this.config.debug)
                                            console.warn('Error on fetching subcomponents', e);
                                        return component;
                                    });
                            }
                            return component;
                        })
                );
            });
    }

    dataDefinition (uid) {
        return this.callApi({
            url: `/api/data/${uid}/definition`
        })
            .then(res => {
                res.uid = uid;
                if (res.labels) {
                    res.labels.forEach(label => this.labels[label.uid] = label);
                }
                return res;
            });
    }

    callData (uid, data, method = 'post', dbInstructions = {}) {
        const config = {
            method,
            url: `/api/data/${uid}`,
            data,
        };
        if (method === 'delete') {
            config.method = 'post';
            config.url += '/delete';
        }
        return this.queryApi(config, dbInstructions);
    }

    createData (uid, data) {
        return this.callData(uid, data);
    }

    readData (uid, dbInstructions, needsGeoLocation) {
        return this.queryApi({
            url: `/api/data/${uid}`,
            needsGeoLocation
        }, dbInstructions);
    }

    updateData (uid, data) {
        return this.callData(uid, data, 'patch');
    }

    deleteData (uid, data) {
        return this.callData(uid, data, 'delete');
    }

    getFileInfo (uid) {
        return this.callApi({
            url: `/api/files/${uid}/info`
        });
    }

    getFiles (dbInstructions, isAdmin) {
        return this.queryApi({
            url: '/api/files',
            params: {
                isAdmin
            }
        }, dbInstructions);
    }

    setFileAccesses (data) {
        return this.callApi({
            url: '/api/files/access',
            method: 'post',
            data
        });
    }

    deleteFile (uid) {
        return this.callApi({
            url: `/api/files/${uid}`,
            method: 'delete'
        });
    }

    sendFile (files, onUploadProgress, cancelToken, uids) {
        const data = new FormData();
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        if (uids) {
            data.append('uids', JSON.stringify(uids));
        }
        if (files.length) {
            for (const file of files) {
                data.append(file.name, file);
            }
        }
        else {
            data.append('FILE', files);
            headers['File-Name'] = files.name;
        }

        return this.callApi({
            url: '/api/files',
            method: 'POST',
            data,
            headers,
            onUploadProgress,
            cancelToken
        });
    }

    notifications (dbInstructions) {
        return this.queryApi('/api/users/notifications', dbInstructions);
    }

    unreadNotificationsCount (applicationId) {
        const filters = [{
            field: 'un.is_read',
            value: false,
        }];
        if (applicationId) {
            filters.push({
                filters: [{
                    field: 'un.application_id',
                    value: applicationId,
                }, {
                    field: 'un.application_id',
                    operator: 'isnull',
                }],
                logic: 'or',
            });
        }
        return this.queryApi('/api/users/notifications', {
            limit: 1,
            where: [{
                filters,
            }],
            aggregation: [{
                function: 'count',
                column: '*',
                alias: 'count',
            }]
        })
            .then(records => parseInt(records[0].count));
    }

    readNotification (notificationId) {
        return this.callApi({
            method: 'POST',
            url: `/api/users/notifications/${notificationId}/is_read`
        });
    }

    getLabelTextFromCache (uid) {
        if (this.labels[uid]) {
            return this.labels[uid].text_overwrite || this.labels[uid].text;
        }
    }

    async getLabelText (uid, defaultValue = uid) {
        let label = this.labels[uid] || await this.getLabel(uid);
        if (label) {
            return label.text_overwrite || label.text || label.default_text_overwrite || label.default_text;
        }
        else if (this.config.debug) {
            console.warn('label uid missing!');
        }
        return defaultValue;
    }

    getLabel (uid) {
        if (!(uid in this._labelRequests)) {
            this._labelRequests[uid] = this.getLabels([uid])
                .then(labels => {
                    delete this._labelRequests[uid];
                    return labels[0];
                }, e => {
                    delete this._labelRequests[uid];
                    throw e;
                });
        }
        return this._labelRequests[uid];
    }

    getLabels (uids) {
        return this.callApi({
            url: '/api/labels',
            params: {
                uids
            }
        })
            .then(labels => {
                labels.forEach(label => this.labels[label.uid] = label);
                return labels;
            }, () => []);
    }

    clearLabels () {
        this.labels = {};
        this._labelRequests = {};
    }

    setBaseUrl (baseUrl) {
        this.http.defaults.baseURL = baseUrl;
    }

    getBaseUrl () {
        return this.http.defaults.baseURL;
    }

    setLanguage (language) {
        if (this.getLanguage() !== language) {
            this.clearLabels();
        }
        this.http.defaults.headers.common['Accept-Language'] = language;
    }

    getLanguage () {
        return this.http.defaults.headers.common['Accept-Language'];
    }

    addDbInstructionsParam (config, dbInstructions) {
        let query = JSON.stringify(dbInstructions);
        if (query !== '{}') {
            if (!config.params) {
                config.params = {};
            }
            config.params.q = this.config.base64EncodeFunction(query);
            if (this.config.debug) {
                config.params.dbInstructions = dbInstructions;
            }
        }
    }

    queryApi (config, dbInstructions = {}) {
        if (typeof config === 'string') {
            config = {
                url: config
            };
        }
        this.addDbInstructionsParam(config, dbInstructions);
        return this.callApi(config, dbInstructions);
    }

    getTasks (clientType) {
        return this.callApi({
            url: '/api/tasks/me',
            params: {
                client: clientType
            }
        });
    }

    getOfflineConfigCount () {
        return this.callApi({
            url: '/api/users/me/config/count',
        });
    }

    getOfflineConfig (client, onDownloadProgress) {
        return this.callApi({
            url: '/api/users/me/config',
            params: {
                client
            },
            onDownloadProgress
        });
    }

    async checkToken () {
        if (this.isTokenExpired()) {
            if (this._onTokenExpired) {
                await this._onTokenExpired;
            }
            else if (this.config.onTokenExpired) {
                this._onTokenExpired = this.config.onTokenExpired.call(this);
                await this._onTokenExpired;
                delete this._onTokenExpired;
            }
            else {
                throw new Error('token expired, please login');
            }
        }
        return this.token;
    }

    isTokenExpired () {
        return !this.token
            || !this.tokenRefreshed
            || this.tokenRefreshed.getTime() + this.tokenExpiresInMS < Date.now();
    }

    refreshTokenTime () {
        this.tokenRefreshed = new Date();
        if (this.storage) {
            this.storage.setItem('tokenRefreshedTime', this.tokenRefreshed);
        }
    }

    calcelToken () {
        delete this.token;
        delete this.tokenExpiresInMS;
        delete this.tokenRefreshed;
        if (this.storage) {
            this.storage.removeItem('token');
            this.storage.removeItem('tokenRefreshedTime');
            this.storage.removeItem('tokenExpiresInMinutes');
        }
    }

    _handleError (error) {
        if (
            error
            && error.response
            && error.response.status < 500
            && error.response.data
            && error.response.data.message
        ) {
            throw new ApiError(error.response.data);
        }
        throw error;
    }

    _getSearchFilter (searchTerm, columns) {
        if (!searchTerm)
            return undefined;
        return {
            filters: columns
                .map(field => ({
                    field,
                    value: searchTerm,
                    operator: 'contains'
                })),
            logic: 'or'
        };
    }

    _extendDBInstuctions (
        searchFilter
        , dbInstructions = {}
        , applicationId
        , applicationIDPrefix = ''
        , showFromOtherApplication = !applicationId
        , applicationIDColumnName = 'application_id'
    ) {
        if (applicationId) {
            if (!showFromOtherApplication) {
                let field = (applicationIDPrefix ? `${applicationIDPrefix}.` : '') + applicationIDColumnName;
                dbInstructions.where = this._extendFilter(
                    {
                        filters: [
                            {
                                field,
                                value: parseInt(applicationId)
                            }
                            , {
                                field,
                                operator: 'isnull'
                            }
                        ],
                        logic: 'or'
                    }
                    , dbInstructions.where
                );
            }
            else {
                if (!dbInstructions.data) {
                    dbInstructions.data = {};
                }
                dbInstructions.data.currentApplicationId = parseInt(applicationId);
            }
        }
        if (searchFilter) {
            dbInstructions.where = this._extendFilter(searchFilter, dbInstructions.where);
        }
        return dbInstructions;
    }

    _extendFilter (
        filterToAdd
        , originalFilter = {
            filters: [],
            logic: 'and'
        }
        , logic = 'and'
    ) {
        if (originalFilter) {
            if (originalFilter.logic === logic) {
                originalFilter.filters.push(filterToAdd);
            }
            else {
                originalFilter = {
                    filters: [originalFilter, filterToAdd],
                    logic: 'and'
                };
            }
        }
        return originalFilter;
    }
}

export class ApiError {
    constructor (error) {
        Object.assign(this, {
            message: '',
            validationErrors: []
        }, error);
    }

    getValidationErrors (fieldName) {
        return this.validationErrors
            .filter(e => e.key === fieldName)
            .map(e => e.text);
    }

    addValidationError (key, text) {
        this.validationErrors.push({
            key,
            text
        });
    }

    clear () {
        this.message = '';
        this.validationErrors = [];
    }
}