<template>
    <div>
        <f2-toolbar
            ref="toolbar"
            :definition="props.definition"
            :data-source-info="dataSourceInfo"
            :selected-items="selectedItems"
            @setpage="pagination.changePage($event)"
        />
        <data-source-status :data-source-info="dataSourceInfo" />
        <div style="position: relative;">
            <ion-refresher
                slot="fixed"
                @ionRefresh="doRefresh"
            >
                <ion-refresher-content />
            </ion-refresher>
            <div class="table-responsive">
                <table
                    :style="props.definition.resolvedStyles"
                    :class="props.definition.resolvedClasses"
                >
                    <thead>
                        <tr>
                            <th
                                v-for="subcomponent in subcomponents"
                                :key="subcomponent.id"
                            >
                                {{ subcomponent.resolvedTemplate || subcomponent.label }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="isDataReady && data?.length">
                            <tr
                                v-for="item of data"
                                :key="item.__id + '-' + item.__changed_at"
                                :class="{
                                    'active ion-color-medium': selectedItems.find(_item => _item.__id === item.__id)
                                }"
                                @click="toggleSelectItem(item)"
                            >
                                <td
                                    v-for="subcomponent in subcomponents"
                                    :key="subcomponent.id"
                                >
                                    <f2-component
                                        :definition="clone(subcomponent)"
                                        :data="item"
                                    />
                                </td>
                            </tr>
                        </template>
                        <tr v-else-if="isDataReady">
                            <td :colspan="labels.length">
                                <ion-label style="display: flex; justify-content: center">
                                    {{ t("error.noRecordsFound") }}
                                </ion-label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <f2-pagination
            ref="pagination"
            :data-source-info="dataSourceInfo"
        />
    </div>
</template>
<script lang="ts">
import {defineComponent, ref} from 'vue';
import {
    IonLabel,
    IonRefresher,
    IonRefresherContent,
} from '@ionic/vue';
import {dataSource} from '@/functionality/dataSource';
import {resolveTemplate} from '@/functionality/template';
import {useI18n} from 'vue-i18n';
import {clone} from '@/framework2-sdk/tools/object';
import DataSourceStatus from '@/components/DataSourceStatus.vue';
import F2Pagination from '@/components/F2Pagination.vue';
import F2Toolbar from '@/components/F2Toolbar.vue';

export default defineComponent({
    components: {
        F2Toolbar,
        DataSourceStatus,
        IonLabel,
        F2Pagination,
        IonRefresher,
        IonRefresherContent,
    },
    props: {
        definition: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        const {t} = useI18n();
        const toolbar = ref();
        const pagination = ref();
        const subcomponents: any[] = props.definition.subcomponents;

        for (const [i, subcomponent] of Object.entries(subcomponents)) {
            if (!subcomponent.id) {
                subcomponent.id = subcomponent.uid || i;
            }
            if (subcomponent.definition.label) {
                subcomponent.label = resolveTemplate({definition: {template: `{__label.${subcomponent.definition.label}}`}}).template;
            }
        }

        const dataSourceInfo = dataSource(
            props.definition,
            async (fetchedDataDefinition) => {
                if (!fetchedDataDefinition.limit) {
                    fetchedDataDefinition.limit = 20;
                }
                return fetchedDataDefinition;
            },
        );

        const {
            data,
            isLoading,
            error,
            isDependenciesMet,
            isGeoLocationNeeded,
            geoLocation,
            isDataReady,
            count,
            selectedItems,
            toggleSelectItem,
        } = dataSourceInfo;

        async function doRefresh(e: any) {
            await dataSourceInfo.dataSource?.fetch();
            e.target.complete();
        }

        return {
            t,
            toggleSelectItem,
            clone,
            subcomponents,
            isDependenciesMet,
            props,
            isLoading,
            error,
            data,
            isGeoLocationNeeded,
            geoLocation,
            dataSourceInfo,
            isDataReady,
            count,
            selectedItems,
            doRefresh,
            toolbar,
            pagination,
        };
    },
});
</script>
<style scoped lang="scss">
.table-responsive {
    overflow-x: auto;
}
table {
    width: 100%;
    border-collapse: collapse;

    tbody tr {
        cursor: pointer;
        &.active {
            background: var(--ion-color-base);
            color: var(--ion-color-contrast);
        }
    }

    > :not(caption) > * > * {
        padding: 0.5rem 0.5rem;
        border-bottom: 1px solid var(--ion-color-secondary);
        border-left: 1px solid var(--ion-color-secondary);

        &:first-child {
            border-left: none;
        }
    }
}
</style>
