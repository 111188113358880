export const emptyValueFilterOperators = ['isnull', 'isnotnull', 'isempty', 'isnotempty'];

export default function (filters) {
    if (Array.isArray(filters) && filters.length) {
        let orFilters = [],
            filterObject = {
                filters: [],
                logic: 'and'
            };
        for (let i = 0; i < filters.length; i++) {
            let filter = filters[i];
            if (filter.logic === 'or' && filter[i + 1]) {
                // add filter to orFilters until the logic of the next filter is not 'or'
                orFilters.push({
                    field: filter.field,
                    operator: filter.operator,
                    value: filter.value
                });
                do {
                    i++;
                    orFilters.push({
                        field: filter.field,
                        operator: filter.operator,
                        value: filter.value
                    });
                } while (filter[i + 1] && filter.logic === 'or');
                // add combined 'or'-filters to userFilter
                filterObject.filters.push({
                    filters: orFilters,
                    logic: 'or'
                });
                orFilters = [];
                continue;
            }
            else {
                // add 'and'-filter to userFilter
                filterObject.filters.push({
                    field: filter.field,
                    operator: filter.operator,
                    value: filter.value
                });
            }
        }
        return filterObject;
    }
    return null;
}