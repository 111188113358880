import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_f2_component = _resolveComponent("f2-component")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_fab_list = _resolveComponent("ion-fab-list")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_f2_sort_modal = _resolveComponent("f2-sort-modal")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_f2_filter_modal = _resolveComponent("f2-filter-modal")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!

  return (_ctx.definition.customToolbar && _ctx.definition.customToolbar.length)
    ? (_openBlock(), _createBlock(_component_f2_component, {
        key: 0,
        style: {"padding":"0 8px"},
        definition: _ctx.definition.customToolbar,
        data: _ctx.$parent.data,
        "should-clone-data": false,
        type: "indeterminate"
      }, null, 8, ["definition", "data"]))
    : (_ctx.$parent.isDataReady)
      ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 1 }, {
          default: _withCtx(() => [
            (_ctx.actions.length ||
                (_ctx.definition?.look?.detail?.grid.length
                    || _ctx.definition?.look?.detail?.subcomponents.length) &&
                (_ctx.definition?.data?.source?.rights?.create !== false
                    || _ctx.definition?.data?.source?.rights?.update !== false) ||
                _ctx.definition?.data?.source?.rights?.delete !== false
                || _ctx.fields.length)
              ? (_openBlock(), _createBlock(_component_ion_fab, {
                  key: 0,
                  slot: "secondary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_fab_button, { color: "secondary" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.chevronForward }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_fab_list, { side: "end" }, {
                      default: _withCtx(() => [
                        ((_ctx.definition?.look?.detail?.grid.length
                        || _ctx.definition?.look?.detail?.subcomponents.length)
                        && _ctx.definition?.data?.source?.rights?.create !== false)
                          ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                              key: 0,
                              color: "primary",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.create()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ((_ctx.definition?.look?.detail?.grid.length
                        || _ctx.definition?.look?.detail?.subcomponents.length)
                        && _ctx.definition?.data?.source?.rights?.update !== false)
                          ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                              key: 1,
                              disabled: !_ctx.selectedItems.length,
                              color: "primary",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit(_ctx.selectedItems[0])))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.createOutline }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }, 8, ["disabled"]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (actionData, i) => {
                          return (_openBlock(), _createBlock(_component_ion_fab_button, {
                            key: i,
                            color: "primary",
                            disabled: !_ctx.selectedItems.length,
                            onClick: ($event: any) => (_ctx.executeAction(actionData))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(actionData.template.template.value), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"]))
                        }), 128)),
                        (_ctx.definition?.data?.source?.rights?.delete !== false)
                          ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                              key: 2,
                              disabled: !_ctx.selectedItems.length,
                              color: "danger",
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteItems()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }, 8, ["disabled"]))
                          : _createCommentVNode("", true),
                        (_ctx.hasOrderableColumns)
                          ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                              key: 3,
                              color: !_ctx.sort.length ? 'primary' : 'secondary',
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSortModalOpen = true))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.shuffleOutline }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }, 8, ["color"]))
                          : _createCommentVNode("", true),
                        (_ctx.hasFiltrableColumns)
                          ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                              key: 4,
                              color: !_ctx.userFilters.length ? 'primary' : 'secondary',
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isFilterModalOpen = true))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.filter }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }, 8, ["color"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_searchbar, {
              slot: "primary",
              debounce: 250,
              onIonInput: _ctx.setFilter
            }, null, 8, ["onIonInput"]),
            _createVNode(_component_ion_modal, {
              "is-open": !!_ctx.isSortModalOpen,
              "initial-breakpoint": .5,
              breakpoints: [.5, 1],
              onDidDismiss: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isSortModalOpen = false))
            }, {
              default: _withCtx(() => [
                (_ctx.isSortModalOpen !== null)
                  ? (_openBlock(), _createBlock(_component_f2_sort_modal, {
                      key: 0,
                      fields: _ctx.fields.filter((field) => field.sortable),
                      sort: _ctx.sort,
                      onSubmit: _ctx.sortItems,
                      onDismiss: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isSortModalOpen = false))
                    }, null, 8, ["fields", "sort", "onSubmit"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["is-open"]),
            _createVNode(_component_ion_modal, {
              "is-open": !!_ctx.isFilterModalOpen,
              "enter-animation": _ctx.enterModalAnimation,
              "leave-animation": _ctx.leaveModalAnimation,
              style: {"--width":"600px"},
              onDidDismiss: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isFilterModalOpen = false))
            }, {
              default: _withCtx(() => [
                (_ctx.isFilterModalOpen !== null)
                  ? (_openBlock(), _createBlock(_component_f2_filter_modal, {
                      key: 0,
                      fields: _ctx.fields.filter((field) => field.filtrable),
                      "ds-fields": _ctx.dataSourceInfo.dataDefinition.value?.fields,
                      "data-source": _ctx.dataSourceInfo.dataSource,
                      filters: _ctx.userFilters,
                      onSubmit: _ctx.filterItems,
                      onDismiss: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isFilterModalOpen = false))
                    }, null, 8, ["fields", "ds-fields", "data-source", "filters", "onSubmit"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["is-open", "enter-animation", "leave-animation"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}