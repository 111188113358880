import recursion from './tools/recursion';

export default class DataSourceRegistry {
    constructor (config = {
        isWatchForDuplicateDataSources: false,
    }) {
        this.dataSources = this._dataSources = {};
        this._events = {};
        this._eventsWaitingToBeAssigned = {};
        this.debug = false;
        this._multipleRegisteredDataSources = [];
        this._config = config;
    }

    register (dataSource) {
        if (!dataSource.id) {
            console.error('DataSourceRegistry: handed in dataSource with no id property');
            return;
        }
        if (this._config.isWatchForDuplicateDataSources && dataSource.id in this._dataSources) {
            this._multipleRegisteredDataSources.push(dataSource.id);
        }
        this._dataSources[dataSource.id] = dataSource;
        this._assignEventsWaiting(dataSource);
    }

    remove (dataSource) {
        // move added events to waiting events in order to reappend the handlers in case the dataSource returns to scope
        if (dataSource.id in this._events) {
            this._eventsWaitingToBeAssigned[dataSource.id] = this._events[dataSource.id].slice();
        }
        this._removeAllOccurrence(this._eventsWaitingToBeAssigned, dataSource.id);
        this._removeAllOccurrence(this._events, dataSource.id);
        delete this._dataSources[dataSource.id];
    }

    get (dataSourceID) {
        return this._dataSources[dataSourceID];
    }

    _removeAllOccurrence (hashMap, dataSourceID) {
        recursion.traverseObjectValues(hashMap, (value, key, info) => {
            if (key === 'registeredByID' && value === dataSourceID) {
                info.parentTree.parentTree.value.splice(info.parentTree.key, 1);
            }
        });
    }

    getData (dataSourceId) {
        if (dataSourceId in this._dataSources) {
            return this._dataSources[dataSourceId].data;
        }
        return null;
    }

    on (eventName, dataSourceId, handler, registeredByID) {
        if (dataSourceId in this._dataSources) {
            const count = this._multipleRegisteredDataSources
                .filter(id => id === dataSourceId)
                .length;
            if (count) {
                console.warn(`there are ${count} dataSources registered with id ${dataSourceId}`);
            }
            let dataSource = this._dataSources[dataSourceId];
            dataSource.on(eventName, handler);
            DataSourceRegistry._add(this._events, dataSourceId, eventName, handler, registeredByID);
        }
        else {
            DataSourceRegistry._add(this._eventsWaitingToBeAssigned, dataSourceId, eventName, handler, registeredByID);
        }
        if (this.debug) {
            this._logDebug(`registered event ${eventName} for DS ID`, dataSourceId, 'is DS available', dataSourceId in this._dataSources, 'DS', this._dataSources[dataSourceId]);
        }
    }

    off (eventName, dataSourceId, handler) {
        if (dataSourceId in this._dataSources) {
            let { item } = DataSourceRegistry._get(this._events, dataSourceId, eventName, handler) || {};
            if (item) {
                this._dataSources[dataSourceId].off(eventName, handler);
                return DataSourceRegistry._remove(this._events, dataSourceId, eventName, handler, item);
            }
            return false;
        }
        else {
            return DataSourceRegistry._remove(this._events, dataSourceId, eventName, handler);
        }
    }

    static _add (hashMap, dataSourceId, eventName, handler, registeredByID) {
        if (!(dataSourceId in hashMap)) {
            hashMap[dataSourceId] = [];
        }
        hashMap[dataSourceId].push({ eventName, handler, registeredByID });
    }

    static _remove (hashMap, dataSourceId, eventName, handler, item) {
        if (!item) {
            item = DataSourceRegistry._get(hashMap, dataSourceId, eventName, handler);
        }
        if (item) {
            hashMap[dataSourceId].splice(item.index, 1);
            if (!hashMap[dataSourceId].length) {
                delete hashMap[dataSourceId];
            }
        }
        return item !== null;
    }

    static _get (hashMap, dataSourceId, eventName, handler) {
        if (dataSourceId in hashMap) {
            let index = hashMap[dataSourceId].findIndex(item => item.handler === handler && item.eventName === eventName);
            return index !== -1
                ? {
                    index,
                    item: hashMap[dataSourceId][index],
                }
                : null;
        }
        return null;
    }

    _assignEventsWaiting (dataSource) {
        if (dataSource.id in this._eventsWaitingToBeAssigned) {
            if (this.debug) {
                this._logDebug('assigning events waiting - DS ID', dataSource.id, this._eventsWaitingToBeAssigned[dataSource.id]);
            }
            let eventsToAssign = this._eventsWaitingToBeAssigned[dataSource.id];
            while (eventsToAssign.length) {
                let { eventName, handler } = eventsToAssign.pop();
                this.on(eventName, dataSource.id, handler);
            }
            delete this._eventsWaitingToBeAssigned[dataSource.id];
        }
    }

    _logDebug () {
        console.log('DataSourceRegistry:', ...arguments);
    }
}