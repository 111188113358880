export default class EventEmitter {
    constructor () {
        this._events = {};
        this._eventEmitterPublicMethods = [
            'emit',
            'on',
            'once',
            'off',
            'allOff',
            'removeAllListeners',
        ];
    }

    _getEventObj (eventName, data, additionalInfo) {
        let event = {
            name: eventName,
            data,
            sender: this,
        };
        if (additionalInfo) {
            event = Object.assign(event, additionalInfo);
            event.additionalInfo = additionalInfo;
        }
        return event;
    }

    emit (eventName, data, additionalInfo) {
        if (this.debug) {
            const event = this._getEventObj(eventName, data, additionalInfo);
            this._logDebug(`emited event: ${eventName}`, event, 'amount listeners:', eventName in this._events && this._events[eventName].length);
        }
        setTimeout(() => {
            if (eventName in this._events && this._events[eventName].length) {
                const event = this._getEventObj(eventName, data, additionalInfo);
                let i = this._events[eventName].length;
                while (i--) {
                    const handler = this._events[eventName][i];
                    handler(Object.assign({}, event));
                    if (handler.isOnce) {
                        this._events[eventName].splice(i, 1);
                    }
                }
            }
        });
    }

    on (eventName, handler, isOnce = false) {
        if (!(eventName in this._events)) {
            this._events[eventName] = [];
        }
        handler.isOnce = isOnce;
        this._events[eventName].push(handler);
        return true;
    }

    once (eventName, handler) {
        this.on(eventName, handler, true);
    }

    off (eventName, handler) {
        if (eventName in this._events) {
            let eventHandlers = this._events[eventName];
            for (let i = 0; i < eventHandlers.length; i++) {
                if (handler === eventHandlers[i]) {
                    eventHandlers.splice(i, 1);
                    return true;
                }
            }
        }
        return false;
    }

    removeAllListeners () {
        this.allOff();
    }

    allOff () {
        this._events = {};
    }
}
