import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-text-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.validationErrors && _ctx.validationErrors.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validationErrors, (error, i) => {
            return (_openBlock(), _createElementBlock("li", { key: i }, [
              _createVNode(_component_ion_text, { color: "danger" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(error.fieldName) + ": " + _toDisplayString(error.text), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isDependenciesMet)
      ? (_openBlock(), _createBlock(_component_ion_label, {
          key: 1,
          color: "warning",
          style: {"display":"flex","justify-content":"center"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("dependencies not met")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isLoading || _ctx.isGeoLocationNeeded && _ctx.geoLocation.isRequestingLocation.value)
      ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
          key: 2,
          type: "indeterminate"
        }))
      : _createCommentVNode("", true),
    (_ctx.isGeoLocationNeeded && _ctx.geoLocation.isRequestingLocation.value)
      ? (_openBlock(), _createBlock(_component_ion_label, {
          key: 3,
          color: "primary",
          class: "ion-text-wrap",
          style: {"display":"flex","justify-content":"center"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("info.readingGeoLocationFromDevice")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_ion_label, {
          key: 4,
          color: "danger",
          class: "ion-text-wrap",
          style: {"display":"flex","justify-content":"center"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("error.loadingData")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isGeoLocationNeeded && _ctx.geoLocation.error.value)
      ? (_openBlock(), _createBlock(_component_ion_label, {
          key: 5,
          color: "danger",
          class: "ion-text-wrap",
          style: {"display":"flex","justify-content":"center"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("error.gettingGeoLocation")), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}