<template>
    <offline-icon
        v-if="id"
        :icon="id"
    />
</template>
<script lang="ts">
import resolve from '../framework2-sdk/dependencyResolver';
import {defineComponent, ref} from 'vue';
import {OfflineIcon} from '@/icons/icons';
import {useStore} from 'vuex';
import {key} from '../store';
import {TagsToResolve} from '../helpers';

export default defineComponent({
    components: {
        OfflineIcon,
    },
    props: {
        definition: {
            required: true,
            type: [Object, String],
        },
        data: {
            type: [Object, Array],
        },
    },
    setup(props) {
        const id = ref('');
        const store = useStore(key);
        id.value = getIDFromObject(props.definition, props.data, store);
        return {
            id,
            props,
        };
    },
});

function getIDFromObject(object: any, data: any, store: any) {
    if (typeof object === 'string') {
        return object;
    }
    if (object?.options) {
        object = object.options;
    }
    if (object?.icon?.family && object?.icon?.name) {
        const icon: any = resolve(object.icon, new TagsToResolve({
            self: Array.isArray(data) ? data[0] : data,
            storage: store.state.user.storage,
        }));
        return `${icon.family}:${icon.name}`;
    }
    return '';
}

</script>
<style scoped>
	.spin {
		animation-name: spin;
		animation-duration: 2500ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	@keyframes spin {
		from {
			transform:rotate(0deg);
		}
		to {
			transform:rotate(360deg);
		}
	}
</style>
