<template>
    <f2-component
        v-if="definition.customToolbar && definition.customToolbar.length"
        style="padding: 0 8px"
        :definition="definition.customToolbar"
        :data="$parent.data"
        :should-clone-data="false"
        type="indeterminate"
    />
    <ion-toolbar v-else-if="$parent.isDataReady">
        <ion-fab
            v-if="actions.length ||
                (definition?.look?.detail?.grid.length
                    || definition?.look?.detail?.subcomponents.length) &&
                (definition?.data?.source?.rights?.create !== false
                    || definition?.data?.source?.rights?.update !== false) ||
                definition?.data?.source?.rights?.delete !== false
                || fields.length"
            slot="secondary"
        >
            <ion-fab-button color="secondary">
                <ion-icon :icon="chevronForward" />
            </ion-fab-button>
            <ion-fab-list side="end">
                <ion-fab-button
                    v-if="(definition?.look?.detail?.grid.length
                        || definition?.look?.detail?.subcomponents.length)
                        && definition?.data?.source?.rights?.create !== false"
                    color="primary"
                    @click="create()"
                >
                    <ion-icon :icon="add" />
                </ion-fab-button>
                <ion-fab-button
                    v-if="(definition?.look?.detail?.grid.length
                        || definition?.look?.detail?.subcomponents.length)
                        && definition?.data?.source?.rights?.update !== false"
                    :disabled="!selectedItems.length"
                    color="primary"
                    @click="edit(selectedItems[0])"
                >
                    <ion-icon :icon="createOutline" />
                </ion-fab-button>
                <ion-fab-button
                    v-for="(actionData, i) of actions"
                    :key="i"
                    color="primary"
                    :disabled="!selectedItems.length"
                    @click="executeAction(actionData)"
                >
                    <ion-label>{{ actionData.template.template.value }}</ion-label>
                </ion-fab-button>
                <ion-fab-button
                    v-if="definition?.data?.source?.rights?.delete !== false"
                    :disabled="!selectedItems.length"
                    color="danger"
                    @click="deleteItems()"
                >
                    <ion-icon :icon="trashOutline" />
                </ion-fab-button>
                <ion-fab-button
                    v-if="hasOrderableColumns"
                    :color="!sort.length ? 'primary' : 'secondary'"
                    @click="isSortModalOpen = true"
                >
                    <ion-icon :icon="shuffleOutline" />
                </ion-fab-button>
                <ion-fab-button
                    v-if="hasFiltrableColumns"
                    :color="!userFilters.length ? 'primary' : 'secondary'"
                    @click="isFilterModalOpen = true"
                >
                    <ion-icon :icon="filter" />
                </ion-fab-button>
            </ion-fab-list>
        </ion-fab>
        <ion-searchbar
            slot="primary"
            :debounce="250"
            @ionInput="setFilter"
        />

        <ion-modal
            :is-open="!!isSortModalOpen"
            :initial-breakpoint=".5"
            :breakpoints="[.5, 1]"
            @didDismiss="isSortModalOpen = false"
        >
            <f2-sort-modal
                v-if="isSortModalOpen !== null"
                :fields="fields.filter((field) => field.sortable)"
                :sort="sort"
                @submit="sortItems"
                @dismiss="isSortModalOpen = false"
            />
        </ion-modal>

        <ion-modal
            :is-open="!!isFilterModalOpen"
            :enter-animation="enterModalAnimation"
            :leave-animation="leaveModalAnimation"
            style="--width: 600px;"
            @didDismiss="isFilterModalOpen = false"
        >
            <f2-filter-modal
                v-if="isFilterModalOpen !== null"
                :fields="fields.filter((field) => field.filtrable)"
                :ds-fields="dataSourceInfo.dataDefinition.value?.fields"
                :data-source="dataSourceInfo.dataSource"
                :filters="userFilters"
                @submit="filterItems"
                @dismiss="isFilterModalOpen = false"
            />
        </ion-modal>
    </ion-toolbar>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue';
import {
    IonSearchbar,
    IonToolbar,
    IonFab,
    IonFabButton,
    IonFabList,
    IonIcon,
    IonLabel,
    IonModal,
    alertController,
} from '@ionic/vue';
import {
    chevronForward,
    add,
    createOutline,
    trashOutline,
} from 'ionicons/icons';
import {DataSourceInfo} from '@/functionality/dataSource';
import {showErrorToast} from '@/functionality/logging';
import {resolveTemplate} from '@/functionality/template';
import {clone} from '@/framework2-sdk/tools/object';
import {action} from '@/functionality/actions';
import DataItem from '@/framework2-sdk/dataItem';
import parseUserFilters from '@/framework2-sdk/dsFilters';
import {key} from '@/store';
import * as f2Api from '@/api';
import {getIcon} from '@/icons/icons';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

import F2SortModal from './F2SortModal.vue';
import F2FilterModal from './F2FilterModal.vue';
import {enterModalAnimation, leaveModalAnimation} from '@/helpers';

export default defineComponent({
    components: {
        IonSearchbar,
        IonToolbar,
        IonFab,
        IonFabButton,
        IonFabList,
        IonIcon,
        IonLabel,
        IonModal,
        F2SortModal,
        F2FilterModal,
    },
    props: {
        definition: {
            required: true,
            type: Object,
        },
        dataSourceInfo: {
            required: true,
            type: Object as () => DataSourceInfo,
        },
        selectedItems: {
            required: true,
            type: Array as () => Array<DataItem>,
        },
    },
    emits: ['setpage'],
    setup(props, {emit}) {
        const {t} = useI18n();
        const store = useStore(key);
        const router = useRouter();
        const actions = [];
        const fields = ref<any[]>([]);
        const userFilters = ref<any[]>([]);
        const sort = ref<any[]>([]);
        const hasFiltrableColumns = ref(false);
        const hasOrderableColumns = ref(false);
        const isSortModalOpen = ref<boolean | null>(null);
        const isFilterModalOpen = ref<boolean | null>(null);
        const api = f2Api.get();

        if (props.definition.actions) {
            for (const a of props.definition.actions) {
                const actionData: any = {};
                actionData.template = resolveTemplate({
                    definition: {
                        template: a.template,
                    },
                });
                actionData.action = action({definition: a});
                actions.push(actionData);
            }
        }

        // C(R)UD handling
        function create() {
            openForm();
        }
        function edit(item: DataItem) {
            const data = item.__getValues();
            openForm(data);
        }
        function openForm(data?: any) {
            const definition = clone(Object.assign({}, props.definition, {dataSource: undefined}));
            definition.data.source = props.dataSourceInfo.dataSource;
            store.commit('user/definition', {
                key: props.definition.uid,
                value: {
                    type: 'submit-button',
                    definition,
                },
            });
            store.commit('user/storage', {
                key: `data-uid-${definition.uid}`,
                value: data,
            });
            router.push({name: 'components', params: {id: props.definition.uid}});
        }

        async function deleteItems(items: Array<DataItem> = props.selectedItems) {
            const alert = await alertController
                .create({
                    subHeader: t('label.delete'),
                    message: t('confirm.deleteRecords'),
                    buttons: [{
                        text: t('action.no'),
                        role: 'false',
                    }, {
                        text: t('action.yes'),
                        role: 'true',
                    }],
                });
            await alert.present();
            const {role} = await alert.onDidDismiss();
            if (role !== 'true') {
                return;
            };
            try {
                for (const item of items) {
                    item.__action = 'delete';
                }
                const res = await props.dataSourceInfo.dataSource?.sync(items);
                if (res?.some((r) => r.isError)) {
                    console.error('error saving form', res);
                    showErrorToast(t('error.deletingItems'));
                } else {
                    // TODO: handle offline insert here - search for this string, just do it
                    await props.dataSourceInfo.dataSource?.fetch();
                }
            } catch (e: any) {
                console.error('error deleting items', e, 'item', items);
                showErrorToast(t('error.deletingItems'));
            }
        }

        async function executeAction(actionData: any, dataItems: Array<DataItem> = props.selectedItems) {
            try {
                await actionData.action.executeAction(true, dataItems.map((dataItem) => dataItem.__getValues()));
            } catch (e: any) {
                console.error('error while executing list action', e, 'actionData', actionData);
                showErrorToast(t('error.executingAction'));
            }
        }

        async function setFilter(event: any) {
            await props.dataSourceInfo.dataSource?.setSearchFilter(event.detail.value);
            emit('setpage', 1); // triggers fetch
        }

        function filterItems(filters: any[]) {
            userFilters.value = filters;
            props.dataSourceInfo.dataSource?.setNamedFilter(filters ? parseUserFilters(filters) : undefined);
            emit('setpage', 1); // triggers fetch
            isFilterModalOpen.value = false;
        }


        function sortItems(sorts: any[]) {
            sort.value = sorts;
            // eslint-disable-next-line
            props.dataSourceInfo.dataSource?.sort(sort.value.length ? sort.value : null);
            props.dataSourceInfo.dataSource?.fetch();
            isSortModalOpen.value = false;
        }

        watch(() => props.dataSourceInfo.dataDefinition.value?.fields, (dsFields) => {
            if (dsFields) {
                fields.value = Object.entries(dsFields)
                    .map(([fieldName, field]) => {
                        const _field = {
                            key: fieldName,
                            label: fieldName,
                            filtrable: field.filtrable !== false,
                            sortable: field.sortable !== false,
                        };
                        if (field.label) {
                            api?.getLabelText(field.label, fieldName)
                                .then((label) => _field.label = label);
                        }
                        return _field;
                    });
                hasFiltrableColumns.value = !!fields.value.find((field) => field.filtrable);
                hasOrderableColumns.value = !!fields.value.find((field) => field.sortable);
            }
        }, {
            immediate: true,
        });

        watch(() => props.dataSourceInfo.dataSource, (dataSource) => {
            if (dataSource) {
                sort.value = dataSource.sort();
            }
        }, {
            immediate: true,
        });


        return {
            create,
            setFilter,
            edit,
            deleteItems,
            executeAction,
            actions,
            chevronForward,
            add,
            trashOutline,
            createOutline,
            filter: getIcon({name: 'filter-menu-outline', family: 'mdi'}),
            shuffleOutline: getIcon({name: 'swap-vertical', family: 'mdi'}),
            enterModalAnimation,
            leaveModalAnimation,
            isSortModalOpen,
            sortItems,
            isFilterModalOpen,
            filterItems,
            fields,
            userFilters,
            sort,
            hasFiltrableColumns,
            hasOrderableColumns,
        };
    },
});
</script>

<style scoped>
ion-fab {
	position: relative;
}
ion-fab > ion-fab-button {
	width: 44px;
	height: 44px;

    &::part(native) {
        box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3), 2px 1px 3px 1px rgba(0, 0, 0, 0.15);
    }
}
ion-fab > ion-fab-list > ion-fab-button::part(native) {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
ion-fab-list {
	min-height: 44px;
}
ion-fab-list.fab-list-side-end {
	margin-inline: 44px;
}
ion-fab-button ion-label{
	font-size: .52em;
	white-space: normal;
}
.fab-button-disabled {
  opacity: .7;
}
/* ion-modal ion-content::part(scroll) {
  overflow-y: var(--overflow);
  overscroll-behavior-y: contain;
} */
</style>
