<template>
    <div
        :style="props.definition.resolvedStyles"
        :class="props.definition.resolvedClasses"
    >
        <ion-segment
            :value="selectedTabID"
            @ionChange="selectTab($event)"
        >
            <ion-segment-button
                v-for="subcomponent of props.definition"
                :key="subcomponent.id"
                :value="subcomponent.id"
            >
                <f2-icon
                    class="tab-button-icon"
                    :definition="subcomponent.definition"
                />
                <ion-label>{{ subcomponent.resolvedTemplate || subcomponent.label }}</ion-label>
            </ion-segment-button>
        </ion-segment>
        <transition-group name="left">
            <f2-component
                v-for="subcomponent of activatedSubcomponents"
                v-show="subcomponent.id == selectedTabID"
                :key="subcomponent.id"
                class="f2-no-padding"
                :definition="subcomponent"
                :data="props.data"
                :model="props.model"
                :data-source="props.dataSource"
            />
        </transition-group>
    </div>
</template>
<script lang="ts">
import {defineComponent, ref} from 'vue';
import {
    IonLabel,
    IonSegment,
    IonSegmentButton,
    SegmentCustomEvent,
} from '@ionic/vue';
import {resolveTemplate} from '../functionality/template';
import F2Icon from './F2Icon.vue';
import {Component} from '@/framework2-sdk/f2Api';

export default defineComponent({
    components: {
        F2Icon,
        IonLabel,
        IonSegment,
        IonSegmentButton,
    },
    props: {
        definition: {
            required: true,
            type: Object as () => Component[],
        },
        data: {
            required: false,
            type: Object,
        },
        model: {
            required: false,
            type: Object,
        },
        dataSource: {
            required: false,
            type: Object,
        },
    },
    setup(props) {
        const subcomponents = props.definition;
        const firstComponent = subcomponents[0];

        const activatedSubcomponents = ref<Array<Component>>([]);

        activatedSubcomponents.value.push(firstComponent);

        for (const [i, subcomponent] of Object.entries(subcomponents)) {
            if (!subcomponent.id) {
                subcomponent.id = subcomponent.uid || i;
            }
            (subcomponent as any)['resolvedTemplate'] = resolveTemplate(subcomponent).template;
            if (subcomponent.definition.label) {
                (subcomponent as any)['label'] = resolveTemplate({definition: {template: `{__label.${subcomponent.definition.label}}`}}).template;
            }
        }

        const selectedTabID = ref(firstComponent.id || 0);

        function selectTab(e: SegmentCustomEvent) {
            const tabID: string = <string>e.detail.value;
            selectedTabID.value = tabID;
            if (!activatedSubcomponents.value.find((c) => c.id == tabID)) {
                const component = subcomponents.find((c) => c.id == tabID);
                if (!component) {
                    return;
                }
                activatedSubcomponents.value.push(component);
            }
        }

        return {
            selectTab,
            subcomponents,
            activatedSubcomponents,
            selectedTabID,
            props,
        };
    },
});


</script>
<style scoped>
	.left-enter-active,
	.left-leave-active {
		transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
	}

	.left-enter-active {
		order: 1;
	}

	.left-leave-active {
        opacity: 0;
		position: absolute;
		left: -100%;
		order: 2;
	}

	.left-enter-from {
		transform: translateX(-100%);
	}

	.left-leave-from {
		transform: translateX(-100%);
	}

</style>
